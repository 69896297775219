import {Component, OnInit, Input} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent implements OnInit {
  @Input() title: '';
  @Input() content: '';
  @Input() payload: any;
  @Input() confirmOnly = false;
  @Input() alignment = 'horizontal';
  confirmButtonText = 'Confirm';
  cancelButtonText = 'Cancel';

  result_data={};

  constructor(
    private dialogRef: MatDialogRef<CustomDialogComponent>
  ) {}

  ngOnInit() {
    this.result_data = this.payload[0];
  }


  print(event){
  }
  onNewWorkplace(wp){
    this.result_data = wp;
  }
  onNewUser(user){
    this.result_data = user;
  }
  closeWithNewUserInfo(user){
    this.dialogRef.close(user);
  }
  close(){
    this.dialogRef.close();
  }
  onWorkplaceInfoChanged(){

  }
  toggleMfa(event){
    this.result_data['mfaEnabled'] = event.checked;    
  }
}
