import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/services/shared.service';
import { PeriodicElement } from '../workplace-pages/workplace-users/workplace-users.component';

@Component({
  selector: 'app-access-logs',
  templateUrl: './access-logs.component.html',
  styleUrls: ['./access-logs.component.scss']
})
export class AccessLogsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  database: any | null;
  logs = new MatTableDataSource<PeriodicElement>([]);

  displayedColumns: string[] = [
    'userName', 'email', 'ipAddress', 'loginDate','logoutDate'
  ];
  isLoadingResults = true;
  isEmpty: boolean;
  resultsLength = 0;


  constructor(
    private sharedService:SharedService
  ) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.setData();
  }
  setData(){
    this.database = {};
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.isEmpty = false;
          const path = environment.celoApiEndpoint+'/api/admin/audit/accesslog';
          let params = {
            Page:this.paginator.pageIndex,
            PageSize:20
          }
          // return this.database!.getRepoIssues(this.sort.active, this.sort.direction, this.paginator.pageIndex);
          return this.sharedService.getObjectById(path,params);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data.total;
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return of([]);
        })
      ).subscribe(data => {
        // this.departments = data;
        this.logs = new MatTableDataSource<PeriodicElement>([]);
        this.logs = new MatTableDataSource<PeriodicElement>(data);
        if(!data||data.length==0){
          this.isEmpty = true;
        }
      });
  }

}
