import { Injectable } from '@angular/core';
import { CustomDialogComponent } from '../../../components/common/custom-dialog/custom-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../../components/common/snackbar/snackbar.component';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {}

    /**
     * Convenience method to show an error snackbar.
     */
    showErrorSnackbar(message: string, seconds: number = 3): void {
        this.showSnackBar(message, seconds);
    }

    showSnackBar(message: string, seconds: number, theme?: string) {
        theme = theme ? theme : 'black';
        const snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
            duration: seconds * 1000,
            panelClass: [theme + '_snackbar'],
            data: {
                message: message,
                theme: theme
            }
        });
    }

    /**
     * @deprecated Old method.
     */
    customDialog(
        title,
        content,
        confirmButtonText = 'Confirm',
        cancelButtonText = 'Cancel',
        confirmOnly = false,
        alignment = 'horizontal',
        payload?
    ) {
        const ref = this.dialog.open(CustomDialogComponent, { autoFocus: false, panelClass: 'alert-panel' });
        const i = ref.componentInstance;
        i.title = title;
        i.alignment = alignment;
        i.content = content;
        i.confirmButtonText = confirmButtonText;
        i.cancelButtonText = cancelButtonText;
        i.confirmOnly = confirmOnly;
        i.payload = payload;
        return ref;
    }
}
