import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'core/services/alert.service';

export interface RenewLicensesComponentPayload {
  expiryDate: Date;
}

@Component({
  selector: 'app-renew-licenses',
  templateUrl: './renew-licenses.component.html',
  styleUrls: ['./renew-licenses.component.scss']
})
export class RenewLicensesComponent {
  max = 100;
  min = 0;
  step = 1;
  thumbLabel = false;
  value = 0;
  dateVal : Date;

  constructor(
    private dialogRef: MatDialogRef<
    RenewLicensesComponent,
    RenewLicensesComponentPayload
    >,
    private alertService: AlertService
  ) 
  {

  }

  ngOnInit() {
    this.value = 1;
  }

  saveClicked() {
    this.alertService.showSnackBar("Editing licenses for this worksplace...", 10);
    const payload: RenewLicensesComponentPayload = {
      expiryDate: this.dateVal
    };
    this.dialogRef.close(payload);
  }

  handleDateChange(event: InputEvent) {
    this.dateVal = new Date((event.target as HTMLInputElement).value);
  }
}

