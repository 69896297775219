<div class="parent celo-content">
    <!-- Tabs -->
    <div class="tabs">
        <a [routerLink]="['/delete-requests']">
            <button mat-button [ngClass]="{ 'button-checked': selectedStatus === null }">All</button>
        </a>

        <a *ngFor="let status of statuses" [routerLink]="['/delete-requests']" [queryParams]="{ status: status }">
            <button mat-button [ngClass]="{ 'button-checked': selectedStatus === status }">{{ status }}</button>
        </a>
    </div>

    <!-- Table -->
    <div class="relative-table-container">
        <div *ngIf="isLoading" class="loading-shade">
            <app-empty-state></app-empty-state>
        </div>

        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="table">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let row" class="name-row">
                        <div class="name-content">
                            <div class="avatar">
                                <app-avatar
                                    [name]="row.fullName"
                                    [userId]="row.userId"
                                    [width]="36"
                                    [height]="36"
                                    [hasImage]="true"
                                ></app-avatar>
                            </div>
                            <a class="name" [routerLink]="['/user/' + row.userId]">{{ row.fullName }}</a>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
                </ng-container>

                <ng-container matColumnDef="createdOnUtc">
                    <th mat-header-cell *matHeaderCellDef>Created On</th>
                    <td mat-cell *matCellDef="let element">{{ element.createdOnUtc | date: 'medium' }}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element">{{ element.status }}</td>
                </ng-container>

                <ng-container matColumnDef="reason">
                    <th mat-header-cell *matHeaderCellDef>Reason</th>
                    <td mat-cell *matCellDef="let element">{{ element.reason }}</td>
                </ng-container>

                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef>Edit</th>
                    <td mat-cell *matCellDef="let element">
                        <button (click)="editRequest(element)">Edit Request</button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef>Delete?</th>
                    <td mat-cell *matCellDef="let element">
                        <button (click)="deleteUser(element.userId, element.id)">Delete User</button>
                    </td>
                </ng-container>

                <tr mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>

            <div *ngIf="!isLoading && !dataSource?.length" class="no-results">- No results found -</div>
        </div>
    </div>

    <!-- Pagination -->
    <div class="paginator">
        <button class="load-more celo-tertiary-button" mat-button (click)="nextPage()" [disabled]="!hasNextPage">
            Load more >
        </button>
    </div>
</div>
