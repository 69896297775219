import { AvatarService } from './services/avatar.service';
import { AuthService } from './services/auth.service';
import { AdminUsersService } from './services/admin-users.service';
import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MaterialModule } from './../material/material.module';
import { AlertService } from './services/alert.service';
import { ApiService } from './services/api.service';

@NgModule({
    declarations: [],
    imports: [CommonModule, MaterialModule],
    providers: [ApiService, AlertService, AdminUsersService, AuthService, AvatarService]
})
export class CoreModule {
    // See: https://angular.io/guide/singleton-services#prevent-reimport-of-the-greetingmodule
    public constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}
