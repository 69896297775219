<div class="content">
  <div class="title">
    <div>{{ title }}</div>
  </div>
  <div class="bodycontent">
    <div class="body subtitle">{{ content }}</div>
    <div *ngIf="payload">
      <div *ngFor="let item of payload">
        <div *ngIf="item.type == 'text'" class="subtext mt-2">
          <p>{{ item.data }}</p>
        </div>
        <div *ngIf="item.type == 'form'" class="subtext mt-2">
          <div *ngFor="let field of item.fields">
            <input
              type="text"
              class="celo-input"
              [(ngModel)]="result_data[field.key]"
              [placeholder]="field.displayName"
            />
          </div>
        </div>
        <div *ngIf="item.type == 'add_workplace'" class="subtext mt-2">
          <app-workplace-info-form
            (formchange)="onNewWorkplace($event)"
            [editmode]="false"
            [newmode]="true"
            [formObject]="item.formObject"
          ></app-workplace-info-form>
        </div>
        <div *ngIf="item.type == 'add_new_workplace'" class="subtext mt-2">
          <app-add-workplace
            [userId]="item.userId"
            [countryCode]="item.countryCode"
            (formchange)="onNewWorkplace($event)"
            (close)="close()"
            (onNewUser)="closeWithNewUserInfo($event)"
          ></app-add-workplace>
        </div>
        <div *ngIf="item.type == 'celo-form'" class="subtext mt-2">
          <app-celo-form
            (formchange)="onNewWorkplace($event)"
            [formObject]="item.formObject"
          ></app-celo-form>
        </div>
        <div *ngIf="item.type == 'add_new_user'" class="subtext mt-2">
          <app-add-user-form
            [id]="item.companyId"
            (formchange)="onNewUser($event)"
            [formObject]="item.formObject"
          ></app-add-user-form>
        </div>
        <div *ngIf="item.type == 'manage_claims'" class="subtext mt-2">
          <div class="claim-edit">
            <div class="claim-item">
              <div class="display-name">Link sharing</div>
              <div>
                <mat-slide-toggle
                  [(ngModel)]="result_data.workspaceInvitationInvite"
                ></mat-slide-toggle>
              </div>
            </div>
            <div class="claim-item">
              <div class="display-name">Link resetting</div>
              <div>
                <mat-slide-toggle
                  [(ngModel)]="result_data.workspaceInvitationReset"
                ></mat-slide-toggle>
              </div>
            </div>
            <div class="claim-item">
              <div class="display-name">User management</div>
              <div>
                <mat-slide-toggle
                  [(ngModel)]="result_data.workspaceUserManage"
                ></mat-slide-toggle>
              </div>
            </div>
            <div class="claim-item">
              <div class="display-name">Team management</div>
              <div>
                <mat-slide-toggle
                  [(ngModel)]="result_data.workspaceTeamManage"
                ></mat-slide-toggle>
              </div>
            </div>
            <div class="claim-item">
              <div class="display-name">External conversation</div>
              <div>
                <mat-slide-toggle
                  [(ngModel)]="result_data.workspaceAllowExternalConversations"
                ></mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="item.type == 'manage_roles'" class="subtext mt-2">
          <div class="claim-edit">
            <div class="claim-item">
              <div class="display-name">Department Admin</div>
              <div>
                <mat-slide-toggle
                  [(ngModel)]="item.departmentAdmin"
                ></mat-slide-toggle>
              </div>
            </div>
            <div class="claim-item">
              <div class="display-name">Broadcaster</div>
              <div>
                <mat-slide-toggle
                  [(ngModel)]="item.broadcaster"
                ></mat-slide-toggle>
              </div>
            </div>
            <div class="claim-item">
              <div class="display-name">Dashboard Viewer</div>
              <div>
                Contact engineering to modify this.
                <!-- <mat-slide-toggle
                  [(ngModel)]="item.dashboardViewer"
                ></mat-slide-toggle> -->
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="item.type == 'manage_features'" class="subtext mt-2">
          <div class="claim-edit">
            <div class="claim-item">
              <div class="display-name">External Integration</div>
              <div>
                <mat-slide-toggle
                  [(ngModel)]="item.externalIntegration"
                ></mat-slide-toggle>
              </div>
            </div>
            <div class="claim-item">
              <div class="display-name">Patient Lookup</div>
              <div>
                <mat-slide-toggle
                  [(ngModel)]="item.patientLookup"
                ></mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="item.type == 'manage_user_mfa'" class="subtext mt-2">
          <div class="claim-edit">
            <div class="claim-item">
              <div class="display-name">MFA</div>
              <div>
                <mat-slide-toggle
                  [(ngModel)]="item.mfaEnabled"
                  (change)="toggleMfa($event)"
                ></mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="action mt-1"
    [ngClass]="{
      vertical: alignment == 'vertical',
      horizontal: alignment != 'vertical'
    }"
  >
    <button
      *ngIf="!confirmOnly && cancelButtonText"
      [mat-dialog-close]="false"
      mat-button
      class="celo-secondary-button"
    >
      {{ cancelButtonText }}
    </button>
    <button
      *ngIf="confirmButtonText"
      [disabled]="result_data['valid'] == false"
      [mat-dialog-close]="{ payload: result_data }"
      mat-button
      class="celo-primary-button"
    >
      {{ confirmButtonText }}
    </button>
  </div>
</div>
