<div class="page-title">Workspace Info</div>
<div class="content">
  <div class="left">
    <div *ngIf="editmode" class="avatar-container" mat-button>
      <app-avatar
        *ngIf="unsavedWorkspacePhotoUrl; else companyProfileImage"
        [hasImage]="true"
        [imageUrl]="unsavedWorkspacePhotoUrl"
        [width]="60"
        [height]="60"
        [isDataUrl]="true"
      ></app-avatar>
      <ng-template #companyProfileImage>
        <app-avatar
          [name]="company.name"
          [hasImage]="company.profilePictureUri"
          [companyId]="company.id"
          [width]="60"
          [height]="60"
          [nocache]="true"
        ></app-avatar>
      </ng-template>
      <input
        #EditPhotoFileInput
        type="file"
        style="display: none"
        accept="image/*"
        (change)="handleEditPhotoFileChange($event)"
      />
      <button mat-button class="action-button" (click)="handleEditPhotoClick()">
        Edit photo
      </button>
    </div>
    <app-workplace-info-form
      [editmode]="editmode"
      [newmode]="false"
      [formObject]="formObject"
    ></app-workplace-info-form>
    <div *ngIf="claims" class="item">
      <div class="title">Default User Claims</div>
      <div *ngIf="!editmode">
        <span>{{ claims | claims: "-" }}</span>
      </div>
      <div *ngIf="editmode">
        <div class="claim-edit">
          <div class="claim-item">
            <div class="display-name">Link sharing</div>
            <div>
              <mat-slide-toggle
                [(ngModel)]="workspaceInvitationInvite"
              ></mat-slide-toggle>
            </div>
          </div>
          <div class="claim-item">
            <div class="display-name">Link resetting</div>
            <div>
              <mat-slide-toggle
                [(ngModel)]="workspaceInvitationReset"
              ></mat-slide-toggle>
            </div>
          </div>
          <div class="claim-item">
            <div class="display-name">User management</div>
            <div>
              <mat-slide-toggle
                [(ngModel)]="workspaceUserManage"
              ></mat-slide-toggle>
            </div>
          </div>
          <div class="claim-item">
            <div class="display-name">Team management</div>
            <div>
              <mat-slide-toggle
                [(ngModel)]="workspaceTeamManage"
              ></mat-slide-toggle>
            </div>
          </div>
          <div class="claim-item">
            <div class="display-name">External conversation</div>
            <div>
              <mat-slide-toggle
                [(ngModel)]="workspaceAllowExternalConversations"
              ></mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="company && !editmode" class="item">
      <div class="title">Created By</div>
      <div *ngIf="!company.creator">-</div>
      <div *ngIf="company.creator">
        <div *ngIf="company.creator.isAdmin">
          Admin (<span
            ><a [routerLink]="['/user/' + company.creator.userId]">{{
              company.creator.fullName
            }}</a></span
          >)
        </div>
        <div *ngIf="!company.creator.isAdmin">
          <a [routerLink]="['/user/' + company.creator.userId]">{{
            company.creator.fullName
          }}</a>
        </div>
      </div>
      <div></div>
    </div>
    <div *ngIf="workspaceLimits.licenseLimit && !editmode" class="item">
      <div class="title">Licenses Count</div>
      <div>
        {{workspaceLimits.currentAllocatedLicenses}}/{{workspaceLimits.licenseLimit}}
      </div>
    </div>
    <div *ngIf="company && !editmode" class="item workspace-link">
      <div class="title">Workspace Link</div>
      <div class="link">
        <span *ngIf="!company.invitationUri">- No link -</span>
        <span *ngIf="company.invitationUri">
          {{ company.invitationUri }}
        </span>
      </div>
      <span>
        <button
          [disabled]="!company.invitationUri"
          mat-icon-button
          matTooltip="Copy link"
          (click)="copy(company.invitationUri)"
        >
          <i class="material-icons">file_copy</i>
        </button>
        <button
          mat-icon-button
          matTooltip="Reset link"
          (click)="resetLinkClick()"
        >
          <i class="material-icons">refresh</i>
        </button>
      </span>
      <div></div>
    </div>
    <div class="bottom-buttons">
      <button
        *ngIf="editmode"
        mat-button
        class="celo-secondary-button"
        (click)="cancel()"
      >
        Cancel
      </button>
      <button
        *ngIf="editmode"
        mat-button
        class="celo-primary-button"
        (click)="save()"
      >
        Save
      </button>
      <span *ngIf="!editmode">
        <div class="buttons">
          <a class="celo-primary-link celo-link" (click)="edit()">
            <span>Edit workspace info</span>
          </a>
        </div>
        <div class="buttons" *ngIf="company.plan != 'Business'">
          <a class="celo-primary-link celo-link" (click)="editLicenses()">
            <span>Edit Licenses</span>
          </a>
        </div>
        <div class="buttons" *ngIf="company.plan != 'Business'">
          <a class="celo-primary-link celo-link" (click)="renewLicenses()">
            <span>Renew Licenses</span>
          </a>
        </div>
        <div class="buttons">
          <a
            *ngIf="!formObject.isActive"
            class="celo-primary-link celo-link"
            (click)="activateWorkplace()"
            >Activate Workplace</a
          >
          <a
            *ngIf="formObject.isActive"
            class="celo-red-link celo-link"
            (click)="deactivateWorkplace()"
            >Deactivate Workplace</a
          >
        </div>
      </span>
    </div>
  </div>
  <div class="right">
    <div class="item">
      <span class="title"> Workspace Location(s) </span>
      <div>
        <div mat-button class="celo-link button" (click)="editLocation()">
          Add location
        </div>
      </div>
      <div *ngFor="let location of locations" class="location celo-hoverable">
        <div *ngFor="let field of locationFields" class="fw">
          <div>
            <div class="title">{{ field.display }}</div>
            <div *ngIf="field.key != 'countryCode'">
              {{ location[field.key] ? location[field.key] : "-" }}
            </div>
            <div *ngIf="field.key == 'countryCode'">
              <div>
                {{
                  location[field.key] ? (location[field.key] | country) : "-"
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          mat-button
          class="celo-link button"
          (click)="editLocation(location)"
        >
          Edit location
        </div>
      </div>
    </div>
  </div>
</div>
