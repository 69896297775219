import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../modules/core/services/auth.service';
import { WorkspaceAddressModel } from './../../types/index';
// import { RequestOptionsArgs, Http, URLSearchParams, Response} from '@angular/http';

const STANDARD_ERROR_MESSAGE = 'An error has occured.';

export interface LocationField {
    key: keyof WorkspaceAddressModel;
    display: string;
    field: string;
    size: string;
    required?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    sortArrayByField(array: any[], fieldName) {
        if (!array || !array.length || !fieldName) {
            return;
        }
        let keyA;
        let keyB;
        array.sort((a, b) => {
            keyA = a[fieldName].toLowerCase();
            keyB = b[fieldName].toLowerCase();
            return keyA > keyB ? 1 : keyB > keyA ? -1 : 0;
        });
    }
    locationFields: LocationField[] = [
        {
            key: 'address1',
            display: 'Address line 1',
            field: 'text',
            size: 'large'
        },
        {
            key: 'address2',
            display: 'Address line 2',
            field: 'text',
            size: 'large'
        },
        {
            key: 'suburb',
            display: 'Suburb',
            field: 'text',
            size: 'small'
        },
        {
            key: 'city',
            display: 'City',
            field: 'text',
            size: 'small'
        },
        {
            key: 'state',
            display: 'State/Province',
            field: 'text',
            size: 'small'
        },
        {
            key: 'countryCode',
            display: 'Country',
            field: 'select',
            size: 'small',
            required: true
        },
        {
            key: 'postCode',
            display: 'Post Code',
            field: 'number',
            size: 'small'
        }
    ];
    setUserId(value) {
        this.authService.loggedInUserId = value;
        return localStorage.setItem('userId', value);
    }

    public httpOptions = {
        headers: new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            CeloAuthorization: environment.celoAuth
        })
    };
    dummyProfessions: any = [
        {
            category: 'Medical Practitioner',
            profession: 'Practitioner',
            verificationStatus: 'Verified'
        },
        {
            category: 'Medical Practitioner1',
            profession: 'Practitioner1',
            verificationStatus: 'Unverified'
        },
        {
            category: 'Medical Practitioner2',
            profession: 'Practitioner2',
            verificationStatus: 'Pending'
        },
        {
            category: 'Medical Practitioner2.1',
            profession: 'Practitioner2.1',
            verificationStatus: 'Pending'
        },
        {
            category: 'Medical Practitioner3',
            profession: 'Practitioner3',
            verificationStatus: 'MoreInfoRequired'
        }
    ];

    constructor(private router: Router, private authService: AuthService, private httpClient: HttpClient) {}

    scrollTo(arg0: number, arg1: number) {
        window.scroll(arg0, arg1);
    }
    scrollToAnchor(el: HTMLElement) {
        el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    }

    postObjectById<T = any>(path: string, body?: any): Observable<T> {
        const httpOptions = {
            headers: {
                Authorization: this.authService.getAuthorization()
            }
        };
        return this.httpClient.post<T>(path, body, httpOptions);
    }

    getObjectById<T = any>(path: string, params: any = {}, headers?: HttpHeaders): Observable<T> {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: this.authService.getAuthorization(),
                ...headers
            }),
            params
        };
        return this.httpClient.get<T>(path, httpOptions);
    }

    putObjectById<T = any>(path: string, body?: any): Observable<T> {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: this.authService.getAuthorization()
            })
        };
        return this.httpClient.put<T>(path, body, httpOptions);
    }

    patchObjectById<T = any>(path: string, body?: any, headers?: Record<string, string>): Observable<T> {
        const httpOptions = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                CeloAuthorization: environment.celoAuth,
                Authorization: 'Bearer ' + this.authService.getAuthorization(),
                ...headers
            }
        };

        if (body instanceof FormData) {
            // delete httpOptions.headers.Accept;
            delete httpOptions.headers['Content-Type'];
        }

        return this.httpClient.patch<T>(path, body, httpOptions);
    }

    deleteObjectById<T = any>(path: string): Observable<T> {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: this.authService.getAuthorization()
            })
        };
        return this.httpClient.delete<T>(path, httpOptions);
    }

    navigateTo(route: string, params: {}) {
        this.router.navigate([route, params]);
    }
    navigateToRoute(route: string) {
        this.router.navigate([route]);
    }
    navigate(route, queryParams?) {
        this.router.navigate(route, queryParams);
    }
    getTitles(callback) {
        const path = environment.celoApiEndpoint + '/api/Account/Titles';
        this.getObjectById(path).subscribe((data) => {
            callback(data);
        });
    }
    getDepartments(workplaceId, callback) {
        const path = environment.celoApiEndpoint + '/api/companies/' + workplaceId + '/departments';
        let params = {
            Page: 0,
            PageSize: 9999
        };
        this.getObjectById(path, params).subscribe((data) => {
            callback(data);
        });
    }
    readErrorMessage(err: any, fallback?) {
        if (!err) return fallback;
        if (err['error'] && err['error']['errors'] && err['error']['errors'][0] && err['error']['errors'][0].message)
            return err['error']['errors'][0].message;

        if (err['error'] && err['error'][0] && err['error'][0].message) {
            return err['error'][0].message;
        }
        if (err['error'] && err['error'][''] && err['error'][''][0]) {
            return err['error'][''][0];
        }
        return fallback;
    }
    copyMessage(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}
