<div class="parent celo-content">

  <div class="tabs" name="fontStyle" aria-label="Font Style">
    <a [routerLink]="['/identity',{status:'Pending'}]"><button mat-button [ngClass]="{'button-checked':status=='Pending'}">Pending</button></a>
    <a [routerLink]="['/identity',{status:'MoreInfoRequired'}]"><button mat-button [ngClass]="{'button-checked':status=='MoreInfoRequired'}">Waiting for Info</button></a>
    <a [routerLink]="['/identity',{status:'Unverified'}]"><button mat-button [ngClass]="{'button-checked':status=='Unverified'}">Unverified</button></a>
    <a [routerLink]="['/identity',{status:'Completed'}]"><button mat-button [ngClass]="{'button-checked':status=='Completed'}">Verified</button></a>
  </div>
  <div class="top-buttons">
    <div class="left">
      <input type="search" [(ngModel)]="query" class="celo-input celo-search" placeholder="Search" (keyup.enter)="search()">
    </div>
    <div *ngIf="status==identityVerificationStatusModel.Pending" class="right">
      <button matTooltip="Request more information from selected users." mat-button class="celo-button celo-primary-button" (click)="moreInfo(selection.selected)" [disabled]="selection&&selection.selected&&selection.selected.length?false:true">
          <span>More Info</span>
      </button>
      <button matTooltip="Restart the verification process the selected users." mat-button class="celo-button celo-primary-button" (click)="redoVerification(selection.selected)" [disabled]="selection&&selection.selected&&selection.selected.length?false:true">
        <span>Redo Verification</span>
      </button>
    </div>
  </div>
  <div class="relative-table-container">
    <div *ngIf="isLoadingResults" class="loading-shade">
      <app-empty-state *ngIf="isLoadingResults"></app-empty-state>
    </div>
  
    <div class="table-container">
  
      <table mat-table [dataSource]="data" class="table"
             matSort matSortActive="created" matSortDisableClear matSortDirection="desc">      
  
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>&nbsp;&nbsp;
          </th>
          <td mat-cell> test </td>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>&nbsp;&nbsp;
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row" class="name-row">
            <div class="avatar">
              <app-avatar [name]="row.firstName + ' ' + row.lastName" [userId]="row.userId" [width]="36" [height]="36" [hasImage]="!!row.profilePic"></app-avatar>
            </div>
            <span class="name">{{row.firstName}} {{row.lastName}}</span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="result">
          <th mat-header-cell *matHeaderCellDef>Results</th>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="row.result==false">
              <div *ngIf="row.method=='Manual'">Manual fail</div>
              <div *ngIf="row.method=='Automated'">Fail</div>
            </div>
            <div *ngIf="row.result==true">
              <div *ngIf="row.method=='Manual'">Manual success</div>
              <div *ngIf="row.method=='Automated'">Success</div>
            </div>
          </td>
        </ng-container>
  
  
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row">{{row.status}}</td>
        </ng-container>
        
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef>Created On</th>
          <td mat-cell *matCellDef="let row">{{row.createdOnUtc?(row.createdOnUtc|date:'medium'):'-'}}</td>
        </ng-container>
  
        <tr mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr class="celo-hoverable celo-cursor-pointer" (click)="verificationClick(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div *ngIf="!isLoadingResults&&(!data||!data.data||!data.data.length)" class="no-results">- No results found -</div>
    </div>
    <div class="paginator">
      <button class="load-more celo-tertiary-button" mat-button (click)="loadMore()" [disabled]="token?false:true">Load more ></button>
    </div>
  
    <!-- <mat-paginator [length]="token?100000:0" [pageSize]="pageSize" (page)="pageSize = $event.pageSize"></mat-paginator> -->
  </div>
</div>