import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-add-user-form',
  templateUrl: './add-user-form.component.html',
  styleUrls: ['./add-user-form.component.scss' , '../../../celo-input.scss']
})
export class AddUserFormComponent implements OnInit {

  @Output() formchange = new EventEmitter();

  fields = [
    {
      key:'title',
      displayName:'Title',
      type:'select',
      options:[
      ]
    },
    {
      key:'firstName',
      displayName:'Preferred Name',
      required:true,
      type:'text'
    },
    {
      key:'legalFirstName',
      displayName:'First Name',
      required:true,
      type:'text'
    },
    {
      key:'lastName',
      displayName:'Last Name',
      required:true,
      type:'text'
    },
    {
      key:'email',
      displayName:'Email',
      required:true,
      type:'text'
    },
    {
      key:'phoneNumber',
      displayName:'Phone Number',
      type:'tel'
    },
    {
      key:'departmentId',
      displayName:'Department',
      type:'select',
      options:[
      ]
    },
    {
      key:'position',
      displayName:'Position',
      type:'text'
    }
  ]

  @Input() formObject = {
    generatePassword:true
  };
  @Input() id ;

  constructor(
    private sharedService:SharedService
  ) { }

  ngOnInit() {
    this.setTitleOptions();
    this.setDepartmentOptions();
  }
  setTitleOptions(){
    let instance = this;
    this.getTitles(function(titles){
      if(!titles||!titles.length){
        return;
      }
      instance.fields.forEach(element => {
        if(element.key=='title'){
          element.options = [];
          titles.forEach(title => {
            element.options.push({
              id:title,
              name:title
            })
          });
        }
      });
    });
  }
  setDepartmentOptions(){
    let instance = this;
    this.getDepartments(function(departments){
      if(!departments||!departments.data||!departments.data.length){
        return;
      }
      instance.fields.forEach(element => {
        if(element.key=='departmentId'){
          element.options = [];
          element.options = departments.data;
        }
      });
    });
  }
  changed(validity){
    this.formObject['valid'] = validity;
    this.formchange.emit(this.formObject)
  }

  getTitles(callback){
    const path = environment.celoApiEndpoint+'/api/Account/Titles';
    this.sharedService.getObjectById(path).subscribe(
      data=>{
        callback(data);
      }
    )
  }
  getDepartments(callback){
    const path = environment.celoApiEndpoint+'/api/companies/'+this.id+'/departments';
    let params = {
      Page:0,
      PageSize:9999
    }
    this.sharedService.getObjectById(path,params).subscribe(
      data=>{
        callback(data);
      }
    )
  }

}
