import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

interface BasicUserConnection {
    id: string;
    email: string;
    title: string;
    firstName: string;
    lastName: string;
    state: string;
    lastModifyOnUtc: Date;
    createdBy: string;
}

@Component({
    selector: 'app-connections',
    templateUrl: './connections.component.html',
    styleUrls: ['./connections.component.scss']
})
export class ConnectionsComponent implements OnInit, OnDestroy {
    @Input('id') id: string;

    columns = ['name', 'email', 'state', 'lastModified', 'createdBy'];
    dataSource = new MatTableDataSource<BasicUserConnection>();

    pageSize: number = 10;
    pageIndex: number = 0;
    totalConnections: number = 0;
    isLoading: boolean = true;

    connectionsSubscription: Subscription;
    routeSubscription: Subscription;

    constructor(private sharedService: SharedService, private route: ActivatedRoute) {}

    ngOnDestroy(): void {
        if (this.connectionsSubscription) {
            this.connectionsSubscription.unsubscribe();
        }

        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.routeSubscription = this.route.params.subscribe((routeParams) => {
            this.id = routeParams['id'];
            this.update();
        });
        this.update();
    }

    private update(): void {
        this.isLoading = true;
        let path = `${environment.celoApiEndpoint}/api/Admin/Users/${this.id}/Connections`;
        this.connectionsSubscription = this.sharedService
            .getObjectById(path, {
                pageSize: this.pageSize,
                page: this.pageIndex
            })
            .pipe(
                map((res) => ({
                    ...res,
                    data: res.data.map((connection) => ({
                        ...connection,
                        lastModifyOnUtc: new Date(connection.lastModifyOnUtc)
                    }))
                }))
            )
            .subscribe({
                next: (res) => {
                    this.totalConnections = res.total;
                    this.dataSource.data = [...res.data];
                },
                error: (err) => {},
                complete: () => {
                    this.isLoading = false;
                }
            });
    }

    onRowClick(row: BasicUserConnection): void {
        this.sharedService.navigate(['/user/' + row.id]);
    }

    onPageChange($event: PageEvent): void {
        this.pageSize = $event.pageSize;
        this.pageIndex = $event.pageIndex;
        this.update();
    }
}
