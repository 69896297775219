<div class="inline_block celo-input-outline">
  <div class="field">
    <mat-form-field class="fw" appearance="outline">
      <mat-label>Workspace</mat-label>
      <mat-select [(ngModel)]="workplace['companyId']" required name="companyId" (ngModelChange)="changed();workplaceSelected()" (openedChange)="filter.focus()">
        <mat-form-field class="filter">
          <mat-icon matPrefix>search</mat-icon>
          <input id="filter" matInput #filter (keyup.enter)="search($event.target.value)" (keydown)="$event.stopPropagation()" placeholder="Search" autocomplete="off">
        </mat-form-field>
          <mat-option *ngIf="!workplaces||!workplaces.length">
            <span *ngIf="!searching">
              Please search for a workspace
            </span>  
            <span *ngIf="searching">
              Searching...
            </span>  
          </mat-option>
          <mat-option *ngFor="let option of workplaces" [value]="option.id">
            {{option.name}}
          </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="fw" appearance="outline">
      <mat-label>Work Email</mat-label>
      <input class="fw" matInput required [(ngModel)]="workplace['email']" type="email" [placeholder]="'Email'" (ngModelChange)="changed();">
    </mat-form-field>
    <mat-form-field class="fw" appearance="outline">
      <mat-label>Department</mat-label>
      <mat-select [(ngModel)]="workplace['departmentId']" name="departmentId" (ngModelChange)="changed();" (openedChange)="filter.focus()">
        <mat-form-field class="filter">
          <mat-icon matPrefix>search</mat-icon>
          <input id="filter" matInput #filter (keyup)="searchDepartment($event.target.value)" (keydown)="$event.stopPropagation()" placeholder="Search" autocomplete="off">
        </mat-form-field>
          <mat-option *ngFor="let option of selectedDepartments" [value]="option.id">
            {{option.name}}
          </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="fw" appearance="outline">
      <mat-label>Position</mat-label>
      <input class="fw" matInput [(ngModel)]="workplace['position']" type="text" [placeholder]="'Position'" (ngModelChange)="changed();">
    </mat-form-field>
    <mat-checkbox class="example-margin" [(ngModel)]="workplace['isNotifyUser']">Notify user via email</mat-checkbox>
  </div>
  <app-celo-cancel-save-buttons [showSave]="true" [saveButtonDisabled]="!workplace['companyId']||loading" [showCancel]="true" (cancel)="closeDialog()" (save)="addWorkplace()" [alignment]="'right'"></app-celo-cancel-save-buttons>
</div>