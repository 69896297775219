<div class="info-form celo-input-outline">
  <div *ngFor="let object of formObject" class="field">
    <div *ngIf="!object.disabled">
      <div *ngIf="object.type=='radio'" >
        <div class="field_title">{{object.name}}</div>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="object.value" class="field-input" (ngModelChange)="valueChange()">
          <mat-radio-button *ngFor="let option of object.options" [value]="option.value">{{option.name}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngIf="object.type=='textarea'" class="field">
        <div class="field_title">{{object.name}}</div>
        <mat-form-field class="fw" appearance="outline">
          <textarea matInput id="celo-send-message-textarea" [placeholder]="object.placeholder" #txtArea [(ngModel)]="object.value" (ngModelChange)="valueChange()">
          </textarea>
        </mat-form-field>
      </div>
      <div *ngIf="object.type=='caption'" class="field caption">
        <div class="field_title">{{object.caption}}</div>
      </div>
      <div *ngIf="object.type=='text'" class="field">
          <mat-form-field class="fw" appearance="outline">
            <mat-label>{{object.name}}</mat-label>
            <input class="fw" matInput [(ngModel)]="object.value" type="text" [placeholder]="object.placeholder" (ngModelChange)="valueChange()">
          </mat-form-field>
      </div>
      <div *ngIf="object.type=='select'" class="field">
          <mat-form-field appearance="outline">
            <mat-label>{{object.name}}</mat-label>
            <mat-select [(ngModel)]="object.value" (ngModelChange)="valueChange()">
              <mat-option *ngFor="let option of object.options" [value]="option.name">
                {{option.name}}
              </mat-option> 
            </mat-select>
          </mat-form-field>
      </div>
    </div>
  </div>
</div>