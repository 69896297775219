import { updateElement } from './../../../../utils/array-utils';
import { MatDialog } from '@angular/material/dialog';
import { EditDeleteRequestComponent } from './../edit-delete-request/edit-delete-request.component';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { merge, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DeleteUserRequest, DeleteUserRequestStatus } from 'types';
import { AdminUsersService } from './../../core/services/admin-users.service';
import { AlertService } from 'core/services/alert.service';

interface DeleteRequestsQueryParams {
    status?: DeleteUserRequestStatus;
}

function isDeleteRequestsQueryParams(value: unknown): value is DeleteRequestsQueryParams {
    return typeof value === 'object';
}

@Component({
    selector: 'app-delete-requests',
    templateUrl: './delete-requests.component.html',
    styleUrls: ['./delete-requests.component.scss']
})
export class DeleteRequestsComponent implements OnInit, OnDestroy {
    public pageSize: number = 20;
    public hasNextPage: boolean = false;
    public isLoading: boolean = false;
    public selectedStatus: DeleteUserRequestStatus | null = null;
    public statuses: DeleteUserRequestStatus[] = ['Received', 'Queued', 'Failed', 'Completed', 'Cancelled'];

    public displayedColumns: string[] = ['name', 'email', 'createdOnUtc', 'status', 'reason', 'edit', 'delete'];
    public dataSource: DeleteUserRequest[] = [];

    private deleteUserRequestsSubscription: Subscription | null = null;
    private nextPageToken: string | null = null;
    private next: EventEmitter<void> = new EventEmitter();

    public constructor(
        private adminUsersService: AdminUsersService,
        private activatedRoute: ActivatedRoute,
        private alertService: AlertService,
        private matDialog: MatDialog
    ) {}

    public ngOnInit(): void {
        const params$ = this.activatedRoute.queryParams.pipe(map((params) => params as DeleteRequestsQueryParams));

        this.deleteUserRequestsSubscription = merge(this.next, params$)
            .pipe(
                switchMap((value) => {
                    const queryParams = isDeleteRequestsQueryParams(value) ? value : null;
                    this.isLoading = true;

                    if (queryParams && this.selectedStatus !== queryParams.status) {
                        this.selectedStatus = queryParams?.status ?? null;
                        this.hasNextPage = false;
                        this.nextPageToken = null;
                    }

                    return this.adminUsersService.getDeleteUserRequests({
                        pageSize: this.pageSize,
                        status: this.selectedStatus,
                        token: this.nextPageToken
                    });
                }),
                map((page) => {
                    this.isLoading = false;
                    this.hasNextPage = page.hasNext;
                    this.nextPageToken = page.token;
                    return page.data;
                })
            )
            .subscribe({
                next: (deleteUserRequests) => {
                    this.dataSource = deleteUserRequests;
                }
            });
    }

    public ngOnDestroy(): void {
        this.deleteUserRequestsSubscription?.unsubscribe();
    }

    public nextPage(): void {
        this.next.emit();
    }

    public editRequest(request: DeleteUserRequest): void {
        EditDeleteRequestComponent.openDialog(this.matDialog, { request })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    const updatedRequest = result?.updatedRequest;
                    if (!updatedRequest) return;
                    this.dataSource = updateElement(
                        this.dataSource,
                        (r) => r.id === updatedRequest.id,
                        () => updatedRequest
                    );
                }
            });
    }

    public deleteUser(userId: string, requestId: string) : void {
        this.adminUsersService.deleteUserInfo(userId).subscribe({
            next: () => {
                this.adminUsersService.updateDeleteUserRequest(requestId, "Completed").subscribe({
                    next: (result) => {
                        if (!result) return;
                        this.dataSource = updateElement(
                            this.dataSource,
                            (r) => r.id === result.id,
                            () => result
                        );

                        this.alertService.showSnackBar("User Deleted!", 2);
                    }
                });
            },
            error: () => {
                this.alertService.showErrorSnackbar("Failed to delete user");
            }
        });
        this.alertService.showSnackBar("Deleting User...", 3);
    }
}
