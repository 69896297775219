<div class="parent celo-content">

  <div class="top-buttons">
    <div class="left">
      <input type="search" [(ngModel)]="query" class="celo-input celo-search" placeholder="Search" (keyup.enter)="search(query,range)">
    </div>
    <div class="right">
      <button mat-button class="celo-tertiary-button" (click)="copyEmails(selection.selected)" [disabled]="selection&&selection.selected&&selection.selected.length?false:true">Copy email(s)</button>
      <app-celo-date-range [startDate]="startDate" [endDate]="endDate" (rangeChange)="rangeChange($event)"></app-celo-date-range>
    </div>
  </div>
  <div class="relative-table-container">
    <div *ngIf="isLoadingResults" class="loading-shade">
      <app-empty-state *ngIf="isLoadingResults"></app-empty-state>
    </div>
    
    <div class="table-container">
      <table mat-table [dataSource]="data" class="table" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>&nbsp;&nbsp;
          </th>
          <td mat-cell> test </td>
          <td mat-cell *matCellDef="let row" class="checkbox">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>&nbsp;&nbsp;
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row" class="name-row">
            <div class="name-content">
              <div class="avatar">
                <app-avatar [name]="row.firstName + ' ' + row.lastName" [userId]="row.userId" [width]="36" [height]="36" [hasImage]="!!row.picture"></app-avatar>
              </div>
              <span class="name">{{row.title}} {{row.firstName}} {{row.lastName}}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Primary Email</th>
          <td mat-cell *matCellDef="let row">{{row.email}}</td>
        </ng-container>
        <ng-container matColumnDef="registeredDate">
          <th mat-header-cell *matHeaderCellDef>Joined On</th>
          <td mat-cell *matCellDef="let row">{{row.registeredDate|date:'medium'}}</td>
        </ng-container>
        <ng-container matColumnDef="lastActiveTime">
          <th mat-header-cell *matHeaderCellDef>Last Active</th>
          <!-- <td mat-cell *matCellDef="let row">{{row.lastActiveTime|date:'medium'}}</td> -->
          <td mat-cell *matCellDef="let row">{{(row.lastActiveTime=='0001-01-01T00:00:00.000Z')?'-':(row.lastActiveTime|date:'medium')}}</td>
        </ng-container>
        <ng-container matColumnDef="workplaces">
          <th mat-header-cell *matHeaderCellDef>Workspaces</th>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="row.workplaces&&row.workplaces.length">
              <div *ngFor="let workplace of row.workplaces" class="workspace-name">
                <div>{{workplace.companyName}} <span *ngIf="!workplace.isActive"> (Left)</span></div>
              </div>
            </div>
            <div *ngIf="!row.workplaces||!row.workplaces.length">
              -
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr class="celo-hoverable celo-cursor-pointer" (click)="itemClick(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div *ngIf="!data||!data.data||!data.data.length" class="no-results">- No results found -</div>
    </div>
  
    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 100, 500]" [pageSize]="pageSize" (page)="pageSize = $event.pageSize"></mat-paginator>
  </div>
</div>