import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from './../material/material.module';
import { SharedModule } from './../shared/shared.module';
import { DeleteRequestsComponent } from './delete-requests-home/delete-requests.component';
import { DeleteRequestsRoutingModule } from './delete-requests-routing.module';
import { EditDeleteRequestComponent } from './edit-delete-request/edit-delete-request.component';

@NgModule({
    declarations: [DeleteRequestsComponent, EditDeleteRequestComponent],
    imports: [CommonModule, DeleteRequestsRoutingModule, MaterialModule, SharedModule, ReactiveFormsModule]
})
export class DeleteRequestsModule {}
