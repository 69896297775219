import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { AlertService } from "core/services/alert.service";
import { AuthService } from "core/services/auth.service";
import { SharedService } from "./shared.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LicenseService {
    constructor(
        private sharedService: SharedService,
        private alertService: AlertService,
        private httpClient: HttpClient,
        private authService: AuthService,
        private sanitizer: DomSanitizer
    ) { }

    createLicenses(licenseCount: number, expiryDate: Date, companyId: string) {
        const path = `${environment.celoApiEndpoint}/api/admin/v2/companies/${companyId}/licenses/create`;
        return this.sharedService.postObjectById(path, {
            licenseCount: licenseCount,
            expiresAt: expiryDate.toISOString()
        });
    }

    renewLicenses(expiryDate: Date, companyId: string) {
        const path = `${environment.celoApiEndpoint}/api/admin/v2/companies/${companyId}/licenses/renew`;
        return this.sharedService.postObjectById(path, {
            expiresAt: expiryDate.toISOString()
        });
    }
}