import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'core/services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SharedService } from './services/shared.service';
import { environment } from 'src/environments/environment';
import { SettingsService } from './services/settings.service';
import { authCodeFlowConfig } from './auth-code-flow.config';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'celo-super-admin';
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;
  version:string;

  constructor(
    public authService:AuthService,
    public sharedService:SharedService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router
    ,private settingsService: SettingsService
    ,private oauthService: OAuthService
  ){
    this.version = environment.appVersion;
    iconRegistry.addSvgIcon(
      'celo-home',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/avatar-company.svg'));

    this.configureCodeFlow();
    // Automatically load user profile
    this.oauthService.events
      .pipe(filter(e => e.type === 'token_received'))
      .subscribe(_ => {
        this.oauthService.loadUserProfile();
        this.authService.refreshTokenInProgress = false;
        if (this.router.url === "/" || !this.router.url) {
          window.location.href = '/workpaces';
          this.sharedService.navigate('/workplaces');
        }
      },err=>{
        this.authService.logout()
      },()=>{
      });
      this.setLoggedInStatus()
  }
  setLoggedInStatus(){
    setTimeout(() => {
      if(!this.authService.getAT()){
        this.authService.login();
      }
      // else{
      //   this.sharedService.navigateTo('/workplaces',{});
      // }
    }, 5000);
  }
  private configureCodeFlow() {
    this.oauthService.setStorage(localStorage);

    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(_ => {
        let returnUrl = '/workplaces';
        if(this.router.url && this.router.url != '/' && this.router.url.indexOf('/signin-oidc')==-1 ){
          returnUrl = this.router.url;
        }
        this.router.navigateByUrl(returnUrl);
    });

    // Optional
    this.oauthService.setupAutomaticSilentRefresh();
    
  }
  ngOnInit(){
    // this.settingsService.getSettings();
    let anchor = document.getElementById('scrollMe') as HTMLElement;
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationStart) {
      }
      if(val instanceof NavigationEnd) {
        this.sharedService.scrollToAnchor(anchor);
      }
    });
    
  }
}
