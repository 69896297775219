import { defaultEnvironment, Environment } from './environment.default';

export const environment: Environment = {
    ...defaultEnvironment,
    production: false,
    celoAuth: '59ac8c4bb45f5b0001e38d913d00e8f4a95b45a46ab694d4b8fbdef3',
    celoApiEndpoint: 'https://celo-api-staging.celohealth.com',
    celoAdminApiEndpoint: 'https://celo-api-staging.celohealth.com',
    celoAuthApiEndpoint: 'https://login-staging.celohealth.com',
    origin: 'https://admin-staging.celohealth.com',
    clientId: 'celo.admintool.3A5B86050DF3'
};
