


<div class="navbar">
  <div class="celo-page navbar-content">
    <a [routerLink]="['/']" class="logo">
      <img src="/assets/logo-v2-invert.svg">
    </a>
    <div class="contents">
      <div class="left-buttons">
        <span *ngFor="let tab of tabs" >
          <a *ngIf="tab.type=='button'" [matTooltip]="tab.tooltip?tab.tooltip:''" matRipple [routerLink]="[tab.link,tab.params?tab.params:{}]" [routerLinkActive]="'is-active'" class="tab-link celo-hoverable-light" [id]="tab.id">
            <div>
              <span>{{tab.name}}</span>
            </div>
          </a>
          <a *ngIf="tab.type=='disabled'" [matTooltip]="tab.tooltip?tab.tooltip:''" matRipple class="tab-link tab-link-disabled celo-hoverable-light" [id]="tab.id">
            <div>
              <span>{{tab.name}}</span>
            </div>
          </a>
          <a *ngIf="tab.type=='menu'" [matTooltip]="tab.tooltip?tab.tooltip:''" class="tab-link celo-hoverable-light" [matMenuTriggerFor]="menu">
            <div>
              <span>
                {{tab.name}}
              </span>
            </div>
          </a>
          <mat-menu #menu="matMenu" class="menu">
            <a *ngFor="let subtab of tab.tabs" mat-menu-item [routerLink]="[subtab.link,subtab.params?subtab.params:{}]" [routerLinkActive]="'is-active'">
              {{subtab.name}}
            </a>
          </mat-menu>
        </span>
      </div>
      <div id="nav-menu" matRipple class="profile_button right-buttons celo-hoverable-light" [matMenuTriggerFor]="menu">
        <div *ngIf="userAccount" class="icon">
          <app-avatar [name]="userAccount.firstName + ' ' + userAccount.lastName"
                            [userId]="userAccount.userId" [width]="36" [height]="36"
                            [hasImage]="!!userAccount.picture"
                            [userId]="userAccount.userId"
                            
                            [nocache]="true"
                            [refreshCount]="avatarRefreshCount"
                            ></app-avatar>
        </div>
        <div class="name">
          <div class="texts">
            <div *ngIf="userAccount" class="text">{{userAccount.firstName}} {{userAccount.lastName}}</div>
            <div *ngIf="!userAccount" class="text">Profile Info</div>
            <div [style.width.px]="userAccount&&userAccount.doNotDisturb?154:0" [style.height.px]="userAccount&&userAccount.doNotDisturb?13:0" class="subtext celo-open-horizontal">You are set to ‘Do not disturb’</div>
          </div>
          <span class="arrowicon">
            <i class="material-icons">keyboard_arrow_down</i>
          </span>
        </div>
      </div>
      <mat-menu #menu="matMenu" xPosition="before" backdropClass="menu">
        <a *ngIf="userAccount" mat-menu-item class="profile" [routerLink]="['/user/'+userAccount.userId]">
          <div class="avatar">
            <app-avatar *ngIf="userAccount.userId" [name]="userAccount.firstName + ' ' + userAccount.lastName"
                  [userId]="userAccount.userId" [width]="40" [height]="40" [hasImage]="!!userAccount.picture"
                  [nocache]="true"
                  [refreshCount]="avatarRefreshCount"
                  ></app-avatar>
          </div>
          <div class="text">
            <div class="name">{{userAccount.firstName}} {{userAccount.lastName}}</div>
            <div class="subname">View your profile</div>
          </div>
        </a>
        <div class="title">&nbsp;</div>
        <button mat-menu-item routerLink="/accesslog" id="celo-logout-button">
          <div class="menu-item celo-red-text">
            <mat-icon class="material-icons celo-red-text">list_alt</mat-icon>
            <span class="celo-red-text">Access Logs</span>
          </div>
        </button>
        <button mat-menu-item routerLink="/settings" id="celo-logout-button">
          <div class="menu-item celo-red-text">
            <mat-icon class="material-icons celo-red-text">settings</mat-icon>
            <span class="celo-red-text">Settings</span>
          </div>
        </button>
        <button mat-menu-item (click)="logout()" id="celo-logout-button">
          <div class="menu-item celo-red-text">
            <mat-icon class="material-icons celo-red-text">exit_to_app</mat-icon>
            <span class="celo-red-text">Log out</span>
          </div>
        </button>
      </mat-menu>
    </div>
  </div>
</div>