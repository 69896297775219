<div class="parent celo-content">
    <div class="relative-table-container">
      <div *ngIf="isLoadingResults" class="loading-shade">
        <app-empty-state *ngIf="isLoadingResults"></app-empty-state>
      </div>
      
      <div class="table-container">
        <table mat-table [dataSource]="data" class="table" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row" class="name-row">
              <div class="avatar">
                <app-avatar [name]="row.firstName + ' ' + row.lastName" [userId]="row.userId" [width]="36" [height]="36" [hasImage]="!!row.profilePic"></app-avatar>
              </div>
              <span class="name">{{row.title}} {{row.firstName}} {{row.lastName}}</span>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <div class="action-buttons">
                    <a *ngIf="row['userId']" [routerLink]="'/user/'+row['userId']">
                        <button mat-button class="celo-secondary-button">
                            View profile
                        </button>
                    </a>
                    <button *ngIf="!row['userId']" disabled mat-button class="celo-secondary-button">
                        View profile
                    </button>
                </div>
            </td>
          </ng-container>
    
          <tr mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr class="celo-hoverable" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          
        </table>
        <div *ngIf="!data||!data.data||!data.data.length" class="no-results">- No results found -</div>
      </div>
    
      <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize" (page)="pageSize = $event.pageSize"></mat-paginator>
    </div>
  </div>