import { Injectable } from '@angular/core';
import { AuthService } from 'core/services/auth.service';
import countryCodes from 'country-codes-list';
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';

export interface Country {
    name: string;
    shortName: string;
}

const myCountryCodesObject: {
    [x: string]: string;
} = countryCodes.customList('countryCode', '{countryNameEn}');

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    settings: any = {};
    countries: Country[] = [];
    constructor(private sharedService: SharedService, private authService: AuthService) {
        this.getSettings();
        for (const key in myCountryCodesObject) {
            if (myCountryCodesObject.hasOwnProperty(key)) {
                this.countries.push({
                    name: myCountryCodesObject[key],
                    shortName: key.toLowerCase()
                });
            }
        }
        this.sharedService.sortArrayByField(this.countries, 'name');
    }
    getSettings(callback?) {
        const path = environment.celoApiEndpoint + '/api/Settings';
        this.sharedService.getObjectById(path).subscribe((data) => {
            this.settings = data;
            if (callback) callback(data);
        });
    }
    getCountryNameFromCode(value: string) {
        value = value ? value : '';
        let name = value.toLowerCase();
        if (this.countries) {
            this.countries.forEach((country) => {
                if (country.shortName == name) {
                    name = country.name;
                }
            });
        }
        return name;
    }
    // getCountryNameFromCode(value: string) {
    //   value = value?value:'';
    //   let name = value.toLowerCase();
    //   if(this.settings['supportedCountries']){
    //     this.settings['supportedCountries'].forEach(country => {
    //       if(country.shortName==name){
    //         name = country.name;
    //       }
    //     });
    //   }
    //   return name;
    // }
    getSupportedCountries(callback) {
        if (!this.settings['supportedCountries']) {
            this.getSettings(function (settings) {
                return callback(settings['supportedCountries']);
            });
        }
        else {
            callback(this.settings['supportedCountries']);
        }
    }
    getFilteredSupportedCountries(callback) {
        let supportedCountries = [];
        let instance = this;
        this.getSupportedCountries(function (countries: []) {
            supportedCountries = countries.filter(
                (country) => country['dataRegionId'] == instance.authService.getRegion()
            );
            callback(supportedCountries);
        });
    }
}
