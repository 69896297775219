import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { AvatarService } from '../../core/services/avatar.service';

const imageUrlPlaceholder = '/assets/profilepic.png';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
    isLoading = false;
    @Input() imageUrl: string | SafeUrl = imageUrlPlaceholder;
    @Input() height = '24';
    @Input() width = '24';
    @Input() image_size = 300;
    @Input() userId;
    @Input() companyId;
    @Input() refreshCount = 0;
    @Input() nocache;
    @Input() hasImage = false;
    @Input() name;
    @Input() doNotDisturbToUtc: string;
    @Input() dndMessage: boolean;
    @Input() badgeBorder = '2';
    @Input() badgeMargin = '10';
    @Input() badgeSize = '30';
    @Input() isDataUrl = false;
    isOnDND: boolean;
    sub: number;

    constructor(private avatarService: AvatarService, private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {}

    ngOnChanges() {
        if (this.isDataUrl || !this.hasImage) return;

        this.isLoading = true;
        this.imageUrl = imageUrlPlaceholder;

        if (this.userId) {
            this.loadUserAvatar();
        } else if (this.companyId) {
            this.loadWorkspaceAvatar();
        } else {
            this.isLoading = false;
        }
    }

    private loadUserAvatar() {
        this.avatarService.loadPicture(this.userId, 300, this.nocache).subscribe(this.handleLoad.bind(this));
    }

    private loadWorkspaceAvatar() {
        this.avatarService
            .loadWorkspacePicture(this.companyId, 'Full', this.nocache)
            .subscribe(this.handleLoad.bind(this));
    }

    private handleLoad(url: SafeUrl) {
        this.isLoading = false;
        this.imageUrl = url;
        this.changeDetectorRef.detectChanges();
    }
}
