
<div #parent class="parent">
  <div #navbar class="navbar">
    <div *ngIf="authService.getAT()">
      <app-navbar></app-navbar>
    </div>
  </div>
  
  <div #content [style.height.px]="parent.offsetHeight-navbar.offsetHeight" class="page">
    <div class="celo-page page-content">
      <div #scrollMe id="scrollMe"></div>
      <div class="content">
        <router-outlet></router-outlet>
        <div *ngIf="!authService.getAT()" class="loading">
          <app-empty-state></app-empty-state>
        </div>
      </div>
      <div *ngIf="authService.getAT()" class="celo-content">
        © 2016 - Celo - Version {{version}}
      </div>
    </div>
  </div>
</div>
