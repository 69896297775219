<h1 mat-dialog-title>Create delete user request</h1>

<div mat-dialog-content class="content">
    <form [formGroup]="deleteUserForm" class="celo-input-outline">
        <mat-form-field class="example-form-field" appearance="outline">
            <mat-label>Reason</mat-label>
            <input matInput type="text" formControlName="reason" [attr.disabled]="isSubmitting || null" />
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions class="actions">
    <button mat-button mat-dialog-close class="celo-secondary-button">Cancel</button>
    <button mat-button class="celo-primary-button" (click)="submit()" [disabled]="isSubmitting">Create</button>
</div>
