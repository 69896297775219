import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompanyModel, CreateWorkspaceAddressModel, WorkspaceAddressModel, Operation, ImageSize } from 'src/types';
import { AlertService } from '../modules/core/services/alert.service';
import { AuthService } from 'core/services/auth.service';
import { SharedService } from './shared.service';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceService {
    constructor(
        private sharedService: SharedService,
        private alertService: AlertService,
        private httpClient: HttpClient,
        private authService: AuthService,
        private sanitizer: DomSanitizer
    ) {}

    getWorkspaceClaims(id: string, callback) {
        let path = environment.celoApiEndpoint + '/api/admin/companies/' + id + '/settings/defaultUserClaims';
        this.sharedService.getObjectById(path).subscribe(
            (res) => {
                callback(res);
            },
            (err) => {
                callback();
            }
        );
    }

    getWorkspace(companyId: string): Observable<CompanyModel> {
        const path = `${environment.celoApiEndpoint}/api/admin/companies/${companyId}`;
        return this.sharedService.getObjectById(path);
    }

    createAddress(companyId: string, address: CreateWorkspaceAddressModel): Observable<WorkspaceAddressModel> {
        const path = `${environment.celoApiEndpoint}/api/admin/companies/${companyId}/addresses`;
        return this.sharedService.postObjectById(path, address);
    }

    updateAddress(companyId: string, addressId: string, operations: Operation[]): Observable<WorkspaceAddressModel> {
        const path = `${environment.celoApiEndpoint}/api/admin/companies/${companyId}/addresses/${addressId}`;
        return this.sharedService.patchObjectById(path, operations);
    }

    deleteAddress(companyId: string, addressId: string): Observable<WorkspaceAddressModel> {
        const path = `${environment.celoApiEndpoint}/api/admin/companies/${companyId}/addresses/${addressId}`;
        return this.sharedService.deleteObjectById(path);
    }

    updateProfileImage(companyId: string, file: File): Observable<CompanyModel> {
        const path = `${environment.celoApiEndpoint}/api/admin/companies/${companyId}`;

        const formData = new FormData();
        formData.append('file', file);

        return this.sharedService.patchObjectById(path, formData);
    }
}
