import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

export interface ApiRequestOptions {
    path?: string;
    queryParams?: HttpParams | Record<string, string | string[] | number | number[] | boolean | boolean[] | undefined>;
    body?: any;
}

export interface BinaryApiRequestOptions {
    responseType: 'blob';
}

export class NoInternetError extends Error {
    constructor() {
        super('No internet');
    }
}

export type HttpRequestMethod = 'post' | 'get' | 'put' | 'patch' | 'delete';

export interface TokenPagedResult {
    token?: string | null;
}

export interface TokenPagedRequestOptions {
    token?: string;
    pageSize?: number;
}

export type TokenPagedApiRequestOptions = ApiRequestOptions & TokenPagedRequestOptions;

export interface OffsetPagedResult {
    page?: number | null;
    totalPages?: number | null;
}

export type OffsetPagedApiRequestOptions = ApiRequestOptions & OffsetPagedResult;

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    protected basePath = environment.celoApiEndpoint;

    constructor(private http: HttpClient) {}

    public request<T>(method: HttpRequestMethod, options: ApiRequestOptions): Observable<T>;

    public request(
        method: HttpRequestMethod,
        options: ApiRequestOptions,
        binaryOptions: BinaryApiRequestOptions
    ): Observable<Blob>;

    public request<T>(
        method: HttpRequestMethod,
        options: ApiRequestOptions,
        binaryOptions?: BinaryApiRequestOptions
    ): Observable<T | Blob> {
        if (!this.isOnline()) return this.throwNoInternetError();
        const { path, queryParams, body } = options;
        const params = queryParams instanceof HttpParams ? queryParams : this.createHttpParams(queryParams ?? {});

        if (binaryOptions) {
            return this.http.request(method, this.basePath + path, {
                params,
                body,
                responseType: 'blob'
            });
        } else {
            return this.http.request<T>(method, this.basePath + path, {
                params,
                body
            });
        }
    }

    public post<T>(options: ApiRequestOptions): Observable<T> {
        return this.request('post', options);
    }

    public get<T>(options: ApiRequestOptions): Observable<T> {
        return this.request('get', options);
    }

    public put<T>(options: ApiRequestOptions): Observable<T> {
        return this.request('put', options);
    }

    public patch<T>(options: ApiRequestOptions): Observable<T> {
        return this.request('patch', options);
    }

    public delete<T>(options: ApiRequestOptions): Observable<T> {
        return this.request('delete', options);
    }

    // #TODO refactor 'getAll' methods

    /**
     * Fetches all pages using cursor/token based pagination.
     *
     * @returns an observable that pushes each page as it's fetched, and completes when the last page has been fetched.
     */
    public getAllByToken<T extends TokenPagedResult>(options: TokenPagedApiRequestOptions): Observable<T> {
        const pageOptions: TokenPagedApiRequestOptions = {
            ...options
        };

        const getPage = (subscriber: Subscriber<T>) => {
            const subscription = this.get<T>(pageOptions).subscribe({
                next: (value) => {
                    subscriber.next(value);

                    // Fetch next page if available
                    if (value.token) {
                        pageOptions.token = value.token;
                        getPage(subscriber);
                    } else {
                        subscriber.complete();
                    }
                },
                error: (err) => subscriber.error(err)
            });
            return () => subscription.unsubscribe();
        };

        const observable = new Observable<T>(getPage);

        return observable;
    }

    /**
     * Fetches all pages using offset based pagination.
     *
     * @returns an observable that pushes each page as it's fetched, and completes when the last page has been fetched.
     */
    public getAllByOffset<T extends OffsetPagedResult>(options: OffsetPagedApiRequestOptions): Observable<T> {
        const pageOptions: OffsetPagedApiRequestOptions = {
            ...options
        };

        const getPage = (subscriber: Subscriber<T>) => {
            const subscription = this.get<T>(pageOptions).subscribe({
                next: (value) => {
                    subscriber.next(value);

                    // Fetch next page if available
                    if (value.totalPages && value.page && value.totalPages < value.page) {
                        pageOptions.page = value.page + 1;
                        getPage(subscriber);
                    } else {
                        subscriber.complete();
                    }
                },
                error: (err) => subscriber.error(err)
            });
            return () => subscription.unsubscribe();
        };

        const observable = new Observable<T>(getPage);

        return observable;
    }

    private createHttpParams(obj: object): HttpParams {
        let params = new HttpParams();

        const mapValue = ([key, value]: [string, unknown]) => {
            switch (typeof value) {
                case 'string':
                    params = params.append(key, value);
                    break;
                case 'number':
                    params = params.append(key, value.toString());
                    break;
                case 'boolean':
                    params = params.append(key, value.toString());
                    break;
                case 'object':
                    if (!Array.isArray(value)) return;
                    value.forEach((v) => mapValue([key, v]));
                    break;
                default:
                    return;
            }
        };

        Object.entries(obj).forEach(mapValue);

        return params;
    }

    private isOnline(): boolean {
        return navigator.onLine;
    }

    private throwNoInternetError() {
        return throwError(new NoInternetError());
    }
}
