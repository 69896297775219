import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AlertService } from '../modules/core/services/alert.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {
  removeProfessionClick(profession, callback) {
    this.alertService.customDialog('Are you sure you want to remove the user’s profession? ','If you continue, the user will lose their profession and verification badge.','CONTINUE','CANCEL',false).afterClosed().subscribe(
      res=>{
        if(!res)
          return;
          this.removeProfession(profession.id, callback)
      }
    )
  }
  removeProfession(id, callback){
    const path = environment.celoApiEndpoint + '/api/admin/professionVerifications/'+id
    this.sharedService.deleteObjectById(path).subscribe(
      data=>{
        callback(data);
      },err=>{
        this.alertService.customDialog("","An error occurred while removing user's profession.",'Close','',true);
      });
  }
  
  

  constructor(
    private alertService:AlertService,
    private sharedService:SharedService
  ) { }


  addProfession(userId, callback) {
    let payload = [
      {
        type:'celo-form',
        formObject:[
          {
            type:'select',
            name:'Profession',
            key:'category',
            optionsLink:`${environment.celoApiEndpoint}/api/settings/professions`,
            options:[]
          },
          {
            type:'text',
            name:'Specify Profession',
            key:'profession',
            parentKey:'category',
            enabledOnParentProperty:'allowFreeformProfession'
          },
          {
            type:'text',
            name:'Name of register',
            key:'registrationName'
          },
          {
            type:'text',
            name:'Registration number/AHPRA number(Aus user)',
            key:'registrationNumber'
          },
          {
            type:'caption',
            caption:'- OR -'
          },
          {
            type:'text',
            name:'Registration link',
            key:'registrationLink'
          },
          {
            type:'radio',
            name:'Profession verification',
            value:'Verified',
            key:'status',
            parentKey:'category',
            enabledOnParentProperty:'canVerify',
            options:[
              {
                name:'Verified',
                value:'Verified'
              },
              {
                name:'Unverified',
                value:'Unverified'
              }
            ]
          },
          {
            type:'textarea',
            name:'',
            key:'note',
            placeholder:'Enter notes here* (For example, manual verification)'
          }
        ]
      }
    ]
    this.alertService.customDialog("Profession Verification","",'Save','Cancel',false,'',payload).afterClosed().subscribe(
      result=>{
        if(!result||!result.payload){
          return;
        }
        let payload = result.payload;
        let body = {};
        body['registrationName'] = payload['registrationName'];
        body['registrationNumber'] = payload['registrationNumber'];
        body['registrationLink'] = payload['registrationLink'];
        body['category'] = payload['category'];
        body['profession'] = payload['profession'];
        body['verificationStatus'] = payload['status'];
        body['userId'] = userId;
        this.alertService.showSnackBar('Updating. Please wait.',1)
        const path = environment.celoApiEndpoint + '/api/admin/professionVerifications'
        this.sharedService.postObjectById(path, body).subscribe(
          data=>{
            this.alertService.showSnackBar('Verification status updated successfully',4)
            callback(data)
          },
          err=>{
            let message = '';
            let error = err['error'];
            for (const key in error) {
              if (error.hasOwnProperty(key)) {
                message = error[key][0]+' ';
              }
            }
            this.alertService.customDialog('Failed',message,'Close','',true);
          },
          ()=>{}
        )
      }
    )
  }

  editVerificationStatusIdentity(identity, status?, callback?) {
    status = (status=='Verified')?'Completed':status;
    let payload = [
      {
        type:'celo-form',
        formObject:[
          {
            type:'radio',
            name:'Identity Verification',
            value:status,
            key:'status',
            options:[
              {
                name:'Verified',
                value:'Completed'
              },
              {
                name:'Unverified',
                value:'Unverified'
              }
            ]
          },
          {
            type:'textarea',
            name:'',
            key:'note',
            placeholder:'Enter notes here* (For example, manual verification)'
          }
        ]
      }
    ]
    this.alertService.customDialog("Identity Verification","",'Save','Cancel',false,'',payload).afterClosed().subscribe(
      result=>{
        if(!result||!result.payload){
          return;
        }
        let payload = result.payload;
        this.editStatusIdentityVerification(payload['note'],payload['status'],identity,callback)
      }
      )
  }
  editStatusIdentityVerification(note,status,identity,callback){
    // let body = identity;
    let body = {
      id:identity.id,
      userId:identity.userId
    };
    body['notes']=note;
    body['status']=status;
    if(status=="Completed"){
      body['result']=true;
    }
    this.alertService.showSnackBar('Updating. Please wait.',1)
    const path = environment.celoApiEndpoint + '/api/admin/IdentityVerifications'
    this.sharedService.putObjectById(path, body).subscribe(
      data=>{
        this.alertService.showSnackBar('Verification status updated successfully',4)
        callback(data)
      },
      err=>{
        let message = '';
        let error = err['error'];
        for (const key in error) {
          if (error.hasOwnProperty(key)) {
            message = error[key][0]+' ';
          }
        }
        this.alertService.customDialog('Failed',message,'Close','',true);
      },
      ()=>{}
    )
  }

  editVerificationStatusProfession(profession, status?, userId?, callback?){
    let payload = [
      {
        type:'celo-form',
        formObject:[
          {
            type:'radio',
            name:'Profession verification',
            value:status?status:profession.verificationStatus,
            key:'status',
            options:[
              {
                name:'Verified',
                value:'Verified'
              },
              {
                name:'Unverified',
                value:'Unverified'
              },
              {
                name:'More Info Required',
                value:'MoreInfoRequired'
              }
            ]
          },
          {
            type:'textarea',
            name:'',
            key:'note',
            placeholder:'Enter notes here* (For example, manual verification)'
          }
        ]
      }
    ]
    this.alertService.customDialog("Profession Verification","",'Save','Cancel',false,'',payload).afterClosed().subscribe(
      result=>{
        if(!result||!result.payload){
          return;
        }
        let payload = result.payload;
        let body = profession;
        body['notes'] = payload['note'];
        body['verificationStatus'] = payload['status'];
        body['userProfessionId'] = payload['id'];
        body['userId'] = userId?userId:'';
        this.alertService.showSnackBar('Updating. Please wait.',1)
        const path = environment.celoApiEndpoint + '/api/admin/professionVerifications'
        this.sharedService.postObjectById(path, body).subscribe(
          // callback(data)
          data=>{
            this.alertService.showSnackBar('Verification status updated successfully',4)
            callback(data)
          //   this.getUserProfile()
          //   this.getIdentityVerificationHistory();
          },
          err=>{
            let message = '';
            let error = err['error'];
            for (const key in error) {
              if (error.hasOwnProperty(key)) {
                message = error[key][0]+' ';
              }
            }
            this.alertService.customDialog('Failed',message,'Close','',true);
            // callback(null)
          },
          ()=>{}
        )
      }
      )
  }
}
