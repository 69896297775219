import { DeleteUserComponent } from './delete-user/delete-user.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../../../modules/core/services/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../modules/core/services/alert.service';
import { AuthService } from 'core/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { VerificationService } from 'src/app/services/verification.service';
import { environment } from 'src/environments/environment';
import { PeriodicElement } from '../../workplace-pages/workplace-users/workplace-users.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss', '../../../celo-input.scss', './common-styles.scss']
})
export class UserComponent implements OnInit {
  id: string;
  user: any;
  editModePersonal = false;
  editModeWorkplace = false;

  personal_fields = [
    {
      name: 'Preferred Name',
      key: 'firstName',
      type: 'text',
      editable: true
    },
    {
      name: 'Title',
      key: 'title',
      type: 'select',
      options: [],
      editable: true
    },
    {
      name: 'Legal First Name',
      key: 'legalFirstName',
      type: 'text',
      editable: true
    },
    {
      name: 'Last Name',
      key: 'lastName',
      type: 'text',
      editable: true
    },
    {
      name: 'Email',
      key: 'email',
      type: 'text',
      editable: true
    },
    {
      name: 'Mobile Number',
      key: 'phoneNumber',
      type: 'text',
      editable: true
    },
    {
      name: 'Location',
      key: 'countryCode',
      type: 'country',
      editable: false
    },
    {
      name: 'Registered Date',
      key: 'registeredDate',
      type: 'date',
      editable: false
    }
  ]
  additional_fields = [
    {
      name: 'Last Active',
      key: 'lastActiveTime',
      type: 'date'
    },
    {
      name: 'Total connections',
      key: 'connectionsCount',
      type: 'text'
    },
    {
      name: 'Identity verifications',
      key: 'identityVerificationCount',
      type: 'text'
    },
  ]
  idv_history: any;
  professionVerifcationHistory: any;
  verificationIdentity: any = {};
  verificationProfession: any = {};
  workplaceRegistrationInterests: any = [];
  workplace_id: string;
  data_region: string;
  idvId: string;

  constructor(
    private sharedService: SharedService,
    private authService: AuthService,
    private alertService: AlertService,
    private verificationService: VerificationService,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private matDialog: MatDialog
  ) { }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.id = routeParams['id'];
      this.workplace_id = routeParams['workplace_id'];
      this.getUserProfile();
      // this.getIdentityVerificationHistory();
      this.getIdentityVerificationData();
      this.getProfessionVerificationHistory();
      this.getWorkplaceRegistrationInterests();
    });
    this.setTitleOptions();
    this.data_region = this.authService.getRegion();
    // this.data_region = 'EUR';
  }

  manageRoles() {
    let payload = [
      {
        type: 'manage_roles',
        departmentAdmin: this.user.roles.departmentAdmin,
        broadcaster: this.user.roles.broadcaster,
        // dashboardViewer: this.user.roles.dashboardViewer
      }
    ];

    this.alertService.customDialog("Manage Roles", 'Changing these settings will overwrite the existing roles for all the selected users.', 'Save', 'Cancel', false, '', payload)
      .afterClosed().subscribe(
        result => {
          if (!result)
            return;
          let claims = result.payload ? result.payload : {};
          let new_claims = {
            departmentAdmin: 'false',
            broadcaster: 'false',
            // dashboardViewer: 'false'
          };
          new_claims['departmentAdmin'] = claims['departmentAdmin'] ? 'true' : 'false';
          new_claims['broadcaster'] = claims['broadcaster'] ? 'true' : 'false';
          // new_claims['dashboardViewer'] = claims['dashboardViewer'] ? 'true' : 'false';
          let patch = { claims: new_claims }
          this.alertService.customDialog('', 'Are you sure you want to update roles. This will overwrite all the 3 roles of this user', 'Confirm', 'Cancel')
          .afterClosed().subscribe(
            res => {
              if (!res) {
                return;
              }
              let roles = result.payload ? result.payload : {};
              let new_roles = {
                departmentAdmin: 'false',
                broadcaster: 'false',
              };
              new_roles['departmentAdmin'] = roles['departmentAdmin'] ? 'true' : 'false';
              new_roles['broadcaster'] = roles['broadcaster'] ? 'true' : 'false';
              // new_roles['dashboardViewer'] = roles['dashboardViewer'] ? 'true' : 'false';

              let patch = { claims: new_roles }
              this.roleChange(patch);
            })
        }
      )
  }

  manageFeatures(externalIntegration : boolean, patientLookup : boolean) {
    let payload = [
      {
        type: 'manage_features',
        externalIntegration: externalIntegration,
        patientLookup: patientLookup,
      }
    ];

    this.alertService.customDialog("Manage Features", 'Changing these settings will update the features for this user.', 'Save', 'Cancel', false, '', payload)
      .afterClosed().subscribe(
        result => {
          if (!result)
            return;
          let features = result.payload ? result.payload : {};
          let new_features = {
            is_external_integration_enabled: 'false',
            is_patient_lookup_enabled: 'false',
          };
          new_features['is_external_integration_enabled'] = features['externalIntegration'] ? 'true' : 'false';
          new_features['is_patient_lookup_enabled'] = features['patientLookup'] ? 'true' : 'false';
          let patch = { features: new_features }
          this.alertService.customDialog('', 'Are you sure you want to update features. This will overwrite all the features for this user', 'Confirm', 'Cancel').afterClosed().subscribe(
            res => {
              if (!res) {
                return;
              }
              this.featureChange(patch)
            });
        }
      )
  }

  roleChange(patch) {
    let path = `${environment.celoAdminApiEndpoint}/api/admin/v2/users/${this.id}/roles`;
    this.sharedService.putObjectById(path, patch).subscribe((result) => {

      console.log(patch);
      console.log("User Roles", this.user.roles);

      this.user.roles.broadcaster = patch.claims.broadcaster === "true";
      this.user.roles.departmentAdmin = patch.claims.departmentAdmin === "true";
      this.user.roles.superAdmin = patch.claims.superAdmin === "true";
      // this.user.roles.dashboardViewer = patch.claims.dashboardViewer === "true";
    })
  }

  featureChange(patch) {
    let path = `${environment.celoAdminApiEndpoint}/api/admin/v2/users/${this.id}/features`;
    this.sharedService.putObjectById(path, patch)
      .subscribe((result) => {
        this.user.features.patientLookup = patch.features.is_patient_lookup_enabled === 'true';
        this.user.features.photoExport = patch.features.is_external_integration_enabled === 'true';

        this.alertService.showSnackBar("user's features updated successfully", 4);
      }, err => {
        this.alertService.customDialog('', 'Error updating features', 'Ok', '', true);
      }
      )
  }

  manageUserMfa() {
    let payload = [
      {
        type: 'manage_user_mfa',
        mfaEnabled: this.user.mfaEnabled,
      }
    ];
    this.alertService.customDialog("Manage MFA", 'Changing these settings will update the MFA settings of this user.', 'Save', 'Cancel', false, '', payload)
      .afterClosed().subscribe(
        result => {
          if (!result)
            return;
          let mfa = result.payload ? result.payload : {mfaEnabled: this.user.mfaEnabled};
          this.alertService.customDialog('', 'Are you sure you want to update MFA settings.', 'Confirm', 'Cancel').afterClosed().subscribe(
            res => {
              if (!res) {
                return;
              }
              this.mfaChange({mfaEnabled: mfa.mfaEnabled});
            });
        }
      )
  }

  mfaChange(patch) {
    let path = `${environment.celoAdminApiEndpoint}/api/admin/v2/users/${this.id}/mfa`;
    this.sharedService.putObjectById(path, patch)
      .subscribe((result) => {
        this.user.mfaEnabled = patch.mfaEnabled;
        this.alertService.showSnackBar("user's MFA settings updated successfully", 4);
      }, err => {
        this.alertService.customDialog('', 'Error updating MFA settings', 'Ok', '', true);
      }
      )
  }

  getWorkplaceRegistrationInterests() {
    const path = environment.celoApiEndpoint + '/api/admin/companyRegistrations';
    let params = {
      userId: this.id,
      page: 0,
      pageSize: 50
    }
    this.sharedService.getObjectById(path, params).subscribe(
      data => {
        this.workplaceRegistrationInterests = data.data;
      }
    )
  }
  onWorkplaceInfoChanged() {
    this.getUserProfile();
  }
  getVerificationIdentity() {
    let userId = this.user.userId;
    let pathIdentityVerifications = environment.celoApiEndpoint + '/api/admin/IdentityVerifications/' + userId
    this.sharedService.getObjectById(pathIdentityVerifications).subscribe(
      data => {
        this.verificationIdentity = data;
      }
    );
  }
  markDone(workplaceRegistration) {
    workplaceRegistration.updating = true;
    let patches = [];
    let patch = {
      "value": 'Completed',
      "path": "/status",
      "op": "add"
    }
    patches.push(patch);
    const path = environment.celoApiEndpoint + '/api/admin/companyRegistrations/' + workplaceRegistration.id;
    this.sharedService.patchObjectById(path, patches).subscribe(
      data => {
        workplaceRegistration.updating = false;
        this.getWorkplaceRegistrationInterests()
      },
      err => {
        workplaceRegistration.updating = false;
      }
    )
  }
  setTitleOptions() {
    let instance = this;
    this.sharedService.getTitles(function (titles) {
      if (!titles || !titles.length) {
        return;
      }
      instance.personal_fields.forEach(element => {
        if (element.key == 'title') {
          element.options = [];
          titles.forEach(title => {
            element.options.push({
              id: title,
              name: title
            })
          });
        }
      });
    });
  }
  getUserProfile() {
    const path = environment.celoApiEndpoint + '/api/admin/users/' + this.id;
    this.sharedService.getObjectById(path, {}).subscribe(
      data => {
        this.user = data;
        // this.getVerificationIdentity();
      }
    )
  }
  getIdentityVerificationData() {
    const path = environment.celoApiEndpoint + '/api/admin/identityverifications';
    let params = {
      userId: this.id,
      pageSize: 10
    }
    this.sharedService.getObjectById(path, params).subscribe(
      data => {
        if (data.data && data.data[0]) {
          this.idvId = data.data[0].id;
          this.verificationIdentity = data.data[0];
          this.idv_history = data.data[0].histories;
        }
        return;
      }, err => {
        this.idv_history = [];
      }
    )
  }
  // getIdentityVerificationHistory(idvId){
  //   const path = environment.celoApiEndpoint+'/api/admin/identityverifications/History/'+idvId;
  //   this.sharedService.getObjectById(path,{}).subscribe(
  //     data=>{
  //       this.idv_history = data;
  //     },err=>{
  //       this.idv_history = [];
  //     }
  //   )
  // }
  getProfessionVerificationHistory() {
    const path = environment.celoApiEndpoint + '/api/admin/professionVerifications/history/' + this.id;
    this.sharedService.getObjectById(path, {}).subscribe(
      data => {
        this.professionVerifcationHistory = data;
      }, err => {
        this.professionVerifcationHistory = [];
      }
    )
  }

  editVerificationStatusIdentity() {
    let instance = this;
    this.verificationService.editVerificationStatusIdentity(this.verificationIdentity, this.user.identityVerificationStatus, function (data) {
      instance.getUserProfile();
      instance.getIdentityVerificationData();
      // instance.getIdentityVerificationHistory(this.idvId);
    });
  }
  editVerificationStatusProfession(profession, status?) {
    let instance = this;
    this.verificationService.editVerificationStatusProfession(profession, status, this.id, function (data) {
      instance.getUserProfile();
      instance.getProfessionVerificationHistory();
    })
  }
  removeProfessionClick(profession) {
    let instance = this;
    this.verificationService.removeProfessionClick(profession, function (res) {
      instance.alertService.customDialog("Success", "Profession has been removed successfully", 'DONE', '', true);
      instance.getUserProfile();
      instance.getProfessionVerificationHistory();
    });
  }
  addProfessionClick() {
    if (this.user.identityVerificationStatus == 'Verified') {
      this.addProfession();
      return;
    }
    this.alertService.customDialog('Verify identity ', 'The user needs to have a verified identity to verify their profession.', 'DONE', '', true).afterClosed().subscribe(
      res => {
        if (!res)
          return;
        // this.addProfession();
      }
    )
  }
  addProfession() {
    let instance = this;
    this.verificationService.addProfession(this.id, function (data) {
      instance.getUserProfile();
      instance.getProfessionVerificationHistory();
    })
  }
  cancelPersonal() {
    this.getUserProfile();
    this.editModePersonal = false;
  }
  savePersonal() {
    this.alertService.customDialog('Confirm', "Are you sure you want to update this user's information?", 'Save', 'Cancel', false)
      .afterClosed().subscribe(
        (resp) => {
          if (!resp) {
            this.cancelPersonal();
            return;
          }
          this.updatePersonalInfo();
        }
      )
  }
  updatePersonalInfo() {
    let patches = [];
    for (const field of this.personal_fields) {
      if (!field.editable)
        continue;
      let patch = {
        "value": this.user[field.key],
        "path": "/" + field.key,
        "op": "add"
      }
      patches.push(patch);
    }
    let path = environment.celoApiEndpoint + '/api/Admin/Users/' + this.id;
    this.sharedService.patchObjectById(path, patches).subscribe(
      (resp) => {
        this.cancelPersonal();
        this.alertService.showSnackBar('User information successfully updated', 3)
      }, err => {
        this.alertService.showSnackBar('Error updating user information', 3)
      }
    )
  }


  public freezeAccount() {
    let path = environment.celoApiEndpoint + '/api/Admin/v2/Users/' + this.id + '/freeze';

    this.sharedService.putObjectById(path).subscribe(_ => {
      this.user.isPaused = true;
    });
  }

  public defrostAccount() {
    let path = environment.celoApiEndpoint + '/api/Admin/v2/Users/' + this.id + '/defrost';
    
    this.sharedService.putObjectById(path).subscribe(_ => {
      this.user.isPaused = false;
    });
  }


  deactivateUser() {
    this.alertService.customDialog('Confirm', "Are you sure you want to deactivate this user?", 'Deactivate', 'Cancel', false)
      .afterClosed().subscribe(
        (resp) => {
          if (!resp) {
            this.cancelPersonal();
            return;
          }
          this.changeUserStatus(false);
        }
      )
  }
  activateUser() {
    this.alertService.customDialog('Confirm', "Are you sure you want to activate this user?", 'Activate', 'Cancel', false)
      .afterClosed().subscribe(
        (resp) => {
          if (!resp) {
            this.cancelPersonal();
            return;
          }
          this.changeUserStatus(true);
        }
      )
  }

  public deleteUser() {
    DeleteUserComponent.openDialog(this.matDialog, {
      userId: this.id
    })
        .afterClosed()
        .subscribe((resp) => {
            if (!resp) return;
            // TODO create dialog with input for 'reason'
            this.createDeleteUserRequest();
        });

    // this.alertService
    //     .customDialog('Confirm', 'Are you sure you want to create a request to delete this user?', 'Create', 'Cancel', false)
    //     .afterClosed()
    //     .subscribe((resp) => {
    //         if (!resp) return;
    //         // TODO create dialog with input for 'reason'
    //         this.createDeleteUserRequest();
    //     });
  }


  private createDeleteUserRequest(reason?: string | null) {
    const path = `${environment.celoApiEndpoint}/api/admin/Users/${this.id}`;
    this.apiService.delete({ path, body: { reason } }).subscribe({
        next: () => {
            this.alertService.showSnackBar('Created delete user request', 3);
            this.getUserProfile();
        },
        error: () => this.alertService.showSnackBar('Error while creating delete user request', 3)
    });
  }

  changeUserStatus(status){
    let path = environment.celoApiEndpoint + '/api/admin/users/'+this.id+(status?'/activate':'/deactivate');
    this.sharedService.postObjectById(path).subscribe(
      (resp) => {
        this.alertService.showSnackBar('User ' + (status ? 'activated' : 'deactivated') + ' successfully', 3);
        this.getUserProfile();
      }, err => {
        this.alertService.showSnackBar('Error while updating user status', 3);
      }
    )
  }
  addWorkplace() {
    let payload = [{
      type: 'add_new_workplace',
      userId: this.id,
      workplace: {}
    }]
    let dialog = this.alertService.customDialog('Add workplace', '', '', '', false, '', payload);
    dialog.afterClosed().subscribe(
      res => {
        if (!res) {
          return;
        }
        this.user = res;
      }
    )
  }
  generatePassword() {
    this.alertService.customDialog('Confirm', "Are you sure you want to generate a new password for this user?", 'Generate password', 'Cancel', false)
      .afterClosed().subscribe(
        (resp) => {
          if (!resp) {
            return;
          }
          this.generatePasswordNow();
        }
      )
  }
  generatePasswordNow() {
    let url = environment.celoApiEndpoint + '/api/Admin/Users/' + this.id + '/Password/Generate'
    this.alertService.showSnackBar('Please wait...', 3);
    this.sharedService.postObjectById(url, {}).subscribe(
      data => {
        this.alertService.customDialog('Success', 'Password has been generated successfully', 'Done', '', true)
      },
      err => {
        this.alertService.customDialog('', 'Error generating password. Please try again laer.', 'Ok', '', true)
      }
    )
  }
}
