<div class="parent celo-content">

  <div class="top-buttons">
    <div class="left">
      <input type="search" [(ngModel)]="query" class="celo-input celo-search" placeholder="Search" (keyup.enter)="search(query)">
    </div>
    <div class="right">
      <button matTooltip="Add a new worsplace to Celo" mat-button class="celo-button celo-primary-button" (click)="addWorkplace()">
          <span>Add New Workspace</span>
      </button>
    </div>
  </div>
  <div class="relative-table-container">
    <div *ngIf="isLoadingResults" class="loading-shade">
      <app-empty-state *ngIf="isLoadingResults"></app-empty-state>
    </div>
  
    <div class="table-container">
  
      <table mat-table [dataSource]="data" class="table"
             matSort matSortActive="created" matSortDisableClear matSortDirection="desc">      
  
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Workspace Name</th>
          <td mat-cell *matCellDef="let row" class="name-row">
            <app-avatar 
                [name]="row.name" 
                [hasImage]="row.profilePictureUri" 
                [userId]="userId" 
                [companyId]="row.id" 
                [width]="38" 
                [height]="38"
                [nocache]="true"
            ></app-avatar>
            <span [matTooltip]="row.name" class="name celo-elipsis">{{row.name}}</span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Workspace email</th>
          <td mat-cell *matCellDef="let row">{{row.email?row.email:'-'}}</td>
        </ng-container>
        <ng-container matColumnDef="plan">
          <th mat-header-cell *matHeaderCellDef>Plan</th>
          <td mat-cell *matCellDef="let row">{{row.plan}}</td>
        </ng-container>
        <ng-container matColumnDef="verificationStatus">
          <th mat-header-cell *matHeaderCellDef>Verification</th>
          <td mat-cell *matCellDef="let row">{{row.verificationStatus}}</td>
        </ng-container>
  
        <ng-container matColumnDef="registeredDate">
          <th mat-header-cell *matHeaderCellDef disableClear mat-sort-header>
            Created On
          </th>
          <td mat-cell *matCellDef="let row" [matTooltip]="row.registeredDate | date:'medium'">{{row.registeredDate | date:'shortDate'}}</td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef disableClear mat-sort-header>
            Created By
          </th>
          <td mat-cell *matCellDef="let row">
            <span *ngIf="!row.creator">
              -
            </span>
            <span *ngIf="row.creator">
              <span *ngIf="row.creator.isAdmin">Admin (<span><a [routerLink]="['/user/'+row.creator.userId]">{{row.creator.fullName}}</a></span>)</span>
              <span *ngIf="!row.creator.isAdmin">
                <a [routerLink]="['/user/'+row.creator.userId]">{{row.creator.fullName}}</a>
              </span>
            </span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let row">{{row.isActive==true?'Active':'Inactive'}}</td>
        </ng-container>
  
        <tr mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr class="celo-hoverable celo-cursor-pointer" (click)="companyClick(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  
    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize" (page)="pageSize = $event.pageSize"></mat-paginator>
  </div>
</div>