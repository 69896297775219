import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDeAt from '@angular/common/locales/en-GB';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccessLogsComponent } from './components/access-logs/access-logs.component';
import { CeloCancelSaveButtonsComponent } from './components/common/celo-cancel-save-buttons/celo-cancel-save-buttons.component';
import { CeloDateRangeComponent } from './components/common/celo-date-range/celo-date-range.component';
import { CeloFormComponent } from './components/common/celo-form/celo-form.component';
import { CustomDialogComponent } from './components/common/custom-dialog/custom-dialog.component';
import { EditLocationComponent } from './components/common/edit-location/edit-location.component';
import { SnackbarComponent } from './components/common/snackbar/snackbar.component';
import { TitleBoxComponent } from './components/common/title-box/title-box.component';
import { UnauthorizedComponent } from './components/common/unauthorized/unauthorized.component';
import { WorkplaceInfoFormComponent } from './components/common/workplace-info-form/workplace-info-form.component';
import { LoggedInComponent } from './components/logged-in/logged-in.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { IdentityComponent } from './components/notifications/identity/identity.component';
import { ProfessionComponent } from './components/notifications/profession/profession.component';
import { UserInterestsComponent } from './components/notifications/user-interests/user-interests.component';
import { WorkplaceInterestsComponent } from './components/notifications/workplace-interests/workplace-interests.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AddUserFormComponent } from './components/users/add-user-form/add-user-form.component';
import { AddWorkplaceComponent } from './components/users/user/add-workplace/add-workplace.component';
import { ConnectionsComponent } from './components/users/user/connections/connections.component';
import { DeleteUserComponent } from './components/users/user/delete-user/delete-user.component';
import { UserComponent } from './components/users/user/user.component';
import { WorkplaceEditComponent } from './components/users/user/workplace-edit/workplace-edit.component';
import { UsersComponent } from './components/users/users.component';
import { WorkplaceDepartmentComponent } from './components/workplace-pages/workplace-department/workplace-department.component';
import { WorkplaceDepartmentsComponent } from './components/workplace-pages/workplace-departments/workplace-departments.component';
import { WorkplaceDocumentsComponent } from './components/workplace-pages/workplace-documents/workplace-documents.component';
import { WorkplaceInfoComponent } from './components/workplace-pages/workplace-info/workplace-info.component';
import { WorkplacePartnersComponent } from './components/workplace-pages/workplace-partners/workplace-partners.component';
import { WorkplaceSettingsComponent } from './components/workplace-pages/workplace-settings/workplace-settings.component';
import { WorkplaceUsersComponent } from './components/workplace-pages/workplace-users/workplace-users.component';
import { WorkplaceComponent } from './components/workplace/workplace.component';
import { WorkplacesComponent } from './components/workplaces/workplaces.component';
import { IdentityVerificationStatusModel } from './models/IdentityVerificationStatus';
import { ProfessionVerificationStatusModel } from './models/ProfessionVerificationStatus';
import { CoreModule } from './modules/core/core.module';
import { DeleteRequestsModule } from './modules/delete-requests/delete-requests.module';
import { MaterialModule } from './modules/material/material.module';
import { SharedModule } from './modules/shared/shared.module';
import { ClaimsPipe } from './pipes/claims.pipe';
import { CountryPipe } from './pipes/country.pipe';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { CeloHttpInterceptorService } from './services/celo-http-interceptor.service';
import { SettingsService } from './services/settings.service';
import { UserAccountService } from './services/user-account.service';
import { EditLicensesComponent } from './components/common/edit-licenses/edit-licenses.component';
import { RenewLicensesComponent } from './components/common/renew-licenses/renew-licenses.component';

export function storageFactory(): OAuthStorage {
    return localStorage;
}

registerLocaleData(localeDeAt);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        WorkplacesComponent,
        UsersComponent,
        NavbarComponent,
        WorkplaceComponent,
        WorkplaceInfoComponent,
        WorkplaceUsersComponent,
        WorkplaceDepartmentsComponent,
        WorkplaceDocumentsComponent,
        WorkplacePartnersComponent,
        WorkplaceSettingsComponent,
        CustomDialogComponent,
        SnackbarComponent,
        WorkplaceInfoFormComponent,
        TitleBoxComponent,
        WorkplaceDepartmentComponent,
        IdentityComponent,
        SettingsComponent,
        CountryPipe,
        AddUserFormComponent,
        AccessLogsComponent,
        UnauthorizedComponent,
        UserComponent,
        CeloFormComponent,
        CeloCancelSaveButtonsComponent,
        ProfessionComponent,
        WorkplaceEditComponent,
        LinkifyPipe,
        AddWorkplaceComponent,
        CeloDateRangeComponent,
        WorkplaceInterestsComponent,
        UserInterestsComponent,
        ClaimsPipe,
        SettingsComponent,
        LoggedInComponent,
        ConnectionsComponent,
        EditLocationComponent,
        DeleteUserComponent,
        EditLicensesComponent,
        RenewLicensesComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        OAuthModule.forRoot(),
        HttpClientModule,
        BrowserAnimationsModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        DeleteRequestsModule,
        CoreModule
    ],
    providers: [
        SettingsService,
        { provide: LOCALE_ID, useValue: 'en-GB' }, //replace "en-US" with your locale
        IdentityVerificationStatusModel,
        ProfessionVerificationStatusModel,
        UserAccountService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CeloHttpInterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {}
}
