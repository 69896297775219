import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { RolesGuard } from 'src/app/guards/roles.guard';
import { DeleteRequestsComponent } from './delete-requests-home/delete-requests.component';

const routes: Routes = [
    {
        path: '',
        component: DeleteRequestsComponent,
        canActivate: [AuthGuard, RolesGuard],
        data: { roles: ['SuperAdmin'] }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DeleteRequestsRoutingModule {}
