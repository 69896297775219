import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

const scope = 'celo_api openid offline_access celo_admin_api'
 
  export const authCodeFlowConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: environment.celoAuthApiEndpoint+'/',
    // issuer: 'https://auth.celohealth.com/',
 
    // URL of the SPA to redirect the user to after login
    redirectUri: environment.origin + '/signin-oidc',
    postLogoutRedirectUri:environment.origin + '/signout-callback-oidc',
 
    clientId: environment.clientId,
    responseType: 'code',
    scope: scope,
    silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
    // useSilentRefresh: true,
    showDebugInformation: true,
    strictDiscoveryDocumentValidation:false,
    requireHttps:false
    // ,timeoutFactor:0.05
    // ,sessionChecksEnabled:true
  };