<div class="parent">
    <div *ngIf="hasImage && !isLoading" class="avatar" [style.height.px]="height" [style.width.px]="width">
        <img [src]="imageUrl" [style.height.px]="height" [style.width.px]="width" src="" alt="" />
    </div>
    <div *ngIf="!hasImage || isLoading">
        <ngx-avatars
            [name]="name"
            [initialsSize]="3"
            [size]="width"
            [bgColor]="'#bebebe'"
            [fgColor]="'#FFFFFF'"
        ></ngx-avatars>
    </div>
    <div
        *ngIf="isOnDND"
        [style.bottom.px]="badgeMargin"
        [style.left.px]="badgeMargin"
        class="badge_container appear_1"
        [matTooltip]="dndMessage ? dndMessage : ''"
    >
        <div
            class="red_badge"
            [style.border-width.px]="badgeBorder"
            [style.height.px]="badgeSize"
            [style.width.px]="badgeSize"
        ></div>
    </div>
</div>
