import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'app-celo-cancel-save-buttons',
  templateUrl: './celo-cancel-save-buttons.component.html',
  styleUrls: ['./celo-cancel-save-buttons.component.scss']
})
export class CeloCancelSaveButtonsComponent implements OnInit {
  @Input() showNow:boolean;
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();
  
  @Input() showCancel = true;
  @Input() saveButtonDisabled = false;
  @Input() showSave = true;
  @Input() alignment = 'left';
  constructor() { }

  ngOnInit() {
  }

}
