import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertService } from '../modules/core/services/alert.service';
import { AuthService } from '../modules/core/services/auth.service';
import { SharedService } from './shared.service';

@Injectable()
export class UserAccountService {
    public accountSubject = new ReplaySubject<any>(1);
    public profilePicChangeSubject = new ReplaySubject<boolean>(1);
    // public accountSubject = new Subject<any>();
    private updateAccount = new Subject<boolean>();
    public userAccount: any;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private sharedService: SharedService,
        private alertService: AlertService
    ) {
        // let firstPulse = this.authService.pulse.pipe(first())
        // firstPulse.pipe(concat(this.updateAccount.asObservable()), debounceTime(1000),).subscribe(() => {
        //   let path = `${environment.celoApiEndpoint}/api/Account`
        //   this.http.get<any>(path).pipe(retry(3)).subscribe(
        //     (profile) => {
        //       this.userAccount = profile;
        //       this.accountSubject.next(profile);
        //     },
        //     (err) => {
        //       this.alertService.alert('Error', 'Error loading user profile', true)
        //     }
        //   )
        // })
    }
    getUserAccount(callback?) {
        if (this.userAccount && this.userAccount.userId) {
            return callback(this.userAccount);
        }
        const path = `${environment.celoApiEndpoint}/api/Account`;
        this.sharedService.getObjectById(path).subscribe(
            (user) => {
                this.updateAccount = user;
                if (callback) return callback(user);
            },
            (err) => {
                if (callback) return callback(null);
            }
        );
    }
    getValidLoginEmails(profile: any) {
        let validEmails = [];
        if (profile) {
            if (profile.email) {
                validEmails.push(profile.email);
            }
            if (profile['companies'])
                for (const company of profile['companies']) {
                    if (company.email) {
                        validEmails.push(company.email);
                    }
                }
        }
        return validEmails;
    }
}
