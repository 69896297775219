import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarModule } from 'ngx-avatars';
import { MaterialModule } from './../material/material.module';
import { AvatarComponent } from './avatar/avatar.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';

@NgModule({
    declarations: [EmptyStateComponent, AvatarComponent],
    exports: [EmptyStateComponent, AvatarComponent],
    imports: [CommonModule, MaterialModule, AvatarModule]
})
export class SharedModule {}
