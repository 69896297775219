import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '../../../../modules/core/services/alert.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-workplace-edit',
  templateUrl: './workplace-edit.component.html',
  styleUrls: ['./workplace-edit.component.scss','../../../../celo-input.scss','../common-styles.scss']
})
export class WorkplaceEditComponent implements OnInit {

  workplace_fields = [
    {
      name:'Name',
      key:'companyName',
      type:'text',
      editable:false
    },
    {
      name:'Work Email',
      key:'email',
      type:'text',
      editable:true
    },
    {
      name:'Department',
      key:'departmentId',
      type:'select',
      editable:true
    },
    {
      name:'Department Name',
      key:'departmentName',
      type:'text',
      editable:false,
      hidden:true
    },
    {
      name:'Position',
      key:'position',
      type:'text',
      editable:true
    }
    ,{
      name:'Claims',
      key:'claims',
      type:'claims',
      editable:false,
      hidden:true
    }
  ]

  @Input() userId ;
  @Input() workplace ;
  @Input() editMode = false;
  @Output() infoChanged = new EventEmitter<any>();

  departments = [];

  constructor(
    private sharedService:SharedService,
    private alertService:AlertService
  ) { }

  ngOnInit() {
  }
  ngOnChanges(){
    if(this.editMode){
      this.getDepartments();
    }
  }
  getDepartments(){
    this.departments = [];
    const path = environment.celoApiEndpoint + '/api/Companies/'+this.workplace.companyId+'/Departments';
    let params = {
      Page:0,
      PageSize:10000
    }
    this.sharedService.getObjectById(path,params).subscribe(
      res=>{
        this.departments = res.data;
      }
    )
  }

  activateWorkplace(workplace){
    this.alertService.customDialog('Confirm',"Are you sure you want to activate this workplace for this user?",'Activate','Cancel',false)
    .afterClosed().subscribe(
      (resp)=>{
        if(!resp){
          return;
        }
        this.changeWorkplaceStatus(workplace, true);
      }
    )
  }
  deactivateWorkplace(workplace){
    this.alertService.customDialog('Confirm',"Are you sure you want to deactivate this workplace for this user?",'Deactivate','Cancel',false)
    .afterClosed().subscribe(
      (resp)=>{
        if(!resp){
          return;
        }
        this.changeWorkplaceStatus(workplace, false);
      }
    )
  }
  changeWorkplaceStatus(workplace, status){
    let path = environment.celoApiEndpoint + '/api/Admin/Users/'+this.userId+'/Workplace/'+workplace.companyId+'/'+(status?'active':'deactive');
    this.sharedService.postObjectById(path).subscribe(
      (resp)=>{
        this.alertService.showSnackBar('User updated successfully',3);
        this.infoChanged.emit();
      },err=>{
        this.alertService.showSnackBar('Error while updating workplace status',3);
      }
    )
  }


  cancelWorkplaceEditMode(workplace){
    this.infoChanged.emit();
    workplace.editMode = false;
  }
  saveWorkplace(workplace){
    this.alertService.customDialog('Confirm',"Are you sure you want to update this user's workspace information?",'Save','Cancel',false)
    .afterClosed().subscribe(
      (resp)=>{
        if(!resp){
          this.cancelWorkplaceEditMode(workplace);
          return;
        }
        this.updateWorkplaceInfo(workplace);
      }
    )
  }
  updateWorkplaceInfo(workplace){
    let patches = [];
    for (const field of this.workplace_fields) {
      if(!field.editable)
        continue;
      let patch = {
        "value": workplace[field.key],
        "path": "/"+field.key,
        "op": "add"
      }
      patches.push(patch);
    }
    let path = environment.celoApiEndpoint + '/api/Admin/Users/'+this.userId+'/Workplace/'+workplace.companyId;
    this.sharedService.patchObjectById(path,patches).subscribe(
      (resp)=>{
        this.cancelWorkplaceEditMode(workplace);
        this.alertService.showSnackBar('User information successfully updated',3)
      },err=>{
        let errorMessage = this.sharedService.readErrorMessage(err,'Error updating workspace information');
        this.alertService.customDialog('',errorMessage,'OK','');
      }
    )
  }

}
