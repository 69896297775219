import { Component, OnInit, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

import {HttpClient} from '@angular/common/http';
import {ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { AlertService } from '../../../modules/core/services/alert.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { PeriodicElement } from '../../workplace-pages/workplace-users/workplace-users.component';
import { IdentityVerificationStatusModel } from 'src/app/models/IdentityVerificationStatus';
import { ActivatedRoute } from '@angular/router';
import { VerificationService } from 'src/app/services/verification.service';

@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss','../notification-styles.scss']
})
export class IdentityComponent implements AfterViewInit {
  columns: string[] = ['name', 'status','created'];
  pendingPageColumns: string[] = ['select','name', 'result', 'status','created'];
  wfiPageColumns: string[] = ['name', 'result', 'status','created'];
  displayedColumns: string[] = ['name', 'status','created'];
  formObject:any = {};

  // data: Company[] = [];
  // data = new MatTableDataSource();
  data = new MatTableDataSource<PeriodicElement>([]);

  resultsLength = 0;
  isLoadingResults = true;
  query:string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  pageSize: number = 25;
  searchEvent = new EventEmitter();

  status:string = 'Pending' ;

  selection = new SelectionModel<PeriodicElement>(true, []);
  token: any;
  list: any;


  constructor(
    private sharedService:SharedService
    ,private alertService:AlertService
    ,private verificationService:VerificationService
    ,private _httpClient: HttpClient
    ,public identityVerificationStatusModel: IdentityVerificationStatusModel
    ,private route:ActivatedRoute
  ) { }


  ngOnInit() {
    // this.database.sort = this.sort;
    this.list = [];
    this.data.sort = this.sort;
    this.status = this.identityVerificationStatusModel.Pending;
    this.route.params.subscribe(routeParams => {
      this.status = routeParams['status'];
      if(!this.status){
        this.status = 'Pending';
        return;
      }
      this.pageFilterChange();
      this.search();
    });
    // this.pageFilterChange();
    // this.search();
  }
  search(){
    this.searchEvent.emit(this.query);
    this.data = new MatTableDataSource<PeriodicElement>([]);
    this.clearTable();
    this.clearToken();
    this.executeSearch();
  }
  loadMore(){
    this.executeSearch();
  }
  clearToken(){
    this.token = ''
  }
  clearTable(){
    this.list = [];
  }
  executeSearch(){
    this.resetSelection();
    const path = environment.celoApiEndpoint + '/api/admin/IdentityVerifications';
    this.isLoadingResults = true;
    let params = {
      pageSize:this.pageSize,
      status:this.status
    }
    if(this.query){
      params['search'] = this.query;
    }
    if(this.token){
      params['token'] = this.token;
    }
    this.token = null;
    this.sharedService.getObjectById(path,params).subscribe(
      data=>{
        this.isLoadingResults = false;
        this.resultsLength = data.total;
        var url_string = data.nextPageUri; //window.location.href
        if(url_string){
          var url = new URL(`${environment.celoApiEndpoint}${url_string}`);
          var token = url.searchParams.get("token") ?? url.searchParams.get("Token");
          this.token = token;
        }
        this.list = this.list.concat(data.data);
        this.data = new MatTableDataSource<PeriodicElement>(this.list);
      },err=>{
        this.isLoadingResults = false;
          this.resultsLength = 0;
      }
    )
  }
  ngAfterViewInit() {
    
  }
  resetSelection(){
    this.selection.clear();
  }
  
  verificationClick(verification){
    this.sharedService.navigate(['/user/'+verification['userId']])
  }
  moreInfo(identityVerifications){
    // this.alertService.showSnackBar('Coming soon',4)
    this.bulkUpdate(identityVerifications,this.identityVerificationStatusModel.MoreInfoRequired);
  }
  redoVerification(identityVerifications){
    // this.alertService.showSnackBar('Coming soon',4)
    this.bulkUpdate(identityVerifications,this.identityVerificationStatusModel.Unverified);
  }
  update(identityVerifications,index,status){
    let instance = this;
    this.verificationService.editStatusIdentityVerification( '',status, identityVerifications[index], function(data){
      index++;
      if(!identityVerifications[index]){
        instance.alertService.showSnackBar('Successfully updated '+identityVerifications.length+ ' verification(s)',4)
        instance.search();
        return;
      }
      return instance.update(identityVerifications,index,status);
    });
  }
  bulkUpdate(identityVerifications, status){
    this.alertService.showSnackBar('Updating verification(s). Please wait.',4);
    this.update(identityVerifications,0,status);
    // for (const identityVerification of identityVerifications) {
    //   identityVerification['status']=status;
      // let instance = this;
      // this.verificationService.editStatusIdentityVerification( '',status, identityVerification, function(data){
      //   instance.search();
      // });
    // }


    // let path = environment.celoApiEndpoint + '/api/admin/identityverifications/UpdateStatus';
    // this.sharedService.postObjectById(path,identityVerifications).subscribe(
    //   resp=>{
    //     this.search();
    //     this.alertService.showSnackBar('Successfully updated '+identityVerifications.length+ ' verification(s)',4)
    //   },
    //   err=>{
    //     this.alertService.showSnackBar('Failed to update verifications',4)
    //   }
    // );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numRows;
    if(this.data&&this.data.data)
      numRows = this.data.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.data.data.forEach(row => this.selection.select(row));
  }
  
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  pageFilterChange(){
    this.displayedColumns = this.columns;
    if(this.status == 'Pending'){
      this.displayedColumns = this.pendingPageColumns;
    }
    else if(this.status == 'MoreInfoRequired'){
      this.displayedColumns = this.wfiPageColumns;
    }
  }
}