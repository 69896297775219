import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '../services/settings.service';

@Pipe({
  name: 'country'
})
export class CountryPipe implements PipeTransform {
  constructor(
    private settingsService:SettingsService
  ){
    
  }

  transform(value: any, args?: any): any {
    return this.settingsService.getCountryNameFromCode(value);
  }

}
