import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'core/services/alert.service';

export interface EditLicensesComponentPayload {
  newCount: number;
  expiryDate: Date;
}

@Component({
  selector: 'app-edit-licenses',
  templateUrl: './edit-licenses.component.html',
  styleUrls: ['./edit-licenses.component.scss'],
})
export class EditLicensesComponent {
  max = 100;
  min = 0;
  step = 1;
  thumbLabel = false;
  value = 0;
  dateVal : Date;

  constructor(
    private dialogRef: MatDialogRef<
    EditLicensesComponent,
    EditLicensesComponentPayload
    >,
    private alertService: AlertService
  ) 
  {

  }

  ngOnInit() {
    this.value = 1;
  }

  saveClicked() {
    this.alertService.showSnackBar("Editing licenses for this workspace...", 10);
    const payload: EditLicensesComponentPayload = {
      newCount: this.value,
      expiryDate: this.dateVal
    };
    this.dialogRef.close(payload);
  }

  handleOnChange(event: InputEvent) {
    this.value = (event.target as HTMLInputElement).valueAsNumber;
  }

  handleDateChange(event: InputEvent) {
    this.dateVal = new Date((event.target as HTMLInputElement).value);
  }
}
