<div class="info-form">
  <div *ngFor="let item of displayItems" class="item">
    <div *ngIf="!editmode && (!newmode || !item.editable)" class="value">
      <div class="title">{{ item.displayName }}</div>
      <div *ngIf="formObject[item.key] || formObject[item.key] == false">
        <div *ngIf="item.type == 'text'">{{ formObject[item.key] }}</div>
        <div *ngIf="item.type == 'toggle' || item.type == 'checkbox'">{{ formObject[item.key] }}</div>
        <div *ngIf="item.type == 'country'">
          {{ formObject[item.key] | country }}
        </div>
        <div *ngIf="item.type == 'date'">
          {{ formObject[item.key] | date: "medium" }}
        </div>
        <div *ngIf="item.type == 'status'">
          {{ formObject.isActive == true ? "Active" : "Inactive" }}
        </div>
      </div>
      <div *ngIf="!formObject[item.key] && formObject[item.key] != false">
        -
      </div>
    </div>
    <div *ngIf="(editmode || newmode) && item.editable" class="">
      <div *ngIf="item.type == 'text'">
        <mat-form-field appearance="outline">
          <input
            matInput
            type="text"
            (change)="valueChange(item.key, $event.target.value)"
            [value]="formObject[item.key] ? formObject[item.key] : ''"
            [placeholder]="item.displayName"
            autocomplete="off"
            [maxLength]="item.maxlength ? item.maxlength : 1000"
          />
          <mat-label>{{ item.displayName }}</mat-label>
        </mat-form-field>
      </div>
      <div *ngIf="item.type == 'toggle'">
        <div class="title">
          <mat-label>{{ item.displayName }}</mat-label>
        </div>
        <mat-radio-group
          aria-label="Select an option"
          (change)="valueChange(item.key, $event.value)"
          [ngModel]="formObject[item.key]"
        >
          <mat-radio-button
            *ngFor="let option of item.options"
            [value]="option.value"
            [disabled]="option.isDisabled"
            >{{ option.display }}</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div *ngIf="item.type == 'checkbox'">
        <mat-checkbox [(ngModel)]="formObject[item.key]" (change)="valueChange(item.key, $event.checked)">
          {{ item.displayName }}
        </mat-checkbox>
      </div>
      <div *ngIf="item.type == 'select'">
        <mat-form-field appearance="outline">
          <mat-label>{{ item.displayName }}</mat-label>
          <mat-select
            (selectionChange)="valueChange(item.key, $event.value)"
            [value]="formObject[item.key]"
            [required]="item.required"
          >
            <mat-option
              *ngFor="let option of item.options"
              [value]="option.shortName"
            >
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
