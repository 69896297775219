import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-title-box',
  templateUrl: './title-box.component.html',
  styleUrls: ['./title-box.component.scss']
})
export class TitleBoxComponent implements OnInit {

  @Input() title;
  @Input() subtitle;
  @Input() flag;
  @Input() showAvatar;
  @Input() hasImage;
  @Input() userId;
  @Input() companyId;
  @Input() firstName;
  @Input() lastName;
  
  constructor() { }

  ngOnInit() {
  }

}
