<div class="relative">
    <div class="page-title">Domain Settings</div>
    <div class="top-buttons">
        <button matTooltip="Add new department" mat-button class="celo-button celo-primary-button" (click)="addDepartment()">
            <i class="material-icons">add</i>
            <span>Add New</span>
        </button>
    </div>
    <div class="relative-table-container">
        <div *ngIf="isLoadingResults ||isEmpty" class="loading-shade">
            <app-empty-state *ngIf="isLoadingResults" [strokeWidth]="2" [diameter]="30"></app-empty-state>
            <div *ngIf="isEmpty">
            - Empty -
          </div>
        </div>
    
        <div class="table-container">
        <table mat-table [dataSource]="departments" class="table"
                matSort matSortActive="created" matSortDisableClear matSortDirection="desc">      
            <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Domain</th>
            <td mat-cell *matCellDef="let row">
                <div class="celo-primary-link celo-link">{{row.name}}</div>
            </td>
            </ng-container>
            <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="t_right">Actions</th>
            <td mat-cell *matCellDef="let row" class="">
                <div class="action-buttons">
                <button mat-button class="celo-action-button" (click)="deleteEntry(row)">
                    <i class="material-icons">delete</i>
                </button>
                </div>
            </td>
            </ng-container>
            <tr mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr class="celo-hoverable celo-cursor-pointer" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        </div>
    
        <mat-paginator [length]="resultsLength" [pageSize]="20"></mat-paginator>
    </div>
</div>
