import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-celo-form',
  templateUrl: './celo-form.component.html',
  styleUrls: ['./celo-form.component.scss','../../../celo-input.scss']
})
export class CeloFormComponent implements OnInit {

  displayItems = [];

  @Input() formObject: any =[];
  @Output() formchange = new EventEmitter();

  result = {};

  constructor(private sharedService:SharedService) { }

  ngOnInit() {
    for (const iterator of this.formObject) {
      if(iterator['type']=='select'&&iterator['optionsLink']){
        this.loadOptions(iterator)
      }
    }
    this.valueChange()
  }
  ngOnChages(){
  }

  loadOptions(field){
    this.sharedService.getObjectById(field['optionsLink'],field['params']).subscribe(
      data=>{
        field['options'] = data;
      }
    )
  }
  sanitizeForm(){
    for (const formField of this.formObject) {
      if(formField.parentKey){
        formField.disabled = false;
        let parentObject = this.getFormObjectByKey(formField.parentKey);
        let selectedParentOption = this.getOption(parentObject.options,this.result[formField.parentKey]);
        if(selectedParentOption&&selectedParentOption[formField.enabledOnParentProperty]==false)
        {
          this.result[formField.key] = '';
          formField.value = ''
          formField.disabled = true;
        }
      }
    }  
  }
  getFormObjectByKey(key){
    for (const iterator of this.formObject) {
      if(iterator.key==key)
        return iterator;
    }
  }
  getOption(options,value){
    for (const iterator of options) {
      if(iterator.name==value)
        return iterator;
    }
  }

  valueChange(){
    for (const object of this.formObject) {
      this.result[object['key']] = object.value?object.value:'';
    }
    this.sanitizeForm();
    this.formchange.emit(this.result);
  }
}
