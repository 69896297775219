import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AdminUsersService } from './../../../../modules/core/services/admin-users.service';
import { AlertService } from './../../../../modules/core/services/alert.service';

export interface DeleteUserData {
    userId: string;
}

export interface DeleteUserResult {}

@Component({
    selector: 'app-delete-user',
    templateUrl: './delete-user.component.html',
    styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent implements OnDestroy {
    public deleteUserForm = this.formBuilder.group({
        reason: ''
    });

    public isSubmitting = false;

    private createDeleteUserRequestSubscription: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) private dialogData: DeleteUserData,
        private matDialogRef: MatDialogRef<DeleteUserComponent, DeleteUserResult>,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private adminUsersService: AdminUsersService
    ) {}

    public ngOnDestroy(): void {
        this.createDeleteUserRequestSubscription?.unsubscribe();
    }

    public submit() {
        this.isSubmitting = true;
        this.createDeleteUserRequestSubscription = this.adminUsersService
            .createDeleteUserRequest(this.dialogData.userId, this.deleteUserForm.value.reason)
            .subscribe({
                next: () => {
                    this.alertService.showSnackBar('Created delete user request', 3);
                    this.matDialogRef.close();
                },
                error: () => {
                    this.alertService.showSnackBar('Error while creating delete user request', 3);
                    this.isSubmitting = false;
                }
            });
    }

    public static openDialog(matDialog: MatDialog, data: DeleteUserData) {
        return matDialog.open<DeleteUserComponent, DeleteUserData, DeleteUserResult>(DeleteUserComponent, {
            role: 'dialog',
            restoreFocus: true,
            data
        });
    }
}
