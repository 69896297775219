<div class="parent celo-content mih_100vh">
    <div class="title">Supported Countries</div>
    <div *ngIf="settingsService.settings&&settingsService.settings.supportedCountries">
        <ul *ngFor="let country of settingsService.settings.supportedCountries">
            <li>
                <div>{{country.name}}</div>
                <div>Data region : {{country.dataRegionId}}</div>
            </li>
        </ul>
    </div>
    <div>Consent Form Valid For(Days) : {{settingsService.settings.consentFormValidDays}}</div>
    <div>Inactivity Period : {{settingsService.settings.inactivityPeriod}}</div>
    <div>Lockout Period : {{settingsService.settings.lockoutPeriod}}</div>
</div>