import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminUsersService } from 'core/services/admin-users.service';
import { AlertService } from 'core/services/alert.service';
import { Subscription } from 'rxjs';
import { DeleteUserRequest, DeleteUserRequestStatus } from 'types';

export interface EditDeleteRequestData {
    request: DeleteUserRequest;
}

export interface EditDeleteRequestResult {
    updatedRequest: DeleteUserRequest;
}

@Component({
    selector: 'app-edit-delete-request',
    templateUrl: './edit-delete-request.component.html',
    styleUrls: ['./edit-delete-request.component.scss']
})
export class EditDeleteRequestComponent implements OnInit, OnDestroy {
    public statuses: DeleteUserRequestStatus[] = ['Received', 'Queued', 'Completed', 'Cancelled'];
    public deleteRequestForm = this.formBuilder.group({
        status: this.statuses[0]
    });

    public isSubmitting = false;
    public request: DeleteUserRequest | null = null;

    private updateDeleteUserRequestSubscription: Subscription;

    public constructor(
        @Inject(MAT_DIALOG_DATA) private dialogData: EditDeleteRequestData,
        private matDialogRef: MatDialogRef<EditDeleteRequestComponent, EditDeleteRequestResult>,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private adminUsersService: AdminUsersService
    ) {}

    public ngOnInit(): void {
        this.request = this.dialogData.request;
        this.deleteRequestForm.get('status').setValue(this.request.status);
    }

    public ngOnDestroy(): void {
        this.updateDeleteUserRequestSubscription?.unsubscribe();
    }

    public static openDialog(matDialog: MatDialog, data: EditDeleteRequestData) {
        return matDialog.open<EditDeleteRequestComponent, EditDeleteRequestData, EditDeleteRequestResult>(
            EditDeleteRequestComponent,
            {
                role: 'dialog',
                restoreFocus: true,
                data
            }
        );
    }

    public submit() {
        this.isSubmitting = true;
        this.updateDeleteUserRequestSubscription = this.adminUsersService
            .updateDeleteUserRequest(this.request.id, this.deleteRequestForm.value.status)
            .subscribe({
                next: (updatedRequest) => {
                    this.alertService.showSnackBar('Updated delete user request', 3);
                    this.matDialogRef.close({ updatedRequest });
                },
                error: () => {
                    this.alertService.showSnackBar('Error while updating delete user request', 3);
                    this.isSubmitting = false;
                }
            });
    }
}
