<div class="title-box">
    <img *ngIf="!showAvatar" class="picture" src="" alt="">
    <app-avatar 
      *ngIf="showAvatar" 
      [name]="[firstName, lastName].join(' ')" 
      [hasImage]="hasImage" 
      [userId]="userId" 
      [companyId]="companyId" 
      [width]="60" 
      [height]="60"
      [nocache]="companyId"
    ></app-avatar>
    <div class="texts">
      <span class="title">{{title}} </span><span class="flag">{{flag}}</span>
      <div class="subtitle">{{subtitle}}</div>
    </div>
</div>