import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, of, scheduled } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { ImageSize } from 'src/types';

@Injectable({
  providedIn: 'root'
})
export class AvatarService implements OnDestroy {
  
  cache: Map<string, SafeUrl>;
  public httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
    'Content-Type': 'application/json',
    'CeloAuthorization': environment.celoAuth
    })
  };

  ngOnDestroy(): void {
    this.cache = new Map<string, SafeUrl>() 
  }

  constructor(private http: HttpClient,
    private authService:AuthService,
    private sanitizer: DomSanitizer) {
    this.cache = new Map<string, SafeUrl>()
  }

  loadPicture(userId:string, dimension, important?:boolean): Observable<SafeUrl> {
    dimension = dimension?dimension:150;
    
    let cacheItem = important?null:this.cache.get(userId);
    if (!cacheItem) {
      return this.loadPictureFromApi(userId, dimension, dimension).pipe(tap(url => {
        this.cache.set(userId, url)
      }))
    }
    return of(cacheItem)
  }

  loadPictureFromApi(userId, width, height): Observable<SafeUrl> {
    let url = `${environment.celoApiEndpoint}/api/User/${userId}/Picture?width=${width}&height=${height}`;

    let httpOptions = {headers: new HttpHeaders({})};
    httpOptions.headers = this.httpOptions.headers.set('Authorization', this.authService.getAuthorization());
    httpOptions['responseType'] = 'arraybuffer' as 'json'; 
    // httpOptions['params']=params;

    return this.http.get<ArrayBuffer>(url, httpOptions ).pipe(
      map((ab) => {
        var arrayBufferView = new Uint8Array(ab)
        var blob = new Blob([arrayBufferView], { type: "image/jpeg" })
        var urlCreator = <any>window.URL
        let url = urlCreator.createObjectURL(blob);
        return this.sanitizer.bypassSecurityTrustUrl(url)
      })
    )
    // return this.http.get<ArrayBuffer>(url, { responseType: 'arraybuffer' as 'json' }).pipe(
    //   map((ab) => {
    //     var arrayBufferView = new Uint8Array(ab)
    //     var blob = new Blob([arrayBufferView], { type: "image/jpeg" })
    //     var urlCreator = <any>window.URL
    //     let url = urlCreator.createObjectURL(blob);
    //     return this.sanitizer.bypassSecurityTrustUrl(url)
    //   })
    // )
  }

  /**
   * Load a workspace's profile picture. 
   * 
   * Note: Requesting a size other than 'Full' after uploading an image may return an empty image as the thumbnails 
   * are generated in the background.
   */
  loadWorkspacePicture(companyId: string, size: ImageSize = 'Small', ignoreCache: boolean = false): Observable<SafeUrl> {
    if (!ignoreCache && this.cache.has(companyId)) {
        return of(this.cache.get(companyId));
    }

    return this.loadWorkspacePictureFromApi(companyId, size).pipe(
        tap((url) => {
            this.cache.set(companyId, url);
        })
    );
  }

  private loadWorkspacePictureFromApi(companyId: string, size: ImageSize = 'Small'): Observable<SafeUrl> {
    const path = `${environment.celoApiEndpoint}/api/v2/workspaces/${companyId}/picture/timestamp/${new Date().toISOString()}?size=${size}`;
    return this.http
        .get(path, {
            headers: new HttpHeaders({
                Authorization: this.authService.getAuthorization()
            }),
            responseType: 'blob'
        })
        .pipe(
            map((blob) => {
                let url = URL.createObjectURL(blob);
                return this.sanitizer.bypassSecurityTrustUrl(url);
            })
        );
  }
}
