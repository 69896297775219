<!-- <div class="info-box celo-content">
  <div class="breadcrums">
    <a [routerLink]="['/workplaces']">Workspaces ></a>&nbsp;&nbsp;<span>{{company.name}}</span>
  </div>
    <app-title-box [title]="company.name" [subtitle]="company.countryCode" [flag]="company.isActive==true?'Active':'Inactive'"></app-title-box>
</div> -->
<div class="relative">
    <div class="page-title">Departments</div>
    <div class="top-buttons">
        <button matTooltip="Add new department" mat-button class="celo-button celo-primary-button" (click)="addDepartment()">
            <i class="material-icons">add</i>
            <span>Add New</span>
        </button>
    </div>
    <div class="relative-table-container">
        <div *ngIf="isLoadingResults" class="loading-shade">
          <app-empty-state *ngIf="isLoadingResults" [strokeWidth]="2" [diameter]="30"></app-empty-state>

        </div>
      
        <div class="table-container">
          <table mat-table [dataSource]="departments" class="table"
                 matSort matSortActive="created" matSortDisableClear matSortDirection="desc">      
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Department Name</th>
              <td mat-cell *matCellDef="let row">
                <div *ngIf="!row.editmode" (click)="clicked(row)" class="celo-primary-link celo-link">{{row.name}}</div>
                <div *ngIf="row.editmode" class="name">
                    <input matInput type="text" class="celo-input" [(ngModel)]="row.name_field">
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="t_right action_title">Actions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <td mat-cell *matCellDef="let row" class="">
                <div class="action-buttons">
                  <button mat-button *ngIf="!row.editmode" class="celo-action-button" (click)="row.name_field = row.name; row.editmode = true">
                      <i class="material-icons">edit</i>
                  </button>
                  <button *ngIf="!row.editmode" mat-button class="celo-action-button" (click)="deleteDepartment(row)">
                      <i class="material-icons">delete</i>
                  </button>
                  <button *ngIf="row.editmode" mat-button class="celo-secondary-button" (click)="row.editmode = false">
                      <span>Cancel</span>
                  </button>
                  <button *ngIf="row.editmode" mat-button class="celo-primary-button" (click)="saveDepartment(row)">
                      <span>Save</span>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr class="celo-hoverable celo-cursor-pointer" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      
        <mat-paginator [length]="resultsLength" [pageSize]="20"></mat-paginator>
    </div>
</div>