<form action="" #form="ngForm">
  <div *ngFor="let field of fields">
    <mat-form-field class="field" *ngIf="field.type=='text'||field.type=='email'||field.type=='tel'" appearance="outline">
      <mat-label>{{field.displayName}}</mat-label>
      <input matInput [(ngModel)]="formObject[field.key]" [name]="field.key" class="" [required]="field.required" [type]="field.type" autocomplete="off" [placeholder]="field.displayName+(field.required?'*':'')" (ngModelChange)="changed(form.valid)">
    </mat-form-field>
    <mat-form-field class="field" *ngIf="field.type=='select'" appearance="outline">
        <mat-label>{{field.displayName}}</mat-label>
        <mat-select [(ngModel)]="formObject[field.key]" [name]="field.key" [required]="field.required" autocomplete="off" (ngModelChange)="changed(form.valid)">
          <mat-option value="">
            None
          </mat-option>
          <mat-option *ngFor="let option of field.options" [value]="option.id">
            {{option.name}}
          </mat-option>
        </mat-select>
    </mat-form-field>
  </div>
  <div class="field">
    <mat-checkbox [disabled]="true" matTooltip="A temporary password will be generated and emailed to the user" [checked]="true" name="generatePassword">Generate Password</mat-checkbox>
  </div>
</form>
