import pack from '../../package.json';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export interface Environment {
    production: boolean;
    celoAuth: string;
    celoApiEndpoint: string;
    celoAdminApiEndpoint: string;
    celoAuthApiEndpoint: string;
    origin: string;
    appVersion: string;
    clientId: string;
}

export const defaultEnvironment: Environment = {
    production: false,
    celoAuth: '59ac8c4bb45f5b0001e38d913d00e8f4a95b45a46ab694d4b8fbdef3',
    celoApiEndpoint: 'https://localhost:44393',
    celoAdminApiEndpoint: 'https://localhost:44377',
    celoAuthApiEndpoint: 'https://localhost:44361',
    origin: 'localhost:4300',
    clientId: 'celo.admintool.3A5B86050DF3',
    appVersion: `admin-${pack.version}`
};
