<div class="parent celo-content mih_100vh">
    <div class="relative-table-container">
        <div *ngIf="isLoadingResults || isEmpty" class="loading-shade">
          <app-empty-state *ngIf="isLoadingResults"></app-empty-state>
          <div *ngIf="isEmpty">
            - Empty -
          </div>
        </div>
      
        <div class="table-container">
      
          <table mat-table [dataSource]="logs" class="table"
                 matSort matSortActive="created" matSortDisableClear matSortDirection="desc">      
            
            <ng-container matColumnDef="userName">
              <th mat-header-cell *matHeaderCellDef>Username</th>
              <td mat-cell *matCellDef="let row">
                <div *ngIf="!row.editmode" class="value-field" [matTooltip]="row.userName?row.userName:'-'">{{row.userName?row.userName:'-'}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let row">
                <div *ngIf="!row.editmode" class="value-field" [matTooltip]="row.email">{{row.email}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="ipAddress">
              <th mat-header-cell *matHeaderCellDef>IP Address</th>
              <td mat-cell *matCellDef="let row">
                <div *ngIf="!row.editmode" class="value-field" [matTooltip]="row.ipAddress">{{row.ipAddress}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="loginDate">
              <th mat-header-cell *matHeaderCellDef>loginDate</th>
              <td mat-cell *matCellDef="let row">
                <div *ngIf="!row.editmode" class="value-field" [matTooltip]="row.loginDate?row.loginDate:'-'">{{row.loginDate?(row.loginDate|date:'short'):'-'}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="logoutDate">
              <th mat-header-cell *matHeaderCellDef>Logout Date</th>
              <td mat-cell *matCellDef="let row">
                <div *ngIf="!row.editmode" class="value-field" [matTooltip]="row.logoutDate?row.logoutDate:'-'">{{row.logoutDate?(row.logoutDate|date:'short'):'-'}}</div>
              </td>
            </ng-container>
            <tr mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr class="celo-hoverable celo-cursor-pointer" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      
        <mat-paginator [length]="resultsLength" [pageSize]="20"></mat-paginator>
    </div>
</div>