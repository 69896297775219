import { Component, OnInit, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

import {ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { PeriodicElement } from '../../workplace-pages/workplace-users/workplace-users.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-workplace-interests',
  templateUrl: './workplace-interests.component.html',
  styleUrls: ['./workplace-interests.component.scss','../notification-styles.scss']
})
export class WorkplaceInterestsComponent implements OnInit {
  
  displayedColumns: string[] = ['name', 'actions'];
  formObject:any = {};

  data = new MatTableDataSource<PeriodicElement>([]);

  resultsLength = 0;
  isLoadingResults = true;
  query:string = '';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  pageSize: number = 25;
  searchEvent = new EventEmitter();

  selection = new SelectionModel<PeriodicElement>(true, []);
  range: any;
  status:string = 'Pending' ;



  constructor(
    private sharedService:SharedService
    ,private route:ActivatedRoute
  ) { }


  ngOnInit() {
    this.data.sort = this.sort;
    this.route.params.subscribe(routeParams => {
      this.searchEvent.emit();
      this.data = new MatTableDataSource<PeriodicElement>([]);
    });

    this.route.queryParams.subscribe(queryParams => {
    });
  }
  rangeChange(range){
    this.range = range;
    this.search(this.query, range);
  }
  search(q,range){
    var params = range?range:{};
    if(q)
      params['q'] = q;
    this.sharedService.navigateTo('/users',params)
  }
  change(){
    this.data = new MatTableDataSource<PeriodicElement>([]);
    this.searchEvent.emit();
  }
  ngAfterViewInit() {
    const path = environment.celoApiEndpoint + '/api/Admin/companyRegistrations'

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    
    merge(this.sort.sortChange, this.paginator.page, this.searchEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          let params = {
            page:this.paginator.pageIndex,
            status:this.status,
            pageSize:this.pageSize
          }
          if(this.query){
            params['Search'] = this.query
          }
          if(this.range){
            params['fromCreationTime'] = this.getUTC(this.range.start)
            params['ToCreationTime'] = this.getUTC(this.range.end)
          }
          return this.sharedService.getObjectById(path,params);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data.total;
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.data = new MatTableDataSource<PeriodicElement>([]);
        this.data = new MatTableDataSource<PeriodicElement>(data);
        this.data.sort = this.sort;
        if(!data||data.length==0){
          // this.isEmpty = true;
        }
      });
  }
  itemClick(item){
    this.sharedService.navigate(['/user/'+item['userId']])
  }
  getUTC(time){
    return new Date(time).toISOString();
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numRows;
    if(this.data&&this.data.data)
      numRows = this.data.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.data.data.forEach(row => this.selection.select(row));
  }
  
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}
