import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as jwt_decode from "jwt-decode";
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn: boolean;
  loggedInUserId: any;
  refreshTokenInProgress: boolean;

  constructor(
    private oauthService: OAuthService
    ,private http: HttpClient
  ) {
    // this.setLoginStatus();
   }
  logout = () => {
    // this.revoke();
    // this.oauthService.logOut();
    this.oauthService.revokeTokenAndLogout({drid:this.getAuthRegion()});
    this.resetRegion();
    localStorage.setItem('userId','');
    // this._securityService.logoff();
  }
  
  public getRT() {
    const token = this.oauthService.getRefreshToken();
    return token;
  }
  // revoke(){
  //   const path = `${environment.celoAuthApiEndpoint}/connect/revocation`;
  //   let body = `token=${this.getRT()}&token_type_hint=refresh_token&client_id=celo.desktop.6CC9BEAE2341`;
  //   const headers: any = {
  //     'content-type':'application/x-www-form-urlencoded'
  //   };
  //   this.http.post(path,body,{headers:headers}).subscribe(
  //     data => {
  //     },
  //     error => {
  //     }
  //   )
  // }
  public hasRoles(requiredRoles): boolean {


    if(!this.getAT())
      return true;


    let content = this.getDecodedAccessToken(this.getAT())?this.getDecodedAccessToken(this.getAT()):{};
    let existingRoles = [];
    if(!Array.isArray(content.role)){
      existingRoles = [content.role];
    }else{
      existingRoles = content.role;
    }

    if(!content.role||!content.role.length)
      return false;
    for(let role of requiredRoles){
      if(existingRoles.indexOf(role) == -1){
        return false;
      }
    }
    return true;
  }
  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }
  getAT(){
    return this.oauthService.getAccessToken();
  }
  getAuthorization(){
    return 'Bearer '+this.oauthService.getAccessToken();
  }

  // setLoginStatus(){
  //   this.oidcSecurityService.getIsAuthorized().subscribe(auth => {
  //     this.loggedIn = auth;
  //   });
  // }

  setRegion(region?){
    if(region){
      localStorage.setItem('data_region',region);
      return;
    }
    let at = this.getAT();
    let content = this.getDecodedAccessToken(at)?this.getDecodedAccessToken(at):{};
    if(content.sub&&content['data_region']){
      localStorage.setItem('data_region',content['data_region']);
    }
  }
  login(hint?,drid?) {
    let params = {};
    if(drid)
      params['drid'] = drid;
    if(hint)
      params['login_hint'] = hint;
    // this.resetRegion();

    params["prompt"] = "login";

    this.oauthService.customQueryParams = params;
    this.oauthService.initCodeFlow();
  }
  
  resetRegion(){
    localStorage.removeItem('data_region');
    localStorage.removeItem('auth_region');
  }
  getRegion(){
    let at = this.getAT();
    let content = this.getDecodedAccessToken(at)?this.getDecodedAccessToken(at):{};
    let region;
    if(content.sub&&content['data_region']){
      region = content['data_region'];
    }else{
      region = localStorage.getItem('data_region');
    }
    return region;
  }
  getAuthRegion(){
    let region;
    region = localStorage.getItem('auth_region');
    return region;
  }
  setAuthRegion(region){
    localStorage.setItem('auth_region',region);
  }
}
