import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'core/services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RolesGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.authService.hasRoles(route.data.roles)) {
            this.router.navigate(['/unauthorized']);
            // this.router.navigate(['/login']);
            // this.router.navigate(['/unauthorized']);
            return false;
        }
        return true;
    }
    //   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    //     // return this.checkUser();
    //     let ru = state.url;
    //   return new Observable<boolean>((subscriber) => {
    //     if (this.oidcSecurityService.moduleSetup) {
    //         subscriber.next(true);
    //         subscriber.complete();
    //     }
    //     else {
    //         this.oidcSecurityService.onModuleSetup.pipe(take(1)).subscribe(() => {
    //             subscriber.next(true);
    //             subscriber.complete();
    //         });
    //     }
    // })
    // .pipe(
    //     switchMapTo(this.oidcSecurityService.getIsAuthorized()),
    //     map((isAuthorized: boolean) => {
    //         if (this.authService.hasRoles(route.data.roles)) {
    //           return true;
    //         }
    //         this.router.navigate(['/unauthorized']);
    //         return false;
    //     }),
    //     take(1))
    // }
}
