import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/services/shared.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import {merge, Observable, of as observableOf} from 'rxjs';
import { AlertService } from '../../../modules/core/services/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-workplace-departments',
  templateUrl: './workplace-departments.component.html',
  styleUrls: ['./workplace-departments.component.scss']
})
export class WorkplaceDepartmentsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'actions'];

  @Input() id:string;
  @Input() company:any = {};
  
  departments = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  database: any | null;
  resultsLength = 0;
  isLoadingResults = true;



  constructor(
    private sharedService:SharedService,
    private alertService:AlertService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setData();
  }
  setData(){
    this.database = {};
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          const path = environment.celoApiEndpoint+'/api/companies/'+this.id+'/departments';
          let params = {
            Page:this.paginator.pageIndex,
            PageSize:20
          }
          // return this.database!.getRepoIssues(this.sort.active, this.sort.direction, this.paginator.pageIndex);
          return this.sharedService.getObjectById(path,params);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data.total;
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => this.departments = data);
  }

  deleteDepartment(department){
    this.alertService.customDialog(
      "Delete this department?",'Are you sure you want to delete this department? All users belonging to this department will be disassociated from this department.',
      "Yes, delete this department", "Cancel", false
    ).afterClosed().subscribe(
      result=>{
        if(result){
          this.delete(department['companyId'],department['id'])
        }
      }
    )
  }
  delete(companyId, departmentId){
    const path = environment.celoApiEndpoint + "/api/admin/companies/"+companyId+"/departments/"+departmentId;
    this.sharedService.deleteObjectById(path).subscribe(
      (data)=>{
        this.alertService.showSnackBar('Deleted successfully',2)
        this.setData();
      },
      (err)=>{

      },
      ()=>{

      }
    )
  }
  saveDepartment(department){
    this.save(department);
  }
  save(department){
    const path = environment.celoApiEndpoint + "/api/admin/companies/"+department['companyId']+"/departments/"+department['id'];
    let payload = {
      companyId:department['companyId'],
      id:department['id'],
      name:department['name_field']
    }
    this.sharedService.putObjectById(path,payload).subscribe(
      (data)=>{
        department.name = department['name_field'];
        department['editmode'] = false;
        this.alertService.showSnackBar('Saved',2)
      },
      (err)=>{

      },
      ()=>{

      }
    )
  }
  addDepartment(){
    let payload = [
      {
        type:'form',
        fields:[
          {
            key:'name',
            displayName:'New department name'
          }
        ]
      }
    ]
    this.alertService.customDialog("Add new department",'','Add','Cancel',false,'',payload)
    .afterClosed().subscribe(
      result=>{
        if(result&&result['payload']&&result['payload'].name){
          this.add(this.id,result['payload'].name)
        }
      }
    )
  }
  add(companyId,departmentName){
    const path = environment.celoApiEndpoint + "/api/admin/companies/"+companyId+"/departments";
    let payload = {
      companyId:companyId,
      id:'',
      mode:'view',
      name:departmentName
    }
    this.sharedService.postObjectById(path,payload).subscribe(
      (data)=>{
        this.setData();
        this.alertService.showSnackBar('Department Added',2)
      },
      (err)=>{

      },
      ()=>{

      }
    )
  }
  clicked(department){
    this.sharedService.navigate(['/workplaces',this.id],{queryParams:{
      page:'department',
      departmentId:department.id
    }})
  }

}
