<div *ngIf="!editMode">
  <div class="field" *ngFor="let field of workplace_fields">
    <div *ngIf="field.type=='text'||field.type=='select'">
      <div class="field_title">{{field.name}}</div>
      <div class="field_value">{{workplace[field.key]?workplace[field.key]:'-'}}</div>
    </div>
    <div *ngIf="field.type=='claims'">
      <div class="field_title">{{field.name}}</div>
      <div>{{workplace[field.key]|claims:'-'}}</div>
    </div>
  </div>
  <div class="field">
    <div class="field_title">Status</div>
    <div class="field_value">{{workplace.isActive?'Active':'Inactive'}}</div>
  </div>
  <div class="field">
    <div class="field_title">Joined On</div>
    <div class="field_value">{{workplace.joinedOnUtc?(workplace.joinedOnUtc|date:'medium'):'-'}}</div>
  </div>
  <div class="field">
    <div class="field_title">Left On</div>
    <div class="field_value">{{workplace.leftOnUtc?(workplace.leftOnUtc|date:'medium'):'-'}}</div>
  </div>
</div>
<div *ngIf="editMode">
  <div *ngFor="let field of workplace_fields" class="inline_block">
    <div class="field" *ngIf="!field.hidden">
      <mat-form-field class="fw" appearance="outline">
        <mat-label>{{field.name}}</mat-label>
        <input *ngIf="field.type=='text'" class="fw" matInput [(ngModel)]="workplace[field.key]" type="text" [placeholder]="field.name" [readonly]="!field.editable">
        <mat-select *ngIf="field.type=='select'" [(ngModel)]="workplace[field.key]" [name]="field.key">
          <mat-option value="">
            None
          </mat-option>
          <span *ngIf="field.key=='departmentId'">
            <mat-option *ngFor="let option of departments" [value]="option.id">
              {{option.name}}
            </mat-option>
          </span>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div>
    <div class="field">
      <div class="field_title">Status</div>
      <div class="field_value">{{workplace.isActive?'Active':'Inactive'}}</div>
    </div>
  </div>
  <div>
    <div class="field">
      <div class="field_title">Joined On</div>
      <div class="field_value">{{workplace.joinedOnUtc?(workplace.joinedOnUtc|date:'medium'):'-'}}</div>
    </div>
    <div class="field">
      <div class="field_title">Left On</div>
      <div class="field_value">{{workplace.leftOnUtc?(workplace.leftOnUtc|date:'medium'):'-'}}</div>
    </div>
  </div>
  <div class="field" *ngIf="editMode">
    <a *ngIf="workplace.isActive" class="celo-red-link celo-link" (click)="deactivateWorkplace(workplace)">
      <span>Deactivate (Remove the user form this workplace)</span>
    </a>
    <a *ngIf="!workplace.isActive" class="celo-link celo-link" (click)="activateWorkplace(workplace)">
      <span>Activate</span>
    </a>
  </div>
  <app-celo-cancel-save-buttons [showSave]="true" [showCancel]="true" (cancel)="cancelWorkplaceEditMode(workplace)" (save)="saveWorkplace(workplace)"></app-celo-cancel-save-buttons>
</div>

