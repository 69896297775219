import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';
import { AlertService } from '../../../modules/core/services/alert.service';

@Component({
  selector: 'app-workplace-partners',
  templateUrl: './workplace-partners.component.html',
  styleUrls: ['./workplace-partners.component.scss']
})
export class WorkplacePartnersComponent implements OnInit {

  @Input() id:string;
  partners = [];

  current_partners = [];
  non_partners = [];
  selectAllCurrent:boolean;
  selectAllNon:boolean;
  hasChanged:boolean = false;

  constructor(
    private sharedService:SharedService,
    private alertService:AlertService
  ) { }

  ngOnInit() {
    this.getPackage();
  }
  cancel(){
    this.getPackage();
  }
  save(){
    const path = environment.celoApiEndpoint+'/api/admin/companies/'+this.id+'/partners';
    let payload = [];
    this.partners.forEach(element => {
      payload.push({
        id:element.id,
        isPartner:element.isPartner,
        name:element.name
      })
    });
    this.sharedService.postObjectById(path,payload).subscribe(
      data=>{
        // this.partners = data;
        this.alertService.showSnackBar('Partner workplaces updated successfully',4);
        this.resetChecked();
      },
      err=>{
        this.alertService.showSnackBar('Failed to update partner workplace',4)
      }
    )
  }

  getPackage(){
    const path = environment.celoApiEndpoint+'/api/admin/companies/'+this.id+'/partners';
    this.sharedService.getObjectById(path,{}).subscribe(
      data=>{
        this.partners = data;
        this.partners.sort( this.compare );
        this.hasChanged = false;
      }
    )
  }
  compare( a, b ) {
    if ( a.name.toLowerCase() < b.name.toLowerCase() ){
      return -1;
    }
    if ( a.name.toLowerCase() > b.name.toLowerCase() ){
      return 1;
    }
    return 0;
  }
  add(){
    this.hasChanged = true;
    this.partners.forEach(element => {
      if(element.checked){
        element.isPartner = true;
      }
    });
    this.resetSelectAll();
  }
  remove(){
    this.hasChanged = true;
    this.partners.forEach(element => {
      if(element.checked){
        element.isPartner = false;
      }
    });
    this.resetSelectAll();
  }
  setAllCurrentPartners(status){
    this.partners.forEach(element => {
      if(element.isPartner){
        element.checked = !!status;
      }
    });
  }
  setAllNonPartners(status){
    this.partners.forEach(element => {
      if(!element.isPartner){
        element.checked = !!status;
      }
    });
  }
  resetSelectAll(){
    this.selectAllCurrent = false;
    this.selectAllNon = false;
  }
  resetChecked(){
    this.partners.forEach(element => {
      element.checked = undefined;
    });
  }
}
