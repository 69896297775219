<div class="parent celo-content">

  <div class="tabs" name="fontStyle" aria-label="Font Style">
    <a [routerLink]="['/profession',{status:'Pending'}]"><button mat-button [ngClass]="{'button-checked':status=='Pending'}">Pending</button></a>
    <a [routerLink]="['/profession',{status:'MoreInfoRequired'}]"><button mat-button [ngClass]="{'button-checked':status=='MoreInfoRequired'}">Waiting for Info</button></a>
    <a [routerLink]="['/profession',{status:'Unverified'}]"><button mat-button [ngClass]="{'button-checked':status=='Unverified'}">Unverified</button></a>
    <a [routerLink]="['/profession',{status:'Verified'}]"><button mat-button [ngClass]="{'button-checked':status=='Verified'}">Verified</button></a>
  </div>
  <div class="top-buttons">
    <div class="left">
      <input type="search" [(ngModel)]="query" class="celo-input celo-search" placeholder="Search" (keyup.enter)="search()">
    </div>
    <div *ngIf="status==professionVerificationStatusModel.Pending" class="right">
      <button matTooltip="Request more information from selected users." mat-button class="celo-button celo-primary-button" (click)="moreInfo(selection.selected)" [disabled]="selection&&selection.selected&&selection.selected.length?false:true">
          <span>More Info</span>
      </button>
    </div>
  </div>
  <div class="relative-table-container">
    <div *ngIf="isLoadingResults" class="loading-shade">
      <app-empty-state *ngIf="isLoadingResults"></app-empty-state>
    </div>
  
    <div class="table-container">
      <table mat-table [dataSource]="data" class="table"
             matSort matSortActive="created" matSortDisableClear matSortDirection="desc">      
  
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>&nbsp;&nbsp;
          </th>
          <td mat-cell> test </td>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>&nbsp;&nbsp;
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let row" class="name-row">
            <div class="avatar">
              <app-avatar [name]="row.firstName + ' ' + row.lastName" [userId]="row.userId" [width]="36" [height]="36" [hasImage]="!!row.profilePic"></app-avatar>
            </div>
            <span class="name">{{row.firstName}} {{row.lastName}}</span>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef>Country</th>
          <td mat-cell *matCellDef="let row">{{row.countryCode|country}}</td>
        </ng-container>
  
        <ng-container matColumnDef="profession">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Profession</th>
          <td mat-cell *matCellDef="let row">{{row.profession}}</td>
        </ng-container>

        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Details</th>
          <td mat-cell *matCellDef="let row">
            <div>{{row.registrationName?row.registrationName:''}}</div>
            <div>{{row.registrationNumber?row.registrationNumber:''}}</div>
            <div *ngIf="row.registrationLink" (click)="$event.stopPropagation()">
              <a target="_blank" class="celo-link" [href]="(row.registrationLink|linkify)">{{row.registrationLink}}</a>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
            <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
              <button mat-button class="action-button celo-secondary-button" (click)="decline(row)">DECLINE</button>
              <button mat-button class="action-button celo-primary-button" (click)="accept(row)">APPROVE</button>
            </td>
        </ng-container>
  
        <tr mat-header-row class="table-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr class="celo-hoverable celo-cursor-pointer" (click)="verificationClick(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div *ngIf="!isLoadingResults&&(!data||!data.data||!data.data.length)" class="no-results">- No results found -</div>
    </div>
    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize" (page)="pageSize = $event.pageSize"></mat-paginator>
  </div>
</div>