import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'core/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    // oidcpart
    isAuthenticated: boolean;
    userData: any;
    status = '';
    accounts = [];
    loggingin: boolean;
    editMode: boolean;
    dataRegionId: string;
    sub: any;

    constructor(
        private sharedService: SharedService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        if (this.router.url.indexOf('signin-oidc') != -1) {
            this.status = 'loggedin';
        } else if (this.router.url.indexOf('signout') != -1) {
            this.status = 'loggingout';
        }
    }
    ngOnInit() {
        this.route.queryParamMap.subscribe((paramMap) => {
            // this.email = paramMap.get('email');
            this.dataRegionId = paramMap.get('data_region_id');
        });
        this.login();
    }
    login() {
        this.dataRegionId = this.dataRegionId ? this.dataRegionId : this.authService.getAuthRegion();
        this.authService.setAuthRegion(this.dataRegionId);
        this.authService.login('', this.dataRegionId);
        this.authService.setRegion(this.dataRegionId);
    }
    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
