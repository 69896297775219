<div class="celo-input-outline">
  <div class="title">Workspace Location</div>
  <form action="" class="content" #locationForm="ngForm">
    <div
      *ngFor="let field of locationFields"
      class="field"
      [ngClass]="{ fw: field.size == 'large' }"
    >
      <mat-form-field
        *ngIf="field.field == 'text' || field.field == 'number'"
        class="fw"
        appearance="outline"
      >
        <input
          matInput
          [required]="field.required"
          [type]="field.field"
          [placeholder]="field.display"
          [(ngModel)]="location[field.key]"
          [name]="field.key"
        />
        <mat-label>{{ field.display }}</mat-label>
      </mat-form-field>
      <mat-form-field
        *ngIf="field.field == 'select'"
        class="fw"
        appearance="outline"
      >
        <mat-select
          *ngIf="field.key == 'countryCode'"
          [required]="field.required"
          [(ngModel)]="location[field.key]"
          [name]="field.key"
          [placeholder]="field.display + (field.required ? ' *' : '')"
        >
          <mat-option
            *ngFor="let option of countries"
            [value]="option.shortName"
          >
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <button
        mat-button
        type="button"
        *ngIf="!isNewLocation"
        class="celo-danger-button"
        (click)="deleteClicked()"
      >
        Remove location
      </button>
    </div>
    <button
      mat-button
      [disabled]="!locationForm.valid"
      class="celo-primary-button"
      type="submit"
      (click)="saveClicked()"
    >
      Save
    </button>
    <button mat-button class="celo-secondary-button" mat-dialog-close>
      Cancel
    </button>
  </form>
</div>
