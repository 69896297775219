<div id="workspaceTopAnchor"></div>
<div class="info-box celo-content">
  <div class="breadcrums">
    <div *ngIf="!departmentId">
      <a [routerLink]="['/workplaces']">Workspaces ></a>&nbsp;&nbsp;<span>{{
        company.name
      }}</span>
    </div>
    <div *ngIf="departmentId">
      <a [routerLink]="['/workplaces']">Workspaces ></a>&nbsp;&nbsp;<a
        [routerLink]="['/workplaces/' + company.id]"
        [queryParams]="{ page: 'info' }"
        ><span>{{ company.name }} ></span></a
      >
    </div>
  </div>
  <div class="title-container">
    <div class="title-container app-title-box">
      <app-title-box
        [title]="company.name"
        [firstName]="company.name"
        [subtitle]="!company.countryCode ? '' : (company.countryCode | country)"
        [flag]="company.isActive == true ? 'Active' : 'Inactive'"
        [showAvatar]="true"
        [hasImage]="company.profilePictureUri"
        [companyId]="company.id"
      ></app-title-box>
    </div>
  </div>
</div>
<div class="parent celo-content mih_100vh">
  <div *ngIf="!departmentId" class="sidenav">
    <ul>
      <a
        *ngFor="let page of pages"
        [routerLink]="['/workplaces/' + id]"
        [queryParams]="{ page: page.route }"
        routerLinkActive="active"
        ><li>{{ page.displayName }}</li></a
      >
    </ul>
  </div>
  <div *ngIf="!departmentId" class="line"></div>
  <div class="content">
    <div *ngIf="page == 'info'" class="">
      <app-workplace-info
        [id]="id"
        [company]="company"
        (companyChanged)="companyChanged($event)"
      ></app-workplace-info>
    </div>
    <div *ngIf="page == 'departments'" class="">
      <app-workplace-departments [id]="id"></app-workplace-departments>
    </div>
    <div *ngIf="page == 'department'" class="">
      <app-workplace-department
        [id]="departmentId"
        [company]="company"
      ></app-workplace-department>
    </div>
    <div *ngIf="page == 'users'" class="">
      <app-workplace-users [id]="id"></app-workplace-users>
    </div>
    <div *ngIf="page == 'partner_company'" class="">
      <app-workplace-partners [id]="id"></app-workplace-partners>
    </div>
    <div *ngIf="page == 'domain_settings'" class="">
      <app-workplace-settings [id]="id"></app-workplace-settings>
    </div>
    <div *ngIf="page == 'documents'" class="">
      <app-workplace-documents [id]="id"></app-workplace-documents>
    </div>
  </div>
</div>
