import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { WorkplacesComponent } from './components/workplaces/workplaces.component';
import { UsersComponent } from './components/users/users.component';
import { AuthGuard } from './guards/auth.guard';
import { WorkplaceComponent } from './components/workplace/workplace.component';
import { IdentityComponent } from './components/notifications/identity/identity.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AccessLogsComponent } from './components/access-logs/access-logs.component';
import { UnauthorizedComponent } from './components/common/unauthorized/unauthorized.component';
import { RolesGuard } from './guards/roles.guard';
import { UserComponent } from './components/users/user/user.component';
import { ProfessionComponent } from './components/notifications/profession/profession.component';
import { WorkplaceInterestsComponent } from './components/notifications/workplace-interests/workplace-interests.component';
import { UserInterestsComponent } from './components/notifications/user-interests/user-interests.component';
import { LoggedInComponent } from './components/logged-in/logged-in.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'workplaces',
    pathMatch: 'full'
    // component: WorkplacesComponent,
    // canActivate: [AuthGuard, RolesGuard],
    // data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'workplaces',
    component: WorkplacesComponent,
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'accesslog',
    component: AccessLogsComponent,
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'workplaces/:id',
    component: WorkplaceComponent,
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'workplaces/:id',
    component: WorkplaceComponent,
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'user/:id',
    component: UserComponent,
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'identity',
    component: IdentityComponent,
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'profession',
    component: ProfessionComponent,
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'registrations/workplaces',
    component: WorkplaceInterestsComponent,
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: 'registrations/users',
    component: UserInterestsComponent,
    canActivate: [AuthGuard, RolesGuard],
    data: { roles: ['SuperAdmin'] }
  },
  {
    path: "delete-requests",
    loadChildren: () =>
      import("./modules/delete-requests/delete-requests.module").then((m) => m.DeleteRequestsModule),
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'signin-oidc',
    component: LoggedInComponent,
  },
  {
    path: 'signout-callback-oidc',
    redirectTo: '/login',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthGuard
  ]
})
export class AppRoutingModule { }
