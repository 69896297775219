

import { Component, OnInit, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

import {HttpClient} from '@angular/common/http';
import {ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { AlertService } from '../../../modules/core/services/alert.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { PeriodicElement } from '../../workplace-pages/workplace-users/workplace-users.component';
import { ProfessionVerificationStatusModel } from 'src/app/models/ProfessionVerificationStatus';
import { VerificationService } from 'src/app/services/verification.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profession',
  templateUrl: './profession.component.html',
  styleUrls: ['./profession.component.scss','../notification-styles.scss']
})
export class ProfessionComponent implements AfterViewInit {

  columns: string[] = ['name', 'country', 'profession', 'details'];
  pendingPageColumns: string[] = ['select','name', 'country', 'profession', 'details', 'action'];
  displayedColumns: string[] = ['select','name', 'country', 'profession', 'details'];

  formObject:any = {};

  // data: Company[] = [];
  // data = new MatTableDataSource();
  data = new MatTableDataSource<PeriodicElement>([]);

  resultsLength = 0;
  isLoadingResults = true;
  query:string = '';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  pageSize: number = 25;
  searchEvent = new EventEmitter();

  status:string = 'Pending' ;

  selection = new SelectionModel<PeriodicElement>(true, []);


  constructor(
    private sharedService:SharedService
    ,private verificationService:VerificationService
    ,private alertService:AlertService
    ,private _httpClient: HttpClient
    ,public professionVerificationStatusModel: ProfessionVerificationStatusModel
    ,private route:ActivatedRoute
  ) { }


  ngOnInit() {
    // this.database.sort = this.sort;
    this.data.sort = this.sort;
    this.status = this.professionVerificationStatusModel.Pending;
    this.route.params.subscribe(routeParams => {
      this.status = routeParams['status'];
      if(!this.status){
        this.status = 'Pending';
        return;
      }
      this.pageFilterChange();
      this.search();
    });
    this.pageFilterChange();
  }
  resetSelection(){
    this.selection.clear();
  }
  search(){
    this.searchEvent.emit(this.query);
    this.data = new MatTableDataSource<PeriodicElement>([]);
  }
  change(){
    this.data = new MatTableDataSource<PeriodicElement>([]);
    this.searchEvent.emit(this.status);
  }
  ngAfterViewInit() {
    const path = environment.celoApiEndpoint + '/api/admin/ProfessionVerifications'
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.searchEvent)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.resetSelection();
          this.isLoadingResults = true;
          let params = {
            page:this.paginator.pageIndex,
            pageSize:this.pageSize,
            status:this.status,
          }
          if(this.query){
            params['search'] = this.query;
          }
          return this.sharedService.getObjectById(path,params);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data.total;
          return data.data;
        })
        // ,catchError(() => {
        //   this.isLoadingResults = false;
        //   this.resultsLength = 0;
        //   // location.reload();
        //   return observableOf([]);
        // })
      ).subscribe(data => {
        this.data = new MatTableDataSource<PeriodicElement>([]);
        this.data = new MatTableDataSource<PeriodicElement>(data);
        this.data.sort = this.sort;
        if(!data||data.length==0){
          // this.isEmpty = true;
        }
      });
  }
  verificationClick(verification){
    this.sharedService.navigate(['/user/'+verification['userId']])
  }

  moreInfo(professionVerifications){
    // this.alertService.showSnackBar('Coming soon',4)
    this.bulkUpdate(professionVerifications,this.professionVerificationStatusModel.MoreInfoRequired);
  }
  bulkUpdate(professionVerifications, status){
    for (const identityVerification of professionVerifications) {
      identityVerification['status']=status;
    }
    let instance = this;
    let path = environment.celoApiEndpoint + '/api/admin/professionVerifications/moreInfoRequired';
    this.sharedService.postObjectById(path,professionVerifications).subscribe(
      resp=>{
        instance.search();
        instance.alertService.showSnackBar('Successfully updated '+professionVerifications.length+ ' verification(s)',4)
      },
      err=>{
        instance.alertService.showSnackBar('Failed to update verifications',4)
      }
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numRows;
    if(this.data&&this.data.data)
      numRows = this.data.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.data.data.forEach(row => this.selection.select(row));
  }
  
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  decline(verification){
    this.editStatus(verification,'Unverified');
  }
  accept(verification){
    this.editStatus(verification,'Verified');
  }

  editStatus(verification, status){
    let verificationObj = {
      category:verification['category'],
      id:verification['id'],
      profession:verification['profession'],
      userId:verification['user'],
      verificationStatus:status
    }
    let instance = this;
    this.verificationService.editVerificationStatusProfession(verificationObj, status, verification.userId, function(data){
      instance.search();
    })
  }
  pageFilterChange(){
    this.displayedColumns = this.columns;
    if(this.status == 'Pending'){
      this.displayedColumns = this.pendingPageColumns;
    }
  }
}