import { AfterViewInit, Component, EventEmitter, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';
import { AlertService } from '../../modules/core/services/alert.service';


@Component({
  selector: 'app-workplaces',
  templateUrl: './workplaces.component.html',
  styleUrls: ['./workplaces.component.scss']
})
export class WorkplacesComponent implements AfterViewInit {
  displayedColumns: string[] = ['name', 'email', 'plan', 'verificationStatus', 'registeredDate', 'createdBy', 'isActive'];
  formObject:any = {};

  // data: Company[] = [];
  data = new MatTableDataSource();

  resultsLength = 0;
  isLoadingResults = true;
  query:string = '';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  pageSize: number = 25;
  searchEvent = new EventEmitter()

  constructor(
    private sharedService:SharedService
    ,private alertService:AlertService
  ) { }


  ngOnInit() {
    // this.database.sort = this.sort;
    this.data.sort = this.sort;
  }
  search(q){
    this.searchEvent.emit();
    this.data = undefined;
  }
  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.searchEvent)
      .pipe(
        startWith({}),
        switchMap(() => {

          this.isLoadingResults = true;
          const path = environment.celoApiEndpoint + '/api/admin/companies'
          let params = {
            Page:this.paginator.pageIndex,
            PageSize:this.pageSize,
            // SortBy:this.sort.active,
            // Direction:this.sort.direction
          }
          if(this.query){
            params['Name'] = this.query
          }
          return this.sharedService.getObjectById(path,params);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data.total;
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.data = data;
        this.data.sort = this.sort;
      });
  }
  companyClick(company){
    this.sharedService.navigate(['/workplaces/'+company['id']],{ queryParams: { page: 'info'} })
  }
  addWorkplace(){
    this.formObject.userLimit = 100;
    this.formObject.isEnterprise = false;
    let payload = [
      {
        type:'add_workplace',
        formObject:this.formObject
      }
    ]
    this.alertService.customDialog("Add new workspace","Please enter the new workspace details",'Save','Cancel',false,'',payload).afterClosed().subscribe(
      result=>{
        if(!result||!result.payload){
          return;
        }
        let payload = result.payload;
        payload['id']="new";
        payload['isActive']=true;
        if(!result||!result.payload||!result.payload.countryCode)
        {
          this.alertService.customDialog('Incomplete data','Please provide a country','Close','',true)
          return;
        }
        
        const path = environment.celoApiEndpoint + '/api/admin/companies'
        this.sharedService.postObjectById(path, payload).subscribe(
          data=>{
            this.companyClick(data)
          },
          err=>{
            let message = '';
            let error = err['error'];
            for (const key in error) {
              if (error.hasOwnProperty(key)) {
                message = error[key][0]+' ';
              }
            }
            this.alertService.customDialog('Failed to create workplace',message,'Close','',true);
            // this.alertService.showSnackBar("Failed to create workplace",3);
          },
          ()=>{}
        )
      }
      )
  }
}

export interface Companies {
  data: Company[];
  total: number;
}

export interface Company {
  name: string;
  email: string;
  plan: string;
  verificationStatus: string;
  registeredDate: string;
  createdBy: string;
  isActive: string;
}

