import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { AlertService } from '../../../modules/core/services/alert.service';
import { SharedService } from 'src/app/services/shared.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { environment } from 'src/environments/environment';
import { ClaimsModel, ClaimsResponseModel } from 'src/types';
import { EditLocationComponent } from '../../common/edit-location/edit-location.component';
import { CompanyModel, Operation, WorkspaceAddressModel } from './../../../../types/index';
import { EditLocationComponentPayload } from './../../common/edit-location/edit-location.component';
import { EditLicensesComponent, EditLicensesComponentPayload } from '../../common/edit-licenses/edit-licenses.component';
import { ApiService } from 'core/services/api.service';
import { LicenseService } from 'src/app/services/license.service';
import { RenewLicensesComponent, RenewLicensesComponentPayload } from '../../common/renew-licenses/renew-licenses.component';

type DisplayItemType = 'date' | 'toggle' | 'status' | 'number' | 'checkbox';

interface DisplayItemBase {
    key: string;
    displayName: string;
    editable?: boolean;
    type?: DisplayItemType;
}

interface DateDisplayItem extends DisplayItemBase {
    type: 'date';
}

interface ToggleDisplayItem extends DisplayItemBase {
    type: 'toggle';
    options: ToggleOption[];
}

interface NumberDisplayItem extends DisplayItemBase {
    type: 'number';
}

interface CheckboxDisplayItem extends DisplayItemBase {
    type: 'checkbox';
}

interface ToggleOption {
    display: string;
    value: string;
}

interface StatusDisplayItem extends DisplayItemBase {
    type: 'status';
}

interface WorkspaceLimitsModel {
    currentAllocatedLicenses: number,
    licenseLimit: number
}

type DisplayItem = DisplayItemBase | DateDisplayItem | ToggleDisplayItem | StatusDisplayItem | NumberDisplayItem | CheckboxDisplayItem;

@Component({
    selector: 'app-workplace-info',
    templateUrl: './workplace-info.component.html',
    styleUrls: ['./workplace-info.component.scss']
})
export class WorkplaceInfoComponent implements OnInit, OnChanges {
    @ViewChild('EditPhotoFileInput') editPhotoFileInput: ElementRef<HTMLInputElement>;

    @Input() id: string;
    @Input() company: CompanyModel;
    public formObject: CompanyModel | null = null;
    @Input() editmode = false;
    @Output() companyChanged = new EventEmitter<CompanyModel>();
    locationFields: any[];

    public displayItems: DisplayItem[] = [
        {
            key: 'name',
            displayName: 'Workspace Name',
            editable: true
        },
        {
            key: 'email',
            displayName: 'Workspace email',
            editable: true
        },
        {
            key: 'countryCode',
            displayName: 'Country'
        },
        {
            key: 'registeredDate',
            displayName: 'Created Date',
            type: 'date'
        },
        {
            key: 'userLimit',
            displayName: 'User Limit',
            editable: true
        },
        {
            key: 'isEnterprise',
            displayName: 'Enterprise',
            type: 'checkbox'
        },
        {
            key: 'plan',
            displayName: 'Pricing Plan',
            type: 'toggle',
            editable: true,
            options: [
                {
                    display: 'Free',
                    value: 'Free'
                },
                {
                    display: 'Business',
                    value: 'Business'
                },
                {
                    display: 'Enterprise',
                    value: 'Enterprise'
                }
            ]
        },
        {
            key: 'verificationStatus',
            displayName: 'Verification Status',
            type: 'toggle',
            editable: true,
            options: [
                {
                    display: 'Verified',
                    value: 'Verified'
                },
                {
                    display: 'Unverified',
                    value: 'Unverified'
                }
            ]
        },
        {
            key: 'isActive',
            displayName: 'Status',
            type: 'status'
        }
    ];
    claims: ClaimsModel;
    // claimsCopy: any = {};

    workspaceInvitationInvite: boolean;
    workspaceInvitationReset: boolean;
    workspaceUserManage: boolean;
    workspaceTeamManage: boolean;
    workspaceAllowExternalConversations: boolean;
    locations: WorkspaceAddressModel[] = [];
    workspaceLimits: WorkspaceLimitsModel = {
        currentAllocatedLicenses: 0,
        licenseLimit: 0
    };

    unsavedWorkspacePhoto: File | null = null;
    unsavedWorkspacePhotoUrl: string | null = null;

    constructor(
        private sharedService: SharedService,
        private workspaceService: WorkspaceService,
        private alertService: AlertService,
        private apiService: ApiService,
        private licensesService: LicenseService,
        private dialog: MatDialog
    ) {
        this.locationFields = this.sharedService.locationFields;
    }

    edit() {
        this.editmode = true;
        this.workspaceInvitationInvite = this.claims['workspaceInvitationInvite'] == 'true' ? true : false;
        this.workspaceInvitationReset = this.claims['workspaceInvitationReset'] == 'true' ? true : false;
        this.workspaceUserManage = this.claims['workspaceUserManage'] == 'true' ? true : false;
        this.workspaceTeamManage = this.claims['workspaceTeamManage'] == 'true' ? true : false;
        this.workspaceAllowExternalConversations = this.claims['workspaceAllowExternalConversations'] == 'true' ? true : false;
        this.scrollTop();
    }
    scrollTop() {
        const anchor = document.getElementById('workspaceTopAnchor') as HTMLElement;
        this.sharedService.scrollToAnchor(anchor);
    }

    ngOnInit() {
        this.getClaims();
        this.setLocations();
    }

    getClaims() {
        let instance = this;
        this.workspaceService.getWorkspaceClaims(this.id, function (response: ClaimsResponseModel) {
            if (!response || !response.claims) {
                instance.alertService.customDialog('', 'Error loading workspace default claims', 'Ok', '');
                return;
            }
            instance.claims = response.claims;
        });
    }

    ngOnChanges(c : SimpleChanges) {
        if(!!c["company"] && !!this.company.id) {
            this.apiService.get<WorkspaceLimitsModel>({
                path: `/api/admin/v2/Companies/${this.company.id!}/Users/limits`
            }).subscribe(r => {
                this.workspaceLimits = r;
                return r;
            }, e => {
                this.workspaceLimits = {
                    currentAllocatedLicenses: 0,
                    licenseLimit: 0
                }
            });
        }

        this.reset(false);
        this.setLocations();
    }

    setLocations() {
        this.locations = this.company?.addresses || [];
    }

    cancel() {
        this.reset();
        this.editmode = false;
    }

    private reset(ignoreChanges: boolean = true) {
        if (ignoreChanges) {
            this.formObject = { ...this.company };
            this.unsavedWorkspacePhoto = null;
            this.unsavedWorkspacePhotoUrl = null;
        } else {
            const editableKeys = this.displayItems.filter((item) => item.editable).map((item) => item.key);

            const editableFields = this.formObject
                ? Object.fromEntries(Object.entries(this.formObject).filter(([key]) => editableKeys.includes(key)))
                : {};

            this.formObject = { ...this.company, ...editableFields, };
        }
    }

    save() {
        this.saveInfo((res) => {
            if (res) {
                this.saveClaims();
            }
        });
    }

    saveInfo(callback: (result: boolean) => void) {
        let params = {};
        for (const ob of this.displayItems) {
            if (ob.editable) {
                let key = ob['key'];
                params[key] = this.formObject[key];
            }
        }

        const path = environment.celoApiEndpoint + '/api/admin/companies/' + this.id;

        const observables: {
            companyUpdate: Observable<any>;
            profilePictureUpdate?: Observable<CompanyModel>;
        } = {
            companyUpdate: this.sharedService.putObjectById(path, params)
        };

        if (this.unsavedWorkspacePhoto) {
            observables.profilePictureUpdate = this.workspaceService.updateProfileImage(
                this.id,
                this.unsavedWorkspacePhoto
            );
        }

        forkJoin(observables).subscribe({
            next: (results) => {
                this.companyChanged.emit({ ...this.formObject });
                this.alertService.showSnackBar('Workspace info saved', 3);
                callback(true);
            },
            error: (e) => {
                const errorMessage = this.sharedService.readErrorMessage(
                    e,
                    'An error has occured while updating workspace info.'
                );
                this.alertService.customDialog('Error updating workspace info', errorMessage, 'Ok', '', true);
            },
            complete: () => {
                this.editmode = false;
                this.scrollTop();
                this.reset(false);
            }
        });
    }

    saveClaims() {
        let path = environment.celoApiEndpoint + '/api/admin/companies/' + this.id + '/settings/defaultUserClaims';
        let body = {
            claims: {
                workspaceInvitationInvite: this.workspaceInvitationInvite,
                workspaceInvitationReset: this.workspaceInvitationReset,
                workspaceUserManage: this.workspaceUserManage,
                workspaceTeamManage: this.workspaceTeamManage,
                workspaceAllowExternalConversations: this.workspaceAllowExternalConversations
            }
        };
        this.sharedService.patchObjectById(path, body).subscribe(
            (data) => {
                this.getClaims();
            },
            (err) => {
                this.alertService.customDialog('', 'Error saving claims', 'Ok', '');
            },
            () => {
                this.editmode = false;
            }
        );
    }
    activateWorkplace() {
        this.alertService
            .customDialog(
                'Activate workplace?',
                'Are you sure you want to activate this workplace?',
                'Yes, activate this workplace',
                'Cancel',
                false
            )
            .afterClosed()
            .subscribe((result) => {
                if (result) this.activate(true);
            });
    }
    deactivateWorkplace() {
        this.alertService
            .customDialog(
                'Deactivate workplace?',
                'Are you sure you want to deactivate this workplace?',
                'Yes, deactivate this workplace',
                'Cancel',
                false
            )
            .afterClosed()
            .subscribe((result) => {
                if (result) this.activate(false);
            });
    }
    activate(status) {
        let path = environment.celoApiEndpoint + '/api/admin/companies/' + this.id;
        if (status) {
            path += '/activate';
        } else {
            path += '/deactivate';
        }
        this.sharedService.postObjectById(path).subscribe(
            (data) => {
                this.company.isActive = status;
                this.companyChanged.emit(this.company);
                if (status) {
                    this.alertService.showSnackBar('Activated successfully', 2);
                } else {
                    this.alertService.showSnackBar('Deactivated successfully', 2);
                }
            },
            (err) => { },
            () => { }
        );
    }
    copy(text) {
        this.sharedService.copyMessage(text);
    }
    resetLinkClick() {
        this.alertService
            .customDialog(
                'Are you sure you want to revoke invitation link for this workspace?',
                'This operation will revoke the existing link and create new invitation link.',
                'Confirm',
                'Cancel',
                false
            )
            .afterClosed()
            .subscribe((res) => {
                if (!res) {
                    return;
                }
                this.resetLink();
            });
    }
    resetLink() {
        let path = environment.celoApiEndpoint + '/api/admin/companies/' + this.id + '/invitation/reset';
        this.sharedService.postObjectById(path).subscribe(
            (res) => {
                if (!res) {
                    return;
                }
                this.company['invitationUri'] = res['invitationUri'];
                this.companyChanged.emit(this.company);
                this.alertService.customDialog('Link has been reset successfully', '', 'Ok', '', true);
            },
            (err) => {
                this.alertService.customDialog('', 'Error reseting link. Please try again laer.', 'Ok', '', true);
            }
        );
    }

    editLicenses() {
        const ref = this.dialog.open<EditLicensesComponent, void, EditLicensesComponentPayload>(EditLicensesComponent, {
            autoFocus: false,
            panelClass: 'celo-popup'
        });

        ref.afterClosed().subscribe(r => {
            console.log("Creating Licenses.....");
            console.log(r);
            this.licensesService.createLicenses(r.newCount, r.expiryDate, this.company.id).subscribe(_ => {
                this.alertService.showSnackBar("Edited License Counts", 10);
                this.workspaceLimits.licenseLimit = r.newCount;
            }, e => {
                this.alertService.showErrorSnackbar(`Failed to update licenses. Reason: ${e}`, 10);
            });
        });
    }

    renewLicenses() {
        const ref = this.dialog.open<RenewLicensesComponent, void, RenewLicensesComponentPayload>(RenewLicensesComponent, {
            autoFocus: false,
            panelClass: 'celo-popup'
        });

        ref.afterClosed().subscribe(r => {
            console.log("Renewing Licenses.....");
            console.log(r);
            this.licensesService.renewLicenses(r.expiryDate, this.company.id).subscribe(_ => {
                this.alertService.showSnackBar("Renewed License Counts", 10);
            }, e => {
                this.alertService.showErrorSnackbar(`Failed to renew licenses. Reason: ${e}`, 10);
            });
        });
    }

    editLocation(location?: WorkspaceAddressModel) {
        const ref = this.dialog.open<EditLocationComponent, void, EditLocationComponentPayload>(EditLocationComponent, {
            autoFocus: false,
            panelClass: 'celo-popup'
        });

        if (location) {
            ref.componentInstance.location = { ...location };
        }

        ref.afterClosed().subscribe({
            next: (res) => {
                if (!res) return;

                if (res.type === 'save' && location) {
                    this.updateLocation(location, res.data);
                } else if (res.type === 'save') {
                    this.createLocation(res.data);
                } else if (res.type === 'delete') {
                    this.deleteLocation(location.id);
                }
            },
            error: () => this.alertService.showErrorSnackbar('Error editing locations')
        });
    }

    updateLocation(previousLocation: WorkspaceAddressModel, newLocation: WorkspaceAddressModel) {
        const operations: Operation[] = [];
        for (const [key, value] of Object.entries(newLocation)) {
            if (value !== location[key]) {
                const operation: Operation = {
                    value,
                    path: `/${key}`,
                    op: 'add'
                };
                operations.push(operation);
            }
        }

        this.workspaceService.updateAddress(this.id, previousLocation.id, operations).subscribe({
            next: (workspace) => {
                const index = this.company.addresses.findIndex((c) => c.id === workspace.id);
                const addresses = [...this.company.addresses];
                addresses[index] = workspace;
                this.companyChanged.emit({
                    ...this.company,
                    addresses
                });
            },
            error: () => this.alertService.showErrorSnackbar('Error updating location')
        });
    }

    createLocation(location: WorkspaceAddressModel) {
        this.workspaceService.createAddress(this.id, location).subscribe({
            next: (workspace) => {
                this.companyChanged.emit({
                    ...this.company,
                    addresses: [...this.company.addresses, workspace]
                });
            },
            error: () => this.alertService.showErrorSnackbar('Error creating location')
        });
    }

    deleteLocation(locationId: string) {
        this.workspaceService.deleteAddress(this.id, locationId).subscribe({
            next: (workspace) => {
                this.companyChanged.emit({
                    ...this.company,
                    addresses: this.company.addresses.filter((a) => a.id != workspace.id)
                });
            },
            error: () => this.alertService.showErrorSnackbar('Error deleting location')
        });
    }

    handleEditPhotoClick() {
        this.editPhotoFileInput.nativeElement.click();
    }

    handleEditPhotoFileChange($event: Event) {
        const target = $event.target as HTMLInputElement;
        const file = target.files[0];

        if (!file) return;

        const reader = new FileReader();

        reader.addEventListener('load', () => {
            if (typeof reader.result !== 'string') {
                this.alertService.showErrorSnackbar('Error loading image');
                return;
            }

            this.unsavedWorkspacePhoto = file;
            this.unsavedWorkspacePhotoUrl = reader.result;
        });

        reader.readAsDataURL(file);
    }
}
