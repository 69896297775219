import { ApiTokenPagedResult, DeleteUserRequest, DeleteUserRequestParameters, DeleteUserRequestStatus } from 'types';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AdminUsersService {
    public constructor(private apiService: ApiService) {}

    public createDeleteUserRequest(userId: string, reason: string | null = null) {
        const path = `/api/admin/Users/${userId}`;
        return this.apiService.delete<DeleteUserRequest>({ path, body: { reason } });
    }

    public getDeleteUserRequests(options: DeleteUserRequestParameters = {}) {
        const path = '/api/admin/Users/DeleteRequests';
        return this.apiService.get<ApiTokenPagedResult<DeleteUserRequest[]>>({
            path,
            queryParams: { ...options }
        });
    }

    public updateDeleteUserRequest(id: string, status: DeleteUserRequestStatus) {
        const path = `/api/admin/Users/DeleteRequests/${id}`;
        return this.apiService.put<DeleteUserRequest>({
            path,
            body: { id, status }
        });
    }

    public deleteUserInfo(id: string) {
        const path = `/api/admin/v2/Users/${id}`;

        return this.apiService.delete({
            path
        })
    }
}
