import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    var result;
    var startingUrl = "http://";
    var httpsStartingUrl = "https://";
    if(value.startsWith(startingUrl)||value.startsWith(httpsStartingUrl)){
        result = value;
    }
    else {
    result = startingUrl + value;
    }
    return result;
  }

}
