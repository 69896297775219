<div class="relative-table-container">
    <div *ngIf="isLoading" class="loading-shade">
        <app-empty-state *ngIf="isLoading"></app-empty-state>
    </div>

    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="table">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let connection">
                    {{ connection.title + ' ' + connection.firstName + ' ' + connection.lastName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let connection">{{ connection.email }}</td>
            </ng-container>
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef>State</th>
                <td mat-cell *matCellDef="let connection">{{ connection.state }}</td>
            </ng-container>
            <ng-container matColumnDef="lastModified">
                <th mat-header-cell *matHeaderCellDef>Last Modified</th>
                <td mat-cell *matCellDef="let connection">{{ connection.lastModifyOnUtc | date: 'short' }}</td>
            </ng-container>
            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef>Is Creator</th>
                <td mat-cell *matCellDef="let connection">
                    {{ connection.createdBy.toLowerCase() === id.toLowerCase() ? 'True' : 'False' }}
                </td>
            </ng-container>
            <tr mat-header-row class="table-header" *matHeaderRowDef="columns"></tr>
            <tr
                mat-row
                class="celo-hoverable celo-cursor-pointer"
                (click)="onRowClick(row)"
                *matRowDef="let row; columns: columns"
            ></tr>
        </table>
    </div>

    <mat-paginator
        [length]="totalConnections"
        [pageSizeOptions]="[10, 25, 50, 100]"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        (page)="onPageChange($event)"
    ></mat-paginator>
</div>
