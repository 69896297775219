import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'claims'
})
export class ClaimsPipe implements PipeTransform {

  transform(value: unknown, defaultValue: string = ''): unknown {
    let claimNames = [];
    if(value['workspaceInvitationInvite']=='true')
      claimNames.push('Link Sharing')
    if(value['workspaceInvitationReset']=='true')
      claimNames.push('Link Resetting')
    if(value['workspaceUserManage']=='true')
      claimNames.push('User management')
    if(value['workspaceTeamManage']=='true')
      claimNames.push('Team management')
    if(value['workspaceAllowExternalConversations']=='true')
      claimNames.push('External conversation')
    
      if(claimNames.length)
      return claimNames.join(', ');

    return defaultValue;
  }

}
