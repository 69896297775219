import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { AuthService } from 'core/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
    selector: 'app-logged-in',
    templateUrl: './logged-in.component.html',
    styleUrls: ['./logged-in.component.scss']
})
export class LoggedInComponent implements OnInit {
    isAuthenticated: boolean;
    sub: any;

    constructor(
        public oauthService: OAuthService,
        public sharedService: SharedService,
        public authService: AuthService
    ) {}

    ngOnInit() {
        this.sharedService.navigateToRoute('workplaces');

        this.sub = this.oauthService.events.pipe(filter((e) => e.type === 'token_received')).subscribe(
            (_) => {
                let content = this.authService.getDecodedAccessToken(this.authService.getAT())
                    ? this.authService.getDecodedAccessToken(this.authService.getAT())
                    : {};
                this.sharedService.setUserId(content.sub);
            },
            (err) => {},
            () => {}
        );
    }
    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
