import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-celo-date-range',
  templateUrl: './celo-date-range.component.html',
  styleUrls: ['./celo-date-range.component.scss']
})
export class CeloDateRangeComponent implements OnInit {

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });
  @Output() rangeChange = new EventEmitter<any>();
  @Input() startDate:string;
  @Input() endDate:string;


  constructor() { }

  ngOnInit() {
  }
  dateChange(){
    if(this.range&&this.range.value&&this.range.value.start&&this.range.value.end){
      this.rangeChange.emit(this.range.value);
    }
  }
  clearFilter(){
    this.range.reset();
    this.rangeChange.emit(this.range.value);
  }
  ngOnChanges(){
    if(this.range&&this.range.value){
      if(this.startDate&&this.endDate){
        this.range.setValue({
          start:new Date(this.startDate),
          end:new Date(this.endDate)
        })
      }
    }
  }

}
