import { Component, OnInit } from '@angular/core';
import { AuthService } from 'core/services/auth.service';
import { UserAccountService } from 'src/app/services/user-account.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  tabs = [
    {
      link: 'workplaces',
      type:'button',
      id:"nav-workplaces",
      name: 'Workspaces',
      notifications: 0
    },
    {
      link: 'users',
      type:'button',
      id:'nav-users',
      name: 'Users'
    },
    {
      link: 'identity',
      type:'button',
      params:{status:'Pending'},
      id:'nav-identity',
      name: 'Identity'
    },
    {
      link: 'profession',
      type:'button',
      params:{status:'Pending'},
      id:'nav-profession',
      name: 'Profession'
    },
    {
      type:'menu',
      name:'Registrations',
      tabs:[
        {
          link: 'registrations/workplaces',
          id:'nav-notifications',
          name: 'Workspace'
        },
        {
          link: 'registrations/users',
          id:'nav-notifications',
          name: 'User'
        }
      ]
    },
    {
      link: 'delete-requests',
      type:'button',
      id:'nav-delete-requests',
      name: 'Delete Requests'
    },
  ];
  userAccount:any;
  data_region: string;

  constructor(
    private authService:AuthService,
    private userAccountService:UserAccountService
  ) { }

  ngOnInit() {
    let i = this;
    this.userAccountService.getUserAccount(function(user){
      if(!user){
        return;
      }
      i.userAccount = user;
    });
    this.data_region = this.authService.getRegion();
    // this.setIdentityTabVisibility();
  }
  logout(){
    this.authService.logout();
  }
  // setIdentityTabVisibility(){
  //   for (const tab of this.tabs) {
  //     if(tab.link=='identity'){
  //       if(this.data_region!='ANZ'){
  //         tab['type']='disabled';
  //         tab['tooltip']='Please login using ANZ admin account to manage identity verifications';
  //       }
  //     }
  //   }
  // }

}
