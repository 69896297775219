import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AlertService } from '../../../modules/core/services/alert.service';
import { merge, of } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-workplace-settings',
  templateUrl: './workplace-settings.component.html',
  styleUrls: ['./workplace-settings.component.scss']
})
export class WorkplaceSettingsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'actions'];

  @Input() id:string;
  departments = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  database: any | null;
  resultsLength = 0;
  isLoadingResults = true;
  isEmpty: boolean;



  constructor(
    private sharedService:SharedService,
    private alertService:AlertService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setData();
  }
  setData(){
    this.database = {};
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          const path = environment.celoApiEndpoint + '/api/admin/companies/' + this.id + '/settings/domains';
          let params = {
            Page:this.paginator.pageIndex,
            PageSize:20
          }
          // return this.database!.getRepoIssues(this.sort.active, this.sort.direction, this.paginator.pageIndex);
          return this.sharedService.getObjectById(path,params);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data.total;
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return of([]);
        })
      ).subscribe(data => {
        this.departments = data
        if(!data||data.length==0){
          this.isEmpty = true;
        }
      });
  }

  deleteEntry(entry){
    this.alertService.customDialog(
      "Delete this domain?",'Are you sure you want to delete this domain?',
      "Yes, delete this domain", "Cancel", false
    ).afterClosed().subscribe(
      result=>{
        if(result){
          this.delete(entry['companyId'],entry['id'])
        }
      }
    )
  }
  delete(companyId, domainId){
    const path = environment.celoApiEndpoint + "/api/admin/companies/"+companyId+"/settings/domains/"+domainId;
    this.sharedService.deleteObjectById(path).subscribe(
      (data)=>{
        this.alertService.showSnackBar('Deleted successfully',2)
        this.setData();
      },
      (err)=>{

      },
      ()=>{

      }
    )
  }
  saveDepartment(department){
    this.save(department);
  }
  save(department){
    const path = environment.celoApiEndpoint + "/api/admin/companies/"+department['companyId']+"/departments/"+department['id'];
    let payload = {
      companyId:department['companyId'],
      id:department['id'],
      name:department['name_field']
    }
    this.sharedService.putObjectById(path,payload).subscribe(
      (data)=>{
        department.name = department['name_field'];
        department['editmode'] = false;
        this.alertService.showSnackBar('Saved',2)
      },
      (err)=>{

      },
      ()=>{

      }
    )
  }
  addDepartment(){
    let payload = [
      {
        type:'form',
        fields:[
          {
            key:'name',
            displayName:'New domain'
          }
        ]
      }
    ]
    this.alertService.customDialog("Add new domain",'','Add','Cancel',false,'',payload)
    .afterClosed().subscribe(
      result=>{
        if(result&&result['payload']&&result['payload'].name){
          this.add(this.id,result['payload'].name);
          this.isEmpty = false;
        }
      }
    )
  }
  add(companyId,departmentName){
    const path = environment.celoApiEndpoint + "/api/admin/companies/"+companyId+"/settings/domains";
    let payload = {
      name:departmentName
    }
    this.sharedService.postObjectById(path,payload).subscribe(
      (data)=>{
        this.setData();
        this.alertService.showSnackBar('Domain Added',2)
      },
      (err)=>{

      },
      ()=>{

      }
    )
  }

}
