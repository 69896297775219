import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '../../../../modules/core/services/alert.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-workplace',
  templateUrl: './add-workplace.component.html',
  styleUrls: ['./add-workplace.component.scss','../../../../celo-input.scss','../common-styles.scss']
})
export class AddWorkplaceComponent implements OnInit {

  workplaces : any = [];
  departments : any = [];
  selectedDepartments : any = [];
  workplace = {
    companyId:'',
    departmentId:'',
    isNotifyUser:true
  } ;
  @Output() formchange = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Output() onNewUser = new EventEmitter<any>();
  searching: boolean;

  @Input() userId:string;
  @Input() countryCode:string = '';
  loading:boolean;

  constructor(
    private sharedService:SharedService,
    private alertService:AlertService
  ) { }

  ngOnInit() {
  }

  search(q) { 
    this.workplaces = [];
    if(!q||q.length<3){
      this.alertService.showSnackBar('Please enter atleast 3 letters to start searching',4)
      return;
    }
    this.searching = true;
    let instance = this;
    this.loadWorkplaces(q, this.countryCode, function(){
      instance.searching = false;
    });
  }
  loadWorkplaces(name, countryCode, callback){
    const path = environment.celoApiEndpoint + '/api/admin/companies'
    let params = {
      Page:0,
      PageSize:100000,
      Name:name
    }
    return this.sharedService.getObjectById(path,params).subscribe(
      res=>{
        this.workplaces = res.data;
        callback();
      }
      ,err=>{
        callback()
      }
    );
  }
  workplaceSelected(){
    this.workplace['departmentId']='';
    this.loadDepartments('')
  }
  searchDepartment(value) { 
    this.selectedDepartments = this.searchArray(this.departments,value);
  }
  searchArray(array, value: string) { 
    let filter = value.toLowerCase();
    return array.filter(country_ob => country_ob.name.toLowerCase().indexOf(filter)!=-1);
  }
  loadDepartments(name){
    const path = environment.celoApiEndpoint + '/api/companies/'+this.workplace['companyId']+'/departments'
    let params = {
      Page:0,
      PageSize:10000,
      Name:name
    }
    return this.sharedService.getObjectById(path,params).subscribe(
      res=>{
        this.departments = res.data;
        this.selectedDepartments = this.departments;
        this.searching = false;
      }
      ,err=>{
        this.searching = false;
      }
    );
  }

  changed(){
    this.formchange.emit(this.workplace);
  }
  closeDialog(){
    this.close.emit();
  }
  addWorkplace(){
    const path = environment.celoApiEndpoint + '/api/Admin/users/'+this.userId+'/workplace';
    this.loading = true;

    this.alertService.showSnackBar('Adding workplace. Please wait...',4);
    
    this.sharedService.postObjectById(path,this.workplace).subscribe(
      res=>{
        this.loading = false;
        this.alertService.showSnackBar('Workplace added successfully',4);
        this.onNewUser.emit(res);
      },
      err=>{
        this.loading = false;
        let errorMessage = this.sharedService.readErrorMessage(err, 'An error has occured while adding workspace info.');
        this.alertService.customDialog('',errorMessage,'OK','');
      }
    )
  }
}
