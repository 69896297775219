
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,HttpResponse
} from '@angular/common/http';
import { AuthService } from 'core/services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class CeloHttpInterceptorService implements HttpInterceptor {
    constructor(
      private injector: Injector
      ,private authService:AuthService
      ,private router: Router
      ,private oauthService:OAuthService
      ) {}
 
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let requestToForward = req;
      if (this.oauthService === undefined) {
        this.oauthService = this.injector.get(this.oauthService);
      }
      if (this.oauthService !== undefined) {
        requestToForward = this.setHeaders(req);

      }
      return next.handle(requestToForward)
      .pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          if(
            event['url'].indexOf('/connect/token')!=-1
            ||event['url'].indexOf('/.well-known/openid-configuration')!=-1
            ){
            let region = event.headers.get('celo-region');
            if(region){
              if(event['url'].indexOf('/.well-known/openid-configuration')!=-1&&!this.authService.getAuthRegion())
                this.authService.setAuthRegion(region);
              else if(event['url'].indexOf('/connect/token')!=-1){
                this.authService.setAuthRegion(region);
              }
            }
            }
        }
        return event;
      }));
    }
    private setHeaders(req: HttpRequest<any>): HttpRequest<any> {
      // if(req.url.indexOf('/.well-known/openid-configuration')!=-1){
      //   return req;
      // }
      let token = this.oauthService.getAccessToken();
      let region = this.authService.getRegion();
      if(req.url.indexOf(environment.celoAuthApiEndpoint)!=-1){
          region = this.authService.getAuthRegion();
      }
      let headers = { 
        CeloAuthorization:environment.celoAuth,
        AppVersion:environment.appVersion
      }
      headers['Celo-Region'] = region?region:'';
      if (token !== '') {
        let tokenValue = 'Bearer ' + token;
        if(tokenValue){
          headers['Authorization'] = tokenValue;
        }
        return req.clone({ setHeaders: headers});
      }else{
        return req.clone({ setHeaders: headers});
      }
    }
}
