import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
// import {PinscreenService} from '../pinscreen/pinscreen.service';
import { AuthService } from 'core/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private oauthService: OAuthService, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
            return true;
        } else {
            if (this.oauthService.getRefreshToken()) this.oauthService.refreshToken();
            // else{
            //   this.authService.login()
            // }
            return false;
        }
    }
}
