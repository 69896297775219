<div class="relative">
  <div class="page-title">Users</div>
  <div class="">
    <div class="button-set">
      <button
        matTooltip="Add new users to this workplace"
        mat-button
        class="celo-button celo-primary-button"
        (click)="addNewUser()"
      >
        <span>Add New User</span>
      </button>
      <span>
        <button
          [disabled]="
            selection && selection.selected && selection.selected.length
              ? false
              : true
          "
          mat-button
          class="celo-button celo-primary-button"
          (click)="manageClaims()"
        >
          <span>Manage claims</span>
        </button>
      </span>
      <span>
        <button
          mat-button
          class="celo-button celo-primary-button"
          (click)="manageRoles()"
          [disabled]="
            selection && selection.selected && selection.selected.length
              ? false
              : true
          "
        >
          <span>Manage Roles</span>
        </button>
      </span>
      <span matTooltip="Activate selected users in this workplace">
        <button
          [disabled]="
            selection && selection.selected && selection.selected.length
              ? false
              : true
          "
          mat-button
          class="celo-button celo-primary-button"
          (click)="activateUsers()"
        >
          <span>Activate</span>
        </button>
      </span>
      <span matTooltip="Deactivate selected users in this workplace">
        <button
          [disabled]="
            selection && selection.selected && selection.selected.length
              ? false
              : true
          "
          mat-button
          class="celo-button celo-secondary-button"
          (click)="deactivateUsers()"
        >
          <span>Deactivate</span>
        </button>
      </span>
      <!-- <span matTooltip="Generate password for the select user">
          <button [disabled]="selection&&selection.selected&&selection.selected.length==1?false:true" mat-button class="celo-button celo-secondary-button" (click)="generatePassword(selection.selected)">
              <span>Generate Password</span>
          </button>
        </span> -->
    </div>
    <div class="search-bar">
      <div *ngFor="let field of searchFields" class="input-div">
        <input
          *ngIf="!field.type"
          [id]="field.key"
          matInput
          type="text"
          class="celo-input"
          [(ngModel)]="searchObject[field.key]"
          [placeholder]="field.name"
          autocomplete="off"
          (keyup.enter)="search()"
        />
        <select
          *ngIf="field.type == 'select'"
          class="celo-input celo-input-select"
          [id]="field.key"
          [(ngModel)]="searchObject[field.key]"
        >
          <option [value]="undefined" selected>{{ field.name }}</option>
          <option *ngFor="let option of field.options" [value]="option.value">
            {{ option.display }}
          </option>
        </select>
        <mat-form-field *ngIf="field.type == 'multiselect'" appearance="fill">
          <mat-label>Claims</mat-label>
          <mat-select
            multiple
            [id]="field.key"
            [(ngModel)]="searchObject[field.key]"
            [matTooltip]="
              searchObject[field.key]
                ? searchObject[field.key].length + ' selected'
                : '-'
            "
          >
            <mat-option
              *ngFor="let option of field.options"
              [value]="option.value"
              >{{ option.display }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <button
        mat-button
        class="celo-button celo-primary-button search-button"
        (click)="search()"
      >
        <span>Search</span>
      </button>
    </div>
  </div>
  <div class="relative-table-container">
    <div *ngIf="isLoadingResults || isEmpty" class="loading-shade">
      <app-empty-state
        *ngIf="isLoadingResults"
        [strokeWidth]="2"
        [diameter]="30"
      ></app-empty-state>
      <div *ngIf="isEmpty">- Empty -</div>
    </div>
    <div class="table-container">
      <table
        mat-table
        [dataSource]="users"
        class="table"
        matSort
        matSortActive="created"
        matSortDisableClear
        matSortDirection="desc"
      >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox
            >&nbsp;&nbsp;
          </th>
          <td mat-cell>test</td>
          <td mat-cell *matCellDef="let row" class="checkbox">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox
            >&nbsp;&nbsp;
          </td>
        </ng-container>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Title</th>
          <td mat-cell>test</td>
          <td mat-cell *matCellDef="let row">
            <div
              *ngIf="!row.editmode"
              class="value-field"
              [matTooltip]="row.title ? row.title : '-'"
            >
              {{ row.title ? row.title : "-" }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef>Preferred Name</th>
          <td mat-cell>test</td>
          <td mat-cell *matCellDef="let row">
            <div
              *ngIf="!row.editmode"
              class="value-field"
              [matTooltip]="row.firstName"
            >
              <a
                [routerLink]="['/user/' + row.userId, { workplace_id: id }]"
                class="celo-link"
                >{{ row.firstName ? row.firstName : "-" }}</a
              >
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="legalFirstName">
          <th mat-header-cell *matHeaderCellDef>First Name</th>
          <td mat-cell>test</td>
          <td mat-cell *matCellDef="let row">
            <div
              *ngIf="!row.editmode"
              class="value-field"
              [matTooltip]="row.legalFirstName"
            >
              {{ row.legalFirstName ? row.legalFirstName : "-" }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef>Last Name</th>
          <td mat-cell>test</td>
          <td mat-cell *matCellDef="let row">
            <div
              *ngIf="!row.editmode"
              class="value-field"
              [matTooltip]="row.lastName"
            >
              {{ row.lastName ? row.lastName : "-" }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell>test</td>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="!row.editmode" class="value-field">
              <div
                *ngIf="row.workplaces && row.workplaces[0]"
                [matTooltip]="
                  row.workplaces[0].email ? row.workplaces[0].email : '-'
                "
              >
                {{ row.workplaces[0].email ? row.workplaces[0].email : "-" }}
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="department">
          <th mat-header-cell *matHeaderCellDef>Department</th>
          <td mat-cell>test</td>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="!row.editmode" class="value-field">
              <div
                *ngIf="row.workplaces && row.workplaces[0]"
                [matTooltip]="
                  row.workplaces[0].departmentName
                    ? row.workplaces[0].departmentName
                    : '-'
                "
              >
                {{
                  row.workplaces[0].departmentName
                    ? row.workplaces[0].departmentName
                    : "-"
                }}
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef>Position</th>
          <td mat-cell>test</td>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="!row.editmode" class="value-field">
              <div
                *ngIf="row.workplaces && row.workplaces[0]"
                [matTooltip]="
                  row.workplaces[0].position ? row.workplaces[0].position : '-'
                "
              >
                {{
                  row.workplaces[0].position ? row.workplaces[0].position : "-"
                }}
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="claims">
          <th mat-header-cell *matHeaderCellDef>Claims</th>
          <td mat-cell>test</td>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="!row.editmode" class="value-field">
              <div
                *ngIf="row.workplaces && row.workplaces[0]"
                [matTooltip]="row.workplaces[0].claims | claims: 'No claims'"
              >
                <div *ngIf="row.workplaces[0].claims">
                  <span>{{ row.workplaces[0].claims | claims: "-" }}</span>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell>test</td>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="!row.editmode" class="value-field">
              <div *ngIf="row.workplaces && row.workplaces[0]">
                {{ row.workplaces[0].isActive ? "Active" : "Inactive" }}
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="beenLoggedIn">
          <th mat-header-cell *matHeaderCellDef>Used App</th>
          <td mat-cell>test</td>
          <td mat-cell *matCellDef="let row">
            <div
              *ngIf="!row.editmode"
              class="value-field"
              [matTooltip]="row.beenLoggedIn ? 'Yes' : 'No'"
            >
              {{ row.beenLoggedIn ? "Yes" : "No" }}
            </div>
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="table-header"
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          class="celo-hoverable celo-cursor-pointer"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>

    <mat-paginator
      [length]="resultsLength"
      [pageSizeOptions]="[5, 10, 25, 100, 500]"
      [pageSize]="pageSize"
      (page)="isLoadingResults = true; pageSize = $event.pageSize"
    ></mat-paginator>
  </div>
</div>
