<div *ngIf="user" class="celo-input-outline">
  <div class="info-box celo-content">
    <div class="breadcrums">
      <div *ngIf="workplace_id">
        <a
          *ngIf="workplace_id"
          [routerLink]="['/workplaces/' + workplace_id]"
          [queryParams]="{ page: 'info' }"
          >Workspaces > </a
        ><a
          [routerLink]="['/workplaces/' + workplace_id]"
          [queryParams]="{ page: 'users' }"
          >Users ></a
        >&nbsp;&nbsp;<span
          >{{ user.title }} {{ user.firstName }} {{ user.lastName }}</span
        >
      </div>
      <div *ngIf="!workplace_id">
        <a [routerLink]="['/users']">Users ></a>&nbsp;&nbsp;<span
          >{{ user.title }} {{ user.firstName }} {{ user.lastName }}</span
        >
      </div>
    </div>

    <div class="title-container">
      <div class="title-container app-title-box">
        <app-title-box
          [title]="
            (user.title ? user.title : '') +
            user.firstName +
            ' ' +
            user.lastName
          "
          [flag]="user.isActive == true ? user.isPaused ? 'Active - Suspended' : 'Active' : 'Inactive'"
          [subtitle]="!user.countryCode ? '-' : (user.countryCode | country)"
          [showAvatar]="true"
          [hasImage]="!!user.picture"
          [userId]="id"
          [firstName]="user.firstName"
          [lastName]="user.lastName"
        ></app-title-box>
      </div>
      <div class="title-container feature-button">
        <button
          mat-button
          class="celo-button celo-primary-button"
          (click)="manageRoles()"
        >
          <span> Manage Roles </span>
        </button>
      </div>
      <div class="title-container feature-button">
        <button
          mat-button
          class="celo-button celo-primary-button"
          (click)="manageFeatures(user.features.photoExport, user.features.patientLookup)"
        >
          <span> Manage Features </span>
        </button>
      </div>
      <div class="title-container feature-button">
        <button
          mat-button
          class="celo-button celo-primary-button"
          (click)="manageUserMfa()"
        >
          <span> Manage MFA </span>
        </button>
      </div>
    </div>
  </div>
  <div class="parent celo-content mih_100vh">
    <div class="content">
      <div class="side-box">
        <div class="box">
          <div class="field">
            <div class="field_title">Identity Verification</div>
            <div class="field_value">{{ user.identityVerificationStatus }}</div>
            <button
              *ngIf="
                (idv_history && idv_history.length) ||
                user.identityVerificationStatus
              "
              mat-button
              class="action-button"
              (click)="editVerificationStatusIdentity()"
            >
              Edit
            </button>
          </div>
          <div *ngIf="idv_history" class="field">
            <div class="field_title">Identity Verification History</div>
            <div
              *ngFor="let verification of idv_history.reverse()"
              class="verification"
            >
              <div class="field_value">{{ verification.method }}</div>
              <div class="field_title">
                Updated by:
                {{ verification.modifiedBy ? verification.modifiedBy : "-" }}
              </div>
              <div class="field_title">
                {{ verification.lastModifiedOnUtc | date: "medium" }}
              </div>
              <div *ngIf="verification.notes" class="field_title">
                Notes: {{ verification.notes }}
              </div>
              <div *ngIf="verification.failedReason" class="field_title">
                failedReason: {{ verification.failedReason }}
              </div>
            </div>
          </div>
          <div *ngIf="!idv_history">
            <app-empty-state
              [strokeWidth]="2"
              [diameter]="20"
            ></app-empty-state>
          </div>
          <div *ngIf="idv_history && idv_history.length == 0" class="">
            No history data
          </div>
        </div>
        <button
          mat-button
          (click)="addProfessionClick()"
          class="celo-tertiary-button add-button"
        >
          + Add new profession
        </button>
        <div *ngFor="let profession of user.professions; let index = index">
          <div *ngIf="profession.verificationStatus != 'Pending'" class="box">
            <div class="field">
              <div class="set">
                <div class="field_title">Profession</div>
                <div class="field_value">{{ profession.category }}</div>
              </div>
              <div class="set">
                <div class="field_title">User Specified</div>
                <div class="field_value">
                  {{ profession.profession ? profession.profession : "-" }}
                </div>
              </div>
              <div class="set">
                <div class="field_title">Name of register</div>
                <div class="field_value">
                  {{
                    profession.registrationName
                      ? profession.registrationName
                      : "-"
                  }}
                </div>
              </div>
              <div class="set">
                <div class="field_title">Registration number</div>
                <div class="field_value">
                  {{
                    profession.registrationNumber
                      ? profession.registrationNumber
                      : "-"
                  }}
                </div>
              </div>
              <div class="set">
                <div class="field_title">Registration Link</div>
                <a
                  *ngIf="profession.registrationLink"
                  target="_blank"
                  class="celo-link field_value"
                  [href]="profession.registrationLink | linkify"
                  >{{ profession.registrationLink }}</a
                >
                <div *ngIf="!profession.registrationLink">-</div>
              </div>
              <div class="set">
                <div class="field_title">Status</div>
                <div class="field_value">
                  {{ profession.verificationStatus }}
                </div>
              </div>
              <button
                mat-button
                class="action-button"
                (click)="editVerificationStatusProfession(profession)"
              >
                Edit
              </button>
            </div>
            <button
              mat-button
              (click)="removeProfessionClick(profession)"
              class="celo-danger-button"
            >
              Remove profession
            </button>
          </div>
        </div>
        <div *ngIf="professionVerifcationHistory" class="box">
          <div class="field">
            <div class="field_title">Profession Verification History</div>
            <div
              *ngFor="let verification of professionVerifcationHistory"
              class="verification"
            >
              <div class="field_value">{{ verification.method }}</div>
              <div class="field_title">
                Updated by:
                {{
                  verification.modifiedByName
                    ? verification.modifiedByName
                    : "-"
                }}
              </div>
              <div class="field_title">
                {{ verification.lastModifiedOnUtc | date: "medium" }}
              </div>
              <div *ngIf="verification.notes" class="field_title">
                Notes: {{ verification.notes }}
              </div>
              <div *ngIf="verification.failedReason" class="field_title">
                failedReason: {{ verification.failedReason }}
              </div>
            </div>
          </div>
          <div
            *ngIf="
              professionVerifcationHistory &&
              professionVerifcationHistory.length == 0
            "
            class=""
          >
            No history data
          </div>
        </div>
      </div>
      <div class="info">
        <div class="pending-notifications">
          <div *ngFor="let profession of user.professions; let index = index">
            <div
              *ngIf="profession.verificationStatus == 'Pending'"
              class="notification-info"
            >
              <div class="title">
                {{ user.title }} {{ user.legalFirstName }}
                {{ user.lastName }} would like to verify their profession
              </div>
              <div class="field">
                <div class="set">
                  <div class="field_title">Country</div>
                  <div class="field_value">
                    {{ user.countryCode ? (user.countryCode | country) : "-" }}
                  </div>
                </div>
                <div class="set">
                  <div class="field_title">Profession</div>
                  <div class="field_value">{{ profession.category }}</div>
                </div>
                <div class="set">
                  <div class="field_title">User Specified</div>
                  <div class="field_value">
                    {{ profession.profession ? profession.profession : "-" }}
                  </div>
                </div>
                <div class="set">
                  <div class="field_title">Name of register</div>
                  <div class="field_value">
                    {{
                      profession.registrationName
                        ? profession.registrationName
                        : "-"
                    }}
                  </div>
                </div>
                <div class="set">
                  <div class="field_title">
                    Registration number/AHPRA number(Aus user)
                  </div>
                  <div class="field_value">
                    {{
                      profession.registrationNumber
                        ? profession.registrationNumber
                        : "-"
                    }}
                  </div>
                </div>
                <div class="set">
                  <div class="field_title">Registration Link</div>
                  <a
                    *ngIf="profession.registrationLink"
                    target="_blank"
                    class="celo-link field_value"
                    [href]="profession.registrationLink | linkify"
                    >{{ profession.registrationLink }}</a
                  >
                  <div *ngIf="!profession.registrationLink">-</div>
                </div>
              </div>
              <div>
                <div class="set">
                  <button
                    mat-button
                    class="celo-secondary-button"
                    (click)="
                      editVerificationStatusProfession(
                        profession,
                        'MoreInfoRequired'
                      )
                    "
                  >
                    MORE INFO
                  </button>
                </div>
                <div class="set">
                  <button
                    mat-button
                    class="celo-secondary-button"
                    (click)="
                      editVerificationStatusProfession(profession, 'Unverified')
                    "
                  >
                    DECLINE
                  </button>
                </div>
                <div class="set">
                  <button
                    mat-button
                    class="celo-primary-button"
                    (click)="
                      editVerificationStatusProfession(profession, 'Verified')
                    "
                  >
                    APPROVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="personal">
          <div class="title">Personal Info</div>
          <div *ngIf="!editModePersonal" class="personal-info celo-hoverable">
            <div class="field" *ngFor="let personal_item of personal_fields">
              <div class="field_title">{{ personal_item.name }}</div>
              <div
                *ngIf="
                  personal_item.type == 'text' || personal_item.type == 'select'
                "
                class="field_value"
              >
                {{ user[personal_item.key] ? user[personal_item.key] : "-" }}
              </div>
              <div *ngIf="personal_item.type == 'date'" class="field_value">
                {{ user[personal_item.key] | date: "medium" }}
              </div>
              <div *ngIf="personal_item.type == 'country'" class="field_value">
                {{ user[personal_item.key] | country }}
              </div>
            </div>
          </div>
          <div *ngIf="editModePersonal" class="personal-info">
            <div class="field" *ngFor="let personal_item of personal_fields">
              <mat-form-field class="fw" appearance="outline">
                <mat-label>{{ personal_item.name }}</mat-label>
                <input
                  *ngIf="personal_item.type == 'text'"
                  class="fw"
                  matInput
                  [(ngModel)]="user[personal_item.key]"
                  type="text"
                  [placeholder]="personal_item.name"
                  [readonly]="!personal_item.editable"
                />
                <input
                  *ngIf="personal_item.type == 'country'"
                  class="fw"
                  matInput
                  [(ngModel)]="user[personal_item.key]"
                  type="text"
                  [placeholder]="personal_item.name"
                  [readonly]="!personal_item.editable"
                />
                <div
                  *ngIf="personal_item.type == 'date'"
                  class="disabled-input"
                >
                  <input
                    hidden
                    class="fw"
                    matInput
                    [(ngModel)]="user[personal_item.key]"
                    type="text"
                    [placeholder]="personal_item.name"
                    [readonly]="!personal_item.editable"
                  />
                  <div>{{ user[personal_item.key] | date: "medium" }}</div>
                </div>
                <mat-select
                  *ngIf="personal_item.type == 'select'"
                  [(ngModel)]="user[personal_item.key]"
                  [name]="personal_item.key"
                >
                  <mat-option value=""> None </mat-option>
                  <mat-option
                    *ngFor="let option of personal_item.options"
                    [value]="option.id"
                  >
                    {{ option.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <app-celo-cancel-save-buttons
              [showSave]="true"
              [showCancel]="true"
              (cancel)="editModePersonal = false; cancelPersonal()"
              (save)="savePersonal()"
            ></app-celo-cancel-save-buttons>
          </div>
          <div *ngIf="!editModePersonal">
            <a
              class="celo-primary-link celo-link"
              (click)="editModePersonal = true"
            >
              <span>Edit</span>
            </a>
          </div>
          <div class="button-activate">
            <a
              *ngIf="user.isActive == true"
              class="celo-red-link celo-link"
              (click)="deactivateUser()"
            >
              <span>Deactivate user</span>
            </a>
            <a
              *ngIf="user.isActive != true"
              class="celo-primary-link celo-link"
              (click)="activateUser()"
            >
              <span>Activate user</span>
            </a>
          </div>
          <div class="button-activate">
            <a
              *ngIf="user.isPaused !== true"
              class="celo-red-link celo-link"
              (click)="freezeAccount()"
            >
              <span>Suspend User</span>
            </a>
            <a
              *ngIf="user.isPaused === true"
              class="celo-primary-link celo-link"
              (click)="defrostAccount()"
            >
              <span>Resume User</span>
            </a>
          </div>
          <div class="button-activate">
            <a *ngIf="user.isActive==true" class="celo-red-link celo-link" (click)="deleteUser()">
              <span>Create delete user request</span>
            </a>
          </div>
          <div class="button-activate">
            <a class="celo-red-link celo-link" (click)="generatePassword()">
              <span>Generate Password</span>
            </a>
          </div>
        </div>
        <div class="workplaces">
          <div class="title">Additional Info</div>
          <div class="celo-hoverable">
            <div *ngFor="let field of additional_fields" class="item">
              <div class="">
                <div class="title">{{ field["name"] }}</div>
                <div *ngIf="user[field.key] || user[field.key] == 0">
                  <div *ngIf="field.type == 'text'" class="value">
                    {{ user[field.key] }}
                  </div>
                  <div *ngIf="field.type == 'date'" class="value">
                    {{
                      user[field.key] == "0001-01-01T00:00:00.000Z"
                        ? "-"
                        : (user[field.key] | date: "medium")
                    }}
                  </div>
                </div>
                <div *ngIf="!user[field.key] && user[field.key] != 0">
                  <div class="value">-</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="workplaces">
          <div class="title">
            <span>Workspace Info</span>
            <span class="add-button">
              <a class="celo-primary-link celo-link" (click)="addWorkplace()">
                <span>Add a workspace</span>
              </a>
            </span>
          </div>

          <div
            *ngFor="let workplace of user.workplaces"
            class="workplace celo-hoverable"
          >
            <app-workplace-edit
              [workplace]="workplace"
              [editMode]="workplace.editMode"
              [userId]="id"
              (infoChanged)="onWorkplaceInfoChanged()"
            ></app-workplace-edit>
            <!-- <div *ngIf="!workplace.editMode">
              
            </div> -->
            <div *ngIf="!workplace.editMode" class="edit-button">
              <div>
                <a
                  class="celo-primary-link celo-link"
                  (click)="workplace.editMode = true"
                >
                  <span>Edit</span>
                </a>
              </div>
              <div>
                <a
                  class="celo-primary-link celo-link"
                  target="_blank"
                  [routerLink]="['/workplaces/' + workplace.companyId]"
                  [queryParams]="{ page: 'info' }"
                >
                  <span>Workspace info</span>
                </a>
              </div>
            </div>
            <hr />
          </div>
          <!-- <div class="edit-button">
            <a class="celo-primary-link celo-link" (click)="addWorkplace()">
              <span>Add a workplace</span>
            </a>
          </div> -->

          <div
            *ngFor="let workplace of workplaceRegistrationInterests"
            class="interest celo-hoverable"
          >
            <div>
              <span>{{
                user.title + user.firstName + " " + user.lastName
              }}</span
              ><span> has registered a workplace</span>
            </div>
            <div class="item">
              <div class="title">Workspace Name</div>
              <div class="value">{{ workplace.companyName }}</div>
            </div>
            <div class="item">
              <div class="title">Work email</div>
              <div class="value">{{ workplace.email }}</div>
            </div>
            <div class="item">
              <div class="title">Status</div>
              <div class="value">{{ workplace.status }}</div>
            </div>
            <div class="item">
              <div class="title">Department</div>
              <div class="value">{{ workplace.department }}</div>
            </div>
            <div class="item">
              <div class="title">Position</div>
              <div class="value">{{ workplace.position }}</div>
            </div>
            <div class="item">
              <div class="title">Created on</div>
              <div class="value">
                {{ workplace.createdOn | date: "medium" }}
              </div>
            </div>
            <div class="item">
              <div class="title">Country</div>
              <div class="value">{{ workplace.country }}</div>
            </div>
            <div class="item">
              <div class="title">Region</div>
              <div class="value">{{ workplace.region }}</div>
            </div>
            <div class="item">
              <div class="title">Mobile number</div>
              <div class="value">{{ workplace.phoneNumber }}</div>
            </div>
            <div *ngIf="workplace.status == 'Pending'">
              <button
                mat-button
                class="celo-primary-button"
                (click)="markDone(workplace)"
                [disabled]="workplace['updating'] == true"
              >
                DONE
              </button>
            </div>
          </div>
        </div>
        <div class="connections">
          <div class="title">
            <span>Connections</span>
          </div>
          <app-connections [id]="id"></app-connections>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!user" class="loading">
  <app-empty-state></app-empty-state>
</div>
