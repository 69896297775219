
<div class="relative">
    <div class="page-title">Partner workplaces</div>
    <div class="top-buttons">
        <button mat-button class="celo-secondary-button" [disabled]="!hasChanged" (click)="cancel()">Discard changes</button>
        <button mat-button class="celo-primary-button" [disabled]="!hasChanged" (click)="save()">Save</button>
    </div>
    <div class="boxes">
        <div class="box">
            <div class="title">Current partner workspaces</div>
            <div class="line"></div>
            <div class="list">
                <div class="checkbox">
                    <mat-checkbox [(ngModel)]="selectAllCurrent" (change)="setAllCurrentPartners($event.checked)">Select All</mat-checkbox>
                </div>
                <div *ngFor="let workplace of partners">
                    <div *ngIf="workplace.isPartner" class="checkbox">
                        <mat-checkbox [(ngModel)]="workplace.checked">{{workplace.name}}</mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-set">
            <button mat-button class="celo-primary-button" (click)="add();">
                <i class="material-icons">arrow_left</i>
                <span>Add</span>
            </button>
            <button mat-button class="celo-secondary-button" (click)="remove();">
                <span>Remove</span>
                <i class="material-icons">arrow_right</i>
            </button>
        </div>
        <div class="box">
            <div class="title">Non-partner workspaces</div>
            <div class="line"></div>
            <div class="list">
                <div class="checkbox">
                    <mat-checkbox  [(ngModel)]="selectAllNon" (change)="setAllNonPartners($event.checked)">Select All</mat-checkbox>
                </div>
                <div *ngFor="let workplace of partners">
                    <div *ngIf="!workplace.isPartner" class="checkbox">
                        <mat-checkbox [(ngModel)]="workplace.checked">{{workplace.name}}</mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>