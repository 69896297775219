import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SharedService } from 'src/app/services/shared.service';
import { AlertService } from '../../../modules/core/services/alert.service';
import { merge, of, Observable, Subject } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}


@Component({
  selector: 'app-workplace-users',
  templateUrl: './workplace-users.component.html',
  styleUrls: ['./workplace-users.component.scss']
})
export class WorkplaceUsersComponent implements OnInit {



  @Input() id: string;
  users = new MatTableDataSource<PeriodicElement>([]);
  selection = new SelectionModel<PeriodicElement>(true, []);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  database: any | null;
  resultsLength = 0;
  pageSize: number = 25;
  isLoadingResults = true;
  isEmpty: boolean;

  searchObject = {};
  searchObservable = new Subject();
  newUserObject = {};


  displayedColumns: string[] = [
    'select',
    'title', 'firstName', 'legalFirstName', 'lastName', 'email',
    'department', 'position', 'claims'
    // , 'phoneNumber',
    , 'isActive', 'beenLoggedIn'
  ];
  titles: any = [];


  searchFields = [
    {
      key: 'title',
      name: 'Title',
      type: 'select',
      options: this.titles
    },
    {
      key: 'firstName',
      name: 'Preferred Name'
    },
    {
      key: 'legalFirstName',
      name: 'First Name'
    },
    {
      key: 'lastName',
      name: 'Last Name'
    },
    {
      key: 'email',
      name: 'Email'
    },
    {
      key: 'department',
      name: 'Department'
    },
    {
      key: 'position',
      name: 'Position'
    },
    {
      key: 'claims',
      name: 'Claims',
      type: 'multiselect',
      options: [
        {
          display: 'Link sharing',
          value: 'workspaceInvitationInvite'
        },
        {
          display: 'Link resetting',
          value: 'workspaceInvitationReset'
        },
        {
          display: 'User management',
          value: 'workspaceUserManage'
        },
        {
          display: 'Team management',
          value: 'workspaceTeamManage'
        },
        {
          display: 'External conversation',
          value: 'workspaceAllowExternalConversations'
        }
      ]
    },
    // {
    //   key:'phoneNumber',
    //   name:'Phone Number'
    // },
    {
      key: 'isActive',
      name: 'Status',
      type: 'select',
      options: [
        {
          display: 'Active',
          value: 'true'
        },
        {
          display: 'Inactive',
          value: 'false'
        }
      ]
    }
  ]

  constructor(
    private sharedService: SharedService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setData();
    this.setTitleOptions();
  }
  setTitleOptions() {
    let instance = this;
    this.sharedService.getTitles(function (titles) {
      if (!titles || !titles.length) {
        return;
      }
      titles.forEach(title => {
        instance.titles.push({
          display: title,
          value: title
        })
      });
    });
  }
  setData() {
    this.database = {};
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page, this.searchObservable)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.isEmpty = false;
          const path = environment.celoApiEndpoint + '/api/admin/companies/' + this.id + '/users';
          let params = {
            Page: this.paginator.pageIndex,
            PageSize: this.pageSize
          }
          for (var field in this.searchObject) {
            if (Object.prototype.hasOwnProperty.call(this.searchObject, field)) {
              if (field != 'claims') {
                params[field] = this.searchObject[field];
              }
              else {
                for (let index = 0; index < this.searchObject[field].length; index++) {
                  const claim = this.searchObject[field][index];
                  params['UserWorkspaceClaim[' + claim + ']'] = true;
                }
              }
            }
          }
          return this.sharedService.getObjectById(path, params);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data.total;
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return of([]);
        })
      ).subscribe(data => {
        // this.departments = data;
        this.selection = new SelectionModel<PeriodicElement>(true, []);
        this.users = new MatTableDataSource<PeriodicElement>([]);
        this.users = new MatTableDataSource<PeriodicElement>(data);
        if (!data || data.length == 0) {
          this.isEmpty = true;
        }
      });
  }
  search() {
    this.searchObservable.next();
    // this.sharedService.getObjectById
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.users.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.users.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  activateUsers() {
    if (!this.selection.selected || !this.selection.selected.length) {
      return;
    }
    let userIds = [];
    this.selection.selected.forEach(user => {
      userIds.push(user['userId'])
    });
    this.alertService.customDialog(
      "Activate " + userIds.length + " user" + (userIds.length > 1 ? 's' : '') + "?", 'Allow ' + (userIds.length > 1 ? 'these users' : 'this user') + ' to continue using Celo again?',
      "Yes, activate users", "Cancel", false
    ).afterClosed().subscribe(
      result => {
        if (result) {
          this.activate(this.id, userIds, true)
        }
      }
    )
  }
  deactivateUsers() {
    if (!this.selection.selected || !this.selection.selected.length) {
      return;
    }
    let userIds = [];
    this.selection.selected.forEach(user => {
      userIds.push(user['userId'])
    });
    this.alertService.customDialog(
      "Deactivate " + userIds.length + " user" + (userIds.length > 1 ? 's' : '') + "?", "Restrict this user from accessing this company?",
      "Yes, deactivate users", "Cancel", false
    ).afterClosed().subscribe(
      result => {
        if (result) {
          this.activate(this.id, userIds, false)
        }
      }
    )
  }
  activate(companyId, userIds, activateStatus) {
    let path = environment.celoApiEndpoint + "/api/admin/companies/" + companyId + "/users/";
    if (activateStatus) {
      path += "activate";
    } else {
      path += "deactivate";
    }
    this.alertService.showSnackBar('Updating...', 1)
    this.resetSelection();
    this.sharedService.postObjectById(path, userIds).subscribe(
      (data) => {
        let message;
        if (activateStatus) {
          message = "Activated successfully";
        } else {
          message = "Deactivated successfully";
        }
        this.alertService.showSnackBar(message, 2)
        this.setData();
      },
      (err) => {

      },
      () => {

      }
    )
  }
  saveDepartment(department) {
    this.save(department);
  }
  save(department) {
    const path = environment.celoApiEndpoint + "/api/admin/companies/" + department['companyId'] + "/departments/" + department['userId'];
    let payload = {
      companyId: department['companyId'],
      id: department['userId'],
      name: department['name_field']
    }
    this.sharedService.putObjectById(path, payload).subscribe(
      (data) => {
        department.name = department['name_field'];
        department['editmode'] = false;
        this.alertService.showSnackBar('Saved', 2)
      },
      (err) => {

      },
      () => {

      }
    )
  }
  addExistingUser() { }
  addNewUser() {
    let payload = [
      {
        type: 'add_new_user',
        companyId: this.id,
        formObject: this.newUserObject
      }
    ]
    this.alertService.customDialog("Add new user", "Please save before proceding to other sections, any unsaved data will lost.", 'Save', 'Cancel', false, '', payload).afterClosed().subscribe(
      result => {
        if (!result || !result.payload) {
          return;
        }
        let payload = result.payload;
        if (!payload.valid) {
          this.alertService.showSnackBar("Incomplete user data", 3);
          return;
        }
        this.alertService.showSnackBar("Adding user...", 2);

        const path = environment.celoApiEndpoint + '/api/admin/companies/' + this.id + '/users'
        this.sharedService.postObjectById(path, payload).subscribe(
          data => {
            this.newUserObject = {};
            this.alertService.showSnackBar("Used added successfully", 3);
            this.setData();
          },
          err => {
            let message = '';
            this.newUserObject = {};
            let error = err['error']['errors'];
            for (const key in error) {
              if (error.hasOwnProperty(key)) {
                message = error[key][0] ? error[key][0] : error[key]['message'];
              }
            }
            this.alertService.customDialog('Failed to add user', message, 'Close', '', true);
          },
          () => { }
        )
      }
    )
  }

  // generatePassword(selected){
  //   let userId = selected[0]['userId'];
  //   let url = environment.celoApiEndpoint + '/api/Admin/Companies/' + this.id + '/Users/'+userId+'/Password/Generate'
  //   this.alertService.showSnackBar('Please wait...',3);
  //   this.resetSelection();
  //   this.sharedService.postObjectById(url,{}).subscribe(
  //     data=>{
  //       this.alertService.showSnackBar('Password has been generated successfully',4);

  //     },
  //     err=>{
  //     }
  //   )
  // }
  resetSelection() {
    this.selection.clear();
  }

  itemClick(id) {
    this.sharedService.navigate(['/user/' + id])
  }
  manageClaims() {
    let payload = [
      {
        type: 'manage_claims',
        workspaceInvitationInvite: false,
        workspaceInvitationReset: false,
        workspaceUserManage: false,
        workspaceTeamManage: false,
        workspaceAllowExternalConversations: false
      }
    ]
    this.alertService.customDialog("Manage Claims", 'Changing these settings will overwrite the existing claims for all the selected users.', 'Save', 'Cancel', false, '', payload)
      .afterClosed().subscribe(
        result => {
          if (!result)
            return;
          let claims = result.payload ? result.payload : {};
          let new_claims = {
            workspaceInvitationInvite: 'false',
            workspaceInvitationReset: 'false',
            workspaceUserManage: 'false',
            workspaceTeamManage: 'false',
            workspaceAllowExternalConversations: 'false'
          };
          new_claims['workspaceInvitationInvite'] = claims['workspaceInvitationInvite'] ? 'true' : 'false';
          new_claims['workspaceInvitationReset'] = claims['workspaceInvitationReset'] ? 'true' : 'false';
          new_claims['workspaceUserManage'] = claims['workspaceUserManage'] ? 'true' : 'false';
          new_claims['workspaceTeamManage'] = claims['workspaceTeamManage'] ? 'true' : 'false';
          new_claims['workspaceAllowExternalConversations'] = claims['workspaceAllowExternalConversations'] ? 'true' : 'false';
          let patch = { claims: new_claims }
          this.alertService.customDialog('', 'Are you sure you want to update claims. This will overwrite all the 5 claims of ' + this.selection.selected.length + ' users', 'Confirm', 'Cancel').afterClosed().subscribe(
            res => {
              if (!res) {
                return;
              }
              this.claimChangeLoop(this.selection.selected, 0, patch)
            });
        }
      )
  }

  manageRoles() {
    let payload = [
      {
        type: 'manage_roles',
        departmentAdmin: false,
        broadcaster: false,
        // dashboardViewer: false
      }
    ];

    this.alertService.customDialog("Manage Roles", 'Changing these settings will overwrite the existing roles for all the selected users.', 'Save', 'Cancel', false, '', payload)
      .afterClosed().subscribe(
        result => {
          if (!result)
            return;
          let claims = result.payload ? result.payload : {};
          let new_claims = {
            departmentAdmin: 'false',
            broadcaster: 'false',
            // dashboardViewer: 'false'
          };
          new_claims['departmentAdmin'] = claims['departmentAdmin'] ? 'true' : 'false';
          new_claims['broadcaster'] = claims['broadcaster'] ? 'true' : 'false';
          // new_claims['dashboardViewer'] = claims['dashboardViewer'] ? 'true' : 'false';
          let patch = { claims: new_claims }
          this.alertService.customDialog('', 'Are you sure you want to update roles. This will overwrite all the 3 roles of ' + this.selection.selected.length + ' users', 'Confirm', 'Cancel').afterClosed().subscribe(
            res => {
              if (!res) {
                return;
              }
              this.roleChangeLoop(this.selection.selected, 0, patch)
            });
        }
      )
  }

  roleChangeLoop(users: any[], index, patch) {
    if (index >= users.length) {
      this.resetSelection();
      this.setData();
      this.alertService.showSnackBar(users.length + " user's roles updated successfully", 4);
      return;
    }
    let path = `${environment.celoAdminApiEndpoint}/api/admin/v2/users/${users[index]['userId']}/roles`;
    this.sharedService.putObjectById(path, patch)
      .subscribe((result) => {
        this.roleChangeLoop(users, ++index, patch);
      }, err => {
        this.alertService.customDialog('', 'Error updating roles', 'Ok', '', true);
      }
      )
  }

  claimChangeLoop(users: any[], index, patch) {
    if (index >= users.length) {
      this.resetSelection();
      this.setData();
      this.alertService.showSnackBar(users.length + " user's claims updated successfully", 4);
      return;
    }
    let path = environment.celoApiEndpoint + '/api/admin/companies/' + this.id + '/users/' + users[index]['userId'] + '/WorkspaceUserClaims';
    this.sharedService.patchObjectById(path, patch)
      .subscribe((result) => {
        this.claimChangeLoop(users, ++index, patch);
      }, err => {
        this.alertService.customDialog('', 'Error updating claims', 'Ok', '', true);
      }
      )
  }

}
