import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-workplace',
  templateUrl: './workplace.component.html',
  styleUrls: ['./workplace.component.scss']
})
export class WorkplaceComponent implements OnInit {
  company: any = {};
  id = '';
  page: any = 'workplace_info';
  pages = [
    {
      displayName: 'Workspace Info',
      route: 'info'
    },
    {
      displayName: 'Departments',
      route: 'departments'
    },
    {
      displayName: 'Users',
      route: 'users'
    },
    {
      displayName: 'Partner Workspaces',
      route: 'partner_company'
    },
    {
      displayName: 'Domain Settings',
      route: 'domain_settings'
    },
    {
      displayName: 'Documents',
      route: 'documents'
    }
  ];
  departmentId: string;
  department: any = {};

  constructor(
    private sharedService: SharedService
    , private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.id = routeParams['id'];
      this.getPackage();
    });

    this.route.queryParams.subscribe(queryParams => {
      this.departmentId = '';

      this.page = queryParams['page'];
      this.departmentId = queryParams['departmentId'];
      if (this.departmentId) {
        this.getDepartment(this.departmentId);
      }
    });
  }

  getPackage() {
    const path = environment.celoApiEndpoint + '/api/admin/companies/' + this.id;
    this.sharedService.getObjectById(path, {}).subscribe(
      data => {
        this.company = data;
      }
    )
  }
  getDepartment(id) {
    const path = environment.celoApiEndpoint + '/api/admin/companies/' + this.id;
    this.sharedService.getObjectById(path, {}).subscribe(
      data => {
        this.department = data;
      }
    )
  }

  companyChanged(company) {
    this.getPackage();
  }
}
