<h1 mat-dialog-title>Edit delete user request</h1>

<div mat-dialog-content class="content">
    <form [formGroup]="deleteRequestForm" class="celo-input-outline">
        <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status" name="status">
                <mat-option *ngFor="let status of statuses" [value]="status">
                    {{ status }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions class="actions">
    <button mat-button mat-dialog-close class="celo-secondary-button">Cancel</button>
    <button mat-button class="celo-primary-button" (click)="submit()" [disabled]="isSubmitting">Update</button>
</div>
