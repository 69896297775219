import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-workplace-info-form',
  templateUrl: './workplace-info-form.component.html',
  styleUrls: ['./workplace-info-form.component.scss', '../../../celo-input.scss']
})
export class WorkplaceInfoFormComponent implements OnInit {

  displayItems = [];
  displayItemsEditMode = [
    {
      key: 'name',
      displayName: "Workspace Name",
      maxlength: 256,
      type: 'text',
      editable: true
    },
    {
      key: 'email',
      displayName: "Workspace Email",
      type: 'text',
      editable: true
    },
    {
      key: 'isEnterprise',
      displayName: 'Enterprise',
      type: 'checkbox'
    },
    {
      key: 'plan',
      displayName: "Pricing plan",
      type: 'toggle',
      options: [
        {
          display: 'Free',
          value: 'Free'
        },
        {
            display: 'Business',
            value: 'Business',
            isDisabled: true
        },
        {
            display: 'Enterprise',
            value: 'Enterprise'
        }
      ],
      editable: true
    },
    {
      key: 'verificationStatus',
      displayName: "Workspace Verification",
      type: 'toggle',
      options: [
        {
          display: 'Verified',
          value: 'Verified'
        },
        {
          display: 'Unverified',
          value: 'Unverified'
        }
      ],
      editable: true
    },
    {
      key: 'countryCode',
      required: true,
      displayName: "Country",
      type: 'country',
      editable: false
    },
    {
      key: 'registeredDate',
      displayName: "Created Date",
      type: 'date'
    },
    {
      key: 'isActive',
      displayName: "Status",
      type: 'status'
    }
  ]
  displayItemsNewMode = [
    {
      key: 'name',
      displayName: "Workspace Name",
      maxlength: 256,
      type: 'text',
      editable: true
    },
    {
      key: 'email',
      displayName: "Workspace email",
      type: 'text',
      editable: true
    },
    {
      key: 'countryCode',
      required: true,
      displayName: "Country Code",
      type: 'select',
      options: [],
      editable: true
    },
    {
      key: 'registeredDate',
      displayName: "Created Date",
      type: 'date'
    },
    {
      key: 'isActive',
      displayName: "Status",
      type: 'status'
    }
  ]
  @Input() formObject: any = {};
  @Input() editmode: boolean;
  @Input() newmode: boolean;
  @Output() formchange = new EventEmitter();

  constructor(
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.setDisplayConfig();
  }
  ngOnChages() {
    this.setDisplayConfig();
  }
  setDisplayConfig() {
    if (this.newmode){
      this.displayItems = this.displayItemsNewMode;
    }
    else{
      this.displayItems = this.displayItemsEditMode;
    }

    let supportedCountries = [];
    let instance = this;
    this.settingsService.getFilteredSupportedCountries(function (countries) {
      supportedCountries = countries;

      instance.displayItemsNewMode.forEach(element => {
        if (element.key == 'countryCode') {
          element.options = supportedCountries;
        }
      });
    });
  }

  valueChange(key, event) {
    this.formObject[key] = event;
    this.formchange.emit(this.formObject);
  }
}
