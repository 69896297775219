<div class="dialog-box">
  <div class="title">Edit Licenses</div>
  <div class="input-row">
      <div class="field">Number of Licenses:</div>
      <input class="input" type="number" matInput [valueAsNumber]="value" (input)="handleOnChange($event)" />
  </div>
  <div class="input-row">
    <div class="field">License Expiry Date:</div>
    <mat-form-field appearance="fill" class="input">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" (dateInput)="handleDateChange($event)">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
 
  <div class="content">
    <button mat-button class="celo-primary-button" type="submit" (click)="saveClicked()">
      Save
    </button>
    <button mat-button class="celo-secondary-button" mat-dialog-close>
      Cancel
    </button>
  </div>
</div>