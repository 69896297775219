import { LocationField } from "./../../../services/shared.service";
import { WorkspaceAddressModel } from "./../../../../types/index";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { SharedService } from "src/app/services/shared.service";
import { Country, SettingsService } from "src/app/services/settings.service";

export interface EditLocationComponentPayload {
  type: "save" | "delete";
  data?: WorkspaceAddressModel;
}


@Component({
  selector: "app-edit-location",
  templateUrl: "./edit-location.component.html",
  styleUrls: ["./edit-location.component.scss", "../../../celo-input.scss"],
})
export class EditLocationComponent implements OnInit {
  @Input() location: WorkspaceAddressModel;
  locationFields: LocationField[];
  isNewLocation: boolean = false;
  countries: Country[] = [];

  constructor(
    private sharedService: SharedService,
    private settingsService: SettingsService,
    private dialogRef: MatDialogRef<
      EditLocationComponent,
      EditLocationComponentPayload
    >
  ) {
    this.locationFields = this.sharedService.locationFields;
  }

  ngOnInit(): void {
    if (!this.location) {
      this.isNewLocation = true;
      this.location = {
        address1: "",
        address2: "",
        suburb: "",
        city: "",
        state: "",
        countryCode: "",
        postCode: "",
      };
    }

    this.countries = this.settingsService.countries;
  }

  saveClicked() {
    const payload: EditLocationComponentPayload = {
      type: "save",
      data: this.location,
    };
    this.dialogRef.close(payload);
  }

  deleteClicked() {
    const payload: EditLocationComponentPayload = {
      type: "delete",
    };
    this.dialogRef.close(payload);
  }
}
